const styles = {
  panel: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWrapper: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}

export default styles
