import { COLORS } from '@constants'

const styles = {
  wrapper: {
    display: 'flex',
  },
  icon: {
    width: '22px',
    height: '22px',
    minWidth: '22px',
    marginRight: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: COLORS.info.main,
    color: COLORS.info.contrastText,
  },
  message: {
    padding: 12,
    fontSize: 14,
    backgroundColor: COLORS.info.main,
    color: COLORS.info.contrastText,
    height: 'fit-content',
  },
}

export default styles
