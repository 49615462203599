/* eslint-disable max-len */
const subtop = {
  sections: {
    subtop: {
      heading: 'De subtop',
      subHeading: 'Wat is het streefcijfer voor het de subtop?',
      intro:
        'Een streefcijfer van 30% betekent: ten minste 30% mannen en ten minste 30% vrouwen.',
    },
    definition: {
      heading: 'Hoe definieert u de subtop?',
      intro: (
        <>
          <p>
            Grote vennootschappen formuleren ook een streefcijfer voor de
            man-vrouwverhouding in 'leidinggevende functies', de zogenoemde
            subtop.
          </p>
          <p>
            U mag zelf definiëren welke functies en werknemers u tot de subtop
            rekent. Medewerkers die gestationeerd zijn in het buitenland mogen
            worden meegeteld als dat past in uw definitie van de subtop.
          </p>
        </>
      ),
    },
    balance: {
      heading: 'Man-vrouwverhouding in de subtop',
    },
    turnover: {
      heading: 'In- en uitstroom van werknemers in de subtop',
      accordion: {
        heading: 'Lees meer over instroom en uitstroom',
        content: (
          <>
            <strong>Definitie instroom</strong>
            <p>
              Het aantal werknemers dat gedurende het afgelopen boekjaar is
              aangesteld in een functie in de subtop.
            </p>
            <p>Het gaat om:</p>
            <ul>
              <li>
                Personen van buiten de organisatie die werden aangesteld in een
                functie in de subtop.
              </li>
              <li>
                Werknemers uit de eigen organisatie die werden aangesteld in een
                functie in de subtop en die voorheen geen functie in de subtop
                hadden.
              </li>
            </ul>
            <p>
              De overgang van een functie in de subtop naar een andere functie
              binnen de subtop wordt niet als instroom geteld.
            </p>
            <p>
              Aanstellingen via uitzendbureaus, payrolling vallen hier niet
              onder. Ook nulurencontracten worden niet meegeteld.
            </p>
            <strong>Definitie uitstroom</strong>
            <p>
              Het aantal werknemers dat gedurende het afgelopen jaar de subtop
              heeft verlaten.
            </p>
            <p>Het gaat om:</p>
            <ul>
              <li>
                Werknemers die uitstroomden naar een functie in de organisatie
                die niet tot de subtop wordt gerekend.
              </li>
              <li>Werknemers die de organisatie hebben verlaten.</li>
            </ul>
            <p>
              De overgang van een functie binnen de subtop naar een andere
              functie binnen de subtop wordt niet als uitstroom geteld.
            </p>
            <p>
              Aanstellingen via uitzendbureaus, payrolling vallen hier niet
              onder. Ook nulurencontracten worden niet meegeteld.
            </p>
          </>
        ),
      },
    },
  },
  skip: {
    severity: 'info',
    content:
      'U heeft aangegeven dat uw organisatie geen Subtop heeft. U kunt deze stap overslaan door op volgende te klikken.',
  },
  questions: {
    code_1: {
      label: 'Beschrijf hier hoe u de subtop definieert',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'text',
    },
    code_2: {
      label: 'Streefcijfer (in %)',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
      inputProps: {
        min: 0,
        max: 50,
        step: 0.01,
      },
    },
    code_3: {
      label: 'Streefjaar',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
      inputProps: {
        min: 2022,
        max: 2050,
      },
    },
    code_4: {
      label:
        'Hoeveel werknemers waren in uw bedrijf in dienst in de subtop op {{taxYear}} (in personen)?',
      male: {
        label: 'Aantal mannen',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
      female: {
        label: 'Aantal vrouwen',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    code_5: {
      label:
        'Hoeveel werknemers werden in het boekjaar {{year}} aangesteld in de subtop?',
      male: {
        label: 'Aantal mannen',
        required: {
          value: false,
        },
      },
      female: {
        label: 'Aantal vrouwen',
        required: {
          value: false,
        },
      },
    },
    code_6: {
      label: 'Hoeveel werknemers verlieten de subtop in het boekjaar {{year}}?',
      male: {
        label: 'Aantal mannen',
        required: {
          value: false,
        },
      },
      female: {
        label: 'Aantal vrouwen',
        required: {
          value: false,
        },
      },
    },
  },
}

export default subtop
