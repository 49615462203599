import { createResource } from '@acato/axios-utils'
import { createInterceptor, errorInterceptor } from '@services/interceptor'

// Api url
const API_URL = process.env.REACT_APP_API_URL

const intercept = createInterceptor({
  error: errorInterceptor,
})

export const authResource = intercept(
  createResource({
    baseURL: `${API_URL}/api/company-portal`,
  })
)
