import ReactMarkdown from 'react-markdown'
import { modal } from '@store/modal.state'

// UI
import { Button } from '@components'
import RequestModal from '@partials/modals/HandleRequestModal'

// Utils
import interpolate from '@helpers/strings/interpolate'

// Contants
import { DASHBOARD } from '@constants'

// Styles
import './styles.scss'

const Parent = ({ year, company, group_head }) => {
  const { id, incoming_request, type } = group_head.request

  const requestType = type.replace(/-/g, '_')
  const requestContent = incoming_request
    ? DASHBOARD.requests.incoming[requestType]
    : DASHBOARD.requests.outgoing[requestType]

  const renderContent = interpolate(
    requestContent,
    {
      parent: group_head?.company?.name || '',
      subsidiary: company?.name || '',
      year,
    },
    {
      bold: true,
    }
  )

  // Methods
  const openRequestModal = () => {
    const modalBody = {
      heading: 'Beoordeel openstaand rapportageverzoek',
      content: (
        <RequestModal
          id={id}
          content={<ReactMarkdown>{renderContent}</ReactMarkdown>}
        />
      ),
      buttons: [],
    }
    modal.setOpen(modalBody)
  }

  return (
    <div className="parent-requests">
      <h2>Openstaande verzoeken</h2>
      <ReactMarkdown>{renderContent}</ReactMarkdown>
      {incoming_request && (
        <Button
          variant="outlined"
          color="primary"
          onClick={openRequestModal}>
          Bekijk verzoek
        </Button>
      )}
    </div>
  )
}

export default Parent
