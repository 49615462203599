import { Button } from '@components'
import { COLORS } from '@constants'
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined'
import { Typography } from '@mui/material'
import { useCallback } from 'react'

export const IntakeCompany = ({ version, onRemove, company, disabled }) => {
  const getCompanyStatus = useCallback(
    accepted_at => {
      if (!accepted_at) return <Typography>Nog niet gekoppeld</Typography>
      if (accepted_at)
        return (
          <div>
            <Typography
              color={COLORS.success.dark}
              fontWeight={500}>
              Gekoppeld
            </Typography>
            <Typography variant="body2">{accepted_at}</Typography>
          </div>
        )
    },
    [company]
  )

  const handleRemove = useCallback(() => {
    onRemove()
  }, [onRemove])

  return (
    <div className={`company company-version-${version}`}>
      {version === 'B' && (
        <div className="company-column company-column-icon">
          <div>
            <StoreMallDirectoryOutlinedIcon />
          </div>
        </div>
      )}
      <div className="company-column company-column-left">
        <strong>{company.name}</strong>
        <span>KvK nr.: {company.kvk_number}</span>
      </div>
      {version === 'B' && (
        <div className="company-column company-column-center">
          {getCompanyStatus(company.accepted_at)}
        </div>
      )}
      <div className="company-column company-column-right">
        <Button
          type="button"
          onClick={handleRemove}
          disabled={disabled || company.locked}>
          Verwijderen
        </Button>
      </div>
    </div>
  )
}
