import { Nav, Panel } from '@components'
import Container from '@mui/material/Container'

// Assets
import noMobile from '@assets/graphics/base/mobile-forbidden.png'

// Styles
import { COLORS } from '@constants'

const MobileForbidden = () => {
  return (
    <>
      <Nav />
      <Container style={styles.screen}>
        <img
          src={noMobile}
          alt="Mobile forbidden"
        />
        <h1 style={styles.heading}>
          Bezoek het diversiteitsportaal op een groter scherm
        </h1>
        <Panel>
          <h4>
            We zijn druk in ontwikkeling. Momenteel is het portaal alleen
            beschikbaar op een laptop of computer (minimaal 960 pixels breed).
          </h4>
        </Panel>
      </Container>
    </>
  )
}

const styles = {
  screen: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 48,
    paddingBottom: 48,
    minHeight: '100vh',
    backgroundColor: COLORS.secondary.main,
  },
  heading: {
    paddingBottom: '24px',
  },
}

export default MobileForbidden
