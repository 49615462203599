import IntakeVersionAFormStep1 from './version-a/IntakeVersionAFormStep1'
import IntakeVersionAFormStep2 from './version-a/IntakeVersionAFormStep2'
import IntakeVersionAFormStep3 from './version-a/IntakeVersionAFormStep3'
import IntakeVersionBFormStep1 from './version-b/IntakeVersionBFormStep1'
import IntakeVersionBFormStep2 from './version-b/IntakeVersionBFormStep2'
import IntakeVersionBFormStep3 from './version-b/IntakeVersionBFormStep3'

export const INTAKE_FORM = {
  A: {
    VERSION: 'A',
    STEPS: [
      IntakeVersionAFormStep1,
      IntakeVersionAFormStep2,
      IntakeVersionAFormStep3,
    ],
  },
  B: {
    VERSION: 'B',
    STEPS: [
      IntakeVersionBFormStep1,
      IntakeVersionBFormStep2,
      IntakeVersionBFormStep3,
    ],
  },
}
