import { useEffect, useMemo } from 'react'

// Services
import { declarationHooks } from '@services'

// Store
import { toaster } from '@store/toaster.state'

// Components
import { Button } from '@components'
import { Tooltip } from '@mui/material'

// Utils
import renderPDF from '@helpers/files/renderPdf'
import { useFormContext } from 'react-hook-form'

const DownloadReport = ({ declaration_id, year }) => {
  const { useDeclarationExport } = declarationHooks
  const ctx = useFormContext()
  const [downloadSummary, loading, res, err] = useDeclarationExport()

  useEffect(() => {
    if (res) {
      const filename = `SER_rapportage_${year}.pdf`
      renderPDF(res, filename)
      toaster.success(`${filename} is gedownload.`)
    }
    err?.errors && toaster.error(err.errors)
  }, [res, err, year])

  const disableDownload = useMemo(() => {
    return year === '2023' || year === 2023
  }, [year])

  if (ctx?.isPDFExport) return null

  const DownloadButton = (
    <Button
      type="button"
      variant="outlined"
      color="primary"
      onClick={() => downloadSummary(declaration_id)}
      loading={loading}
      disabled={disableDownload}>
      Download rapportage
    </Button>
  )

  if (disableDownload) {
    return (
      <Tooltip title="De download is nog niet beschikbaar">
        <div>{DownloadButton}</div>
      </Tooltip>
    )
  } else return DownloadButton
}

export default DownloadReport
