import { activationResource } from './resource'

export const activationService = {
  activateCode: (body, signal) =>
    activationResource.post(
      '/api/company-portal/activate',
      {
        activation_code: body.activation_code,
        kvk_number: body.kvk_number,
        contacts: body.contacts.map(contact => ({
          type: contact.type,
          email: contact.email,
          first_name: contact.firstName,
          last_name: contact.lastName,
        })),
      },
      { signal: signal() }
    ),
}
