import { setPartialState } from './ui.store'

const setDashboardState = setPartialState('dashboard', {
  years: null,
  loading: true,
})

export const dashboard = {
  setDashboard: years =>
    setDashboardState({
      years,
      loading: false,
    }),
  clearDashboard: () =>
    setDashboardState({
      years: null,
      loading: false,
    }),
  setDashboardLoading: loadingVal =>
    setDashboardState(prevState => ({
      ...prevState,
      loading: loadingVal,
    })),
}
