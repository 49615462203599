import { createFetchHooks } from '@acato/react-fetch'
import { activationService } from './service'

const handlers = (resCb = null, errCb = null) => ({
  responseHandler: res => (resCb && resCb(res?.data)) || res?.data,
  errorHandler: ({ errors }) => (errCb && errCb({ errors })) || { errors },
})

export const activationHooks = createFetchHooks({
  name: 'useActivateCode',
  fetcher: activationService.activateCode,
  ...handlers(),
})
