import { createFetchHooks } from '@acato/react-fetch'
import { intakeService } from './service'

const handlers = (resCb = null, errCb = null) => ({
  responseHandler: res => (resCb && resCb(res?.data)) || res?.data,
  errorHandler: ({ errors }) => (errCb && errCb({ errors })) || { errors },
})

export const intakeHooks = createFetchHooks(
  {
    name: 'useGetIntake',
    fetcher: intakeService.getIntake,
    ...handlers(),
  },
  {
    name: 'useCreateIntake',
    fetcher: intakeService.createIntake,
    ...handlers(),
  },
  {
    name: 'usePatchIntake',
    fetcher: intakeService.patchIntake,
    ...handlers(),
  },
  {
    name: 'useResetIntake',
    fetcher: intakeService.resetIntake,
    ...handlers(),
  },
  {
    name: 'useAcceptRequest',
    fetcher: intakeService.acceptIntakeRequest,
    ...handlers(),
  }
)
