import { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { CONTENT } from '@constants'
import { getState } from '@store/ui.store'
import { profileHooks } from '@services/index'

import { Checkbox } from '@components'

const {
  welcome: { modal },
} = CONTENT

const WelcomeModal = () => {
  const methods = useForm()
  const auth = getState('auth')
  const { control, watch, handleSubmit } = methods
  const { useUpdatePreferences } = profileHooks

  const [updatePreferences, submitting, response, error] =
    useUpdatePreferences()

  // State
  const update = body => {
    updatePreferences({ user_id: auth?.data?.id, body })
  }

  useEffect(() => {
    const watcher = watch(handleSubmit(update))
    return () => watcher.unsubscribe()
  }, [handleSubmit, watch])

  return (
    <div>
      <div>{modal.intro.content}</div>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(update)}
          style={{
            margin: '32px 0',
            padding: '16px',
            border: '2px solid #241D6D',
          }}>
          <Controller
            name="receive_tips"
            control={control}
            render={({ field: { value, ref, onChange } }) => {
              const { label } = modal.questions.receive_info
              return (
                <Checkbox
                  label={label}
                  checked={value || false}
                  onChange={onChange}
                  inputRef={ref}
                  disabled={submitting}
                />
              )
            }}
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default WelcomeModal
