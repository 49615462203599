import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import SyncAltIcon from '@mui/icons-material/SyncAlt'

export const INTAKE_GROUP_RELATION_CHANGE_STATES = {
  added: {
    color: 'success',
    icon: CheckCircleOutline,
  },
  updated: { color: 'warning', icon: SyncAltIcon },
  removed: { color: 'error', icon: RemoveCircleOutlineIcon },
}
