import React from 'react'

// Components
import { Alert } from '@components'

// Material
import Popper from '@mui/material/Popper'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

// Constants
import { COLORS } from '@constants'

interface Props {
  label?: string
  options: Option[]
  disabled?: boolean
  required?: boolean
  value: any
  onChange: any
  alert: {
    severity?: 'error' | 'warning' | 'info' | 'success'
    message?: string
  }
  inputRef: any
}

interface Option {
  id: number | string
  value: number | string
  label: string
}

const StyledPopper = (props: any) => {
  return <Popper {...props}>{props.children}</Popper>
}

const AutoComplete: React.FC<Props> = ({
  label,
  options,
  disabled,
  required,
  value,
  onChange,
  alert,
  inputRef,
}) => {
  const setNewValue = (_: any, newValue: any) => {
    onChange(newValue?.value || null)
  }

  return (
    <div>
      <Autocomplete
        disabled={disabled}
        options={options}
        value={options.find(option => option.value === value) || null}
        onChange={setNewValue}
        noOptionsText="Geen opties"
        PopperComponent={StyledPopper}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            required={required}
            inputRef={inputRef}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& > fieldset': {
                  borderColor: alert?.message
                    ? COLORS.error.contrastText
                    : COLORS.gray.main,
                },
              },
            }}
          />
        )}
      />
      {alert?.message && (
        <Alert
          severity="error"
          content={alert.message}
          sx={{ marginTop: '6px', marginBottom: '6px' }}
        />
      )}
    </div>
  )
}

export default AutoComplete
