import { setPartialState, getState } from './ui.store'

const setModalState = setPartialState('modal')

export const modal = {
  setOpen: ({ heading, customHeading, content, buttons, props = {} }) =>
    setModalState({
      open: true,
      heading,
      customHeading,
      content,
      buttons,
      props,
    }),
  setClose: () => {
    setModalState({
      ...getState('modal'),
      open: false,
    })

    // Delay content erease to prevent flickering before close animation is done
    setTimeout(() => {
      setModalState({
        open: false,
        customHeading: null,
        heading: '',
        content: '',
        buttons: [],
        props: {},
      })
    }, 150)
  },
}
