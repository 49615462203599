import React from 'react'

// Material
import { Grid } from '@mui/material'

// Styling
import styles from './styles'
interface Props {
  spacing?: number
  children?: React.ReactNode
  style?: any
}

const Panel: React.FC<Props> = ({ spacing, children, style }) => {
  return (
    <Grid
      container
      spacing={spacing}
      rowSpacing={2}
      style={{ ...styles.panel, ...style }}>
      {children}
    </Grid>
  )
}

export default Panel
