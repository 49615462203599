import { styled } from '@mui/system'

export const Container = styled('div', {
  shouldForwardProp: prop => prop !== 'columns',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '0.5fr 1fr',
}))

export const Header = styled('div', {
  shouldForwardProp: prop => prop !== 'columns',
})(({ theme }) => ({
  gridColumn: '1 / 3',
  gridRow: '1 / 1',
}))

export const Intro = styled('div', {
  shouldForwardProp: prop => prop !== 'color',
})(({ theme, color = 'primary' }) => ({
  gridColumn: '1 / 3',
  gridRow: '2 / 2',
  backgroundColor: theme.palette.background[color],
}))
export const Content = styled('div', {
  shouldForwardProp: prop => prop !== 'columns',
})(({ theme }) => ({
  gridColumn: '1 / 3',
  gridRow: '2 / 2',
}))

export const FooterSection = styled('div', {
  shouldForwardProp: prop => prop !== 'columns',
})(({ theme }) => ({
  gridColumn: '1 / 3',
  gridRow: '3 / 4',
  backgroundColor: theme.palette.primary.main,
}))
