import { createRxState } from '@acato/rx-state'

const [setPartialState, useSharedState, getState, subscribe] = createRxState({
  auth: {},
  breadcrumb: [],
  dashboard: {
    years: null,
    loading: true,
  },
  intake: {
    year: null,
    declaration_id: null,
    company: {
      name: null,
      id: null,
    },
    subsidiaries: null,
  },
  declaration: {
    companies: [],
    company: {},
    consolidated: null,
    deadline_at: null,
    declaration_version: null,
    end_tax_year_at: null,
    id: null,
    receive_info: null,
    receive_tips: null,
    sections: [],
    share_dialogs: null,
    share_profile: null,
    status: null,
    year: null,
  },
  toaster: { type: null, message: null },
  modal: {
    open: false,
    customHeading: null,
    heading: '',
    content: '',
    buttons: [],
    props: {},
  },
  current_submit: {},
})

export { setPartialState, useSharedState, getState, subscribe }
