import { Snackbar, Alert } from '@mui/material'
import { setPartialState, useSharedState } from '@store/ui.store'
import { useMemo } from 'react'
import ToasterMessage from './ToasterMessage'

const handleClose = () => setPartialState('toaster')(null)

const Toaster = ({ vertical = 'bottom', horizontal = 'right' }) => {
  const toaster = useSharedState('toaster')

  return useMemo(() => {
    const { type = 'success', message, hide = 3000 } = toaster || {}
    if (!message) return null
    return (
      <Snackbar
        key={message}
        anchorOrigin={{
          vertical,
          horizontal,
        }}
        open={!!message}
        autoHideDuration={hide}
        onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={type}
          sx={{ width: '100%' }}>
          <ToasterMessage messages={message} />
        </Alert>
      </Snackbar>
    )
  }, [toaster, horizontal, vertical])
}

export default Toaster
