// MUI
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import clsx from 'clsx'

// Components
import { Alert } from '@components'

// Styles
import './style.scss'

interface Props {
  intake_version: 'A' | 'B'
  options: Option[]
  value: string | number
  name?: string
  required?: boolean
  disabled?: boolean
  horizontal?: boolean
  alert?: {
    severity?: 'error' | 'warning' | 'info' | 'success'
    message?: string
  }
  onChange: (option: string | number) => void
  inputRef: any
}

interface Option {
  id: string | number
  value: string | number
  label: string
  title: string
  content: string
  selected: boolean
  unavailable?: boolean
  image?: {
    src: string
    height?: number
    objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  }
}

interface OptionProps {
  intake_version: Props['intake_version']
  option: Option
  selected: boolean
  required?: boolean
  disabled?: boolean
  horizontal?: boolean
  setValue: (value: number | string) => void
}

const SelectImage: React.FC<Props> = ({
  intake_version,
  value,
  onChange,
  options,
  required,
  disabled,
  horizontal,
  alert,
  inputRef,
  name,
}) => {
  const handleChange = (value: number | string) => {
    if (disabled) return null
    onChange(value)
  }

  return (
    <div ref={inputRef}>
      <RadioGroup
        row
        value={value}
        className={clsx(
          'options-grid',
          horizontal && 'options-grid--horizontal'
        )}
        name={name}
        style={
          {
            '--options-count': options.length,
          } as React.CSSProperties
        }>
        {options.map(option => {
          return (
            <FormControlLabel
              key={option.id}
              value={option.value}
              label={option.label}
              className={option.unavailable ? 'unavailable' : undefined}
              onClick={() => handleChange(option.value)}
              sx={{
                border: alert?.message ? '1px solid red' : '',
              }}
              control={
                <RenderOption
                  intake_version={intake_version}
                  option={option}
                  selected={value === option.value}
                  required={required}
                  setValue={(value: number | string) => handleChange(value)}
                  horizontal={horizontal}
                />
              }></FormControlLabel>
          )
        })}
      </RadioGroup>
      {alert?.message && (
        <Alert
          severity={alert.severity || 'error'}
          content={alert.message}
          sx={{
            marginTop: '-6px',
            marginBottom: '6px',
          }}
        />
      )}
    </div>
  )
}

const RenderOption: React.FC<OptionProps> = ({
  intake_version = 'A',
  option,
  selected,
  required,
  setValue,
  horizontal,
}) => {
  const { image, title, content } = option

  return (
    <div
      className={`options-grid__card ${
        selected ? 'selected' : ''
      } version-${intake_version}`}>
      <div
        className={`radio__wrapper radio__wrapper__version-${intake_version}`}>
        <div className={`radio ${selected ? 'radio__selected' : ''}`}>
          <div className="dot"></div>
        </div>
        <Radio
          checked={selected}
          required={required}
          onChange={() => setValue(option.value)}
        />
        {intake_version === 'B' && (
          <Typography variant="h3">{title}</Typography>
        )}
      </div>
      {image && (
        <img
          src={image.src}
          alt={title}
          style={{
            maxHeight: '250px',
            maxWidth: '100%',
            marginTop: '24px',
            height: image.height ? image.height : '',
            objectFit: image.objectFit || 'contain',
          }}
        />
      )}
      <div className="content__wrapper">
        {intake_version === 'A' && (
          <h3 style={horizontal ? { margin: 0 } : void 0}>{title}</h3>
        )}
        <p>{content}</p>
      </div>
    </div>
  )
}

export default SelectImage
