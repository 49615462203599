import { useState, useRef } from 'react'
import { TextField, InputLabel, Alert } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { forwardRef } from 'react'
import { COLORS } from '@constants'

interface Props {
  variant?: 'filled' | 'outlined' | 'standard'
  labelType?: 'fixed' | 'floating'
  label?: string
  required?: boolean
  color?: 'success' | 'info' | 'warning' | 'error' | 'primary'
  placeholder?: string
  disabled?: boolean
  alert?: {
    severity?: 'success' | 'info' | 'warning' | 'error'
    message: string
  }
  value: number
  onChange: (e: any) => void
  rest?: any
  min?: number
  max?: number
  toFixed?: number
  sx?: any
}

const NumberInput: React.FC<Props> = forwardRef(
  (
    {
      variant,
      labelType = 'fixed',
      label,
      color = 'primary',
      placeholder,
      required,
      alert,
      value,
      onChange,
      min = 0,
      max,
      toFixed = 0,
      ...rest
    },
    ref
  ) => {
    const ctx: any = useFormContext()
    const [info, setInfo] = useState('')

    if (alert?.message && !alert.severity) {
      alert.severity = 'error'
    }

    if (alert?.severity) {
      color = alert.severity
    }

    const inputRef = useRef<any>(null)

    // Methods
    const handleChange = (e: any) => {
      if (e.target.value === '') {
        return onChange(e.target.value)
      }
      let val = e.target.valueAsNumber
      if ((min || min === 0) && val < min) {
        val = min
        setInfo('Minimum waarde is ' + min)
        inputRef.current.scrollIntoView()
        setTimeout(() => {
          setInfo('')
        }, 2000)
      }
      if (max && val > max) {
        val = max
        setInfo('Maximum waarde is ' + max)
        inputRef.current.scrollIntoView()
        setTimeout(() => {
          setInfo('')
        }, 2000)
      }

      onChange(parseFloat(val.toFixed(toFixed)))
    }

    return (
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        ref={inputRef}>
        {label && labelType === 'fixed' && <InputLabel>{label}</InputLabel>}
        {ctx?.isPDFExport ? (
          <div className="pdf-export__input">{value}</div>
        ) : (
          <TextField
            type="number"
            inputRef={ref}
            variant={variant}
            label={labelType === 'floating' ? label : ''}
            placeholder={placeholder}
            required={required}
            color={color}
            value={value}
            onChange={onChange}
            onBlur={handleChange}
            sx={{
              ...(rest?.sx || {}),
              '& .MuiOutlinedInput-root': {
                '&:not(.Mui-disabled)': {
                  backgroundColor: 'rgb(255, 255, 255)',
                },
                '& > fieldset': {
                  borderColor: alert?.message
                    ? COLORS[color].contrastText
                    : COLORS.gray.main,
                },
              },
            }}
            {...rest}
          />
        )}
        {alert?.message && (
          <Alert
            severity={alert.severity ? alert.severity : 'error'}
            sx={{ marginTop: '6px', marginBottom: '6px' }}>
            {alert.message}
          </Alert>
        )}
        {info && (
          <div className="fade-in">
            <Alert
              severity={'info'}
              sx={{ marginTop: '6px', marginBottom: '6px' }}>
              {info}
            </Alert>
          </div>
        )}
      </div>
    )
  }
)
NumberInput.displayName = 'NumberInput'
export default NumberInput
