import { RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { Alert } from '@components'
import { useFormContext } from 'react-hook-form'
// styles
import styles from './styles'
import { COLORS } from '@constants'

// Icons
import CircleIcon from '@mui/icons-material/Circle'
import { useEffect, useMemo } from 'react'

interface Item {
  label?: string
  value?: any
  style?: object
}
interface Props {
  label: string
  items: Item[]
  value: any
  defaultValue: any
  name?: string
  disabled?: boolean
  required?: boolean
  alert?: {
    type?: 'error' | 'warning' | 'info' | 'success'
    message?: string
  }
  onChange: () => void
  className?: 'string'
  wrapperStyle?: any
  inputRef?: any
  rest?: any
}

const RadioButton: React.FC<Props> = ({
  items,
  value,
  defaultValue,
  name,
  disabled,
  required,
  alert,
  onChange,
  className,
  inputRef,
}) => {
  const ctx: any = useFormContext()
  let outline = COLORS.gray.main

  if (disabled) {
    outline = COLORS.gray.disabled
  }
  if (alert?.message && alert?.type) {
    switch (alert.type) {
      case 'error':
        outline = COLORS.error.contrastText
        break
      case 'warning':
        outline = COLORS.warning.contrastText
        break
      case 'info':
        outline = COLORS.info.contrastText
        break
      case 'success':
        outline = COLORS.success.contrastText
        break

      default:
        break
    }
  }

  return (
    <div
      className={className}
      ref={inputRef}>
      <RadioGroup
        row
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        name={name}
        aria-labelledby={name}
        sx={{
          ...styles.radioWrapper,
          borderColor: outline,
          width: '100%',
          '.MuiFormControlLabel-root': {
            border: '1px solid',
            padding: '6px 18px',
            borderColor: outline,
            marginLeft: '0',
          },
        }}>
        {items?.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.value}
            label={item.label}
            labelPlacement="end"
            disabled={disabled}
            sx={{ ...styles.radioBox, ...item?.style }}
            control={
              <Radio
                required={required}
                sx={styles.radio}
                checkedIcon={<CircleIcon fontSize="small" />}
              />
            }
          />
        ))}
      </RadioGroup>
      {alert?.message && (
        <Alert
          severity="error"
          content={alert?.message}
          sx={{ marginTop: '6px', marginBottom: '6px' }}
        />
      )}
    </div>
  )
}

export default RadioButton
