const reviewSubmit = {
  sections: {
    overview: {
      heading: 'Rapportage controleren',
      intro: 'Bekijk uw rapportage en voeg eventueel opmerkingen toe.',
    },
    profile: {
      heading: 'Bedrijfsgegevens',
    },
    numbers: {
      label:
        'Man-vrouwverhouding in de top, subtop en organisatie op {{taxYear}}',
    },
  },
  labels: {
    profile: {
      public_name: 'Bedrijfsnaam',
      postal_code: 'Postcode',
      address: 'Adres',
      city: 'Plaats',
      kvk: 'KvK-nummer',
    },
    button: {
      download: 'Download rapportage',
    },
  },
  balance: {
    heading:
      'Man-vrouwverhouding in de top, subtop en organisatie op {{taxYear}}',
    grid: {
      men: 'Aantal mannen',
      women: 'Aantal vrouwen',
      percentage: '% Vrouwen',
      target: 'Streefcijfer (Streefjaar)',
      boardDirectors: 'Bestuur',
      supervisoryBoard: 'RvC',
      subtop: 'Subtop',
      total: 'Organisatie',
      subtopDefinition: 'Definitie subtop',
      publicComment: 'Toelichting voor de dataverkenner',
    },
  },
  questions: {
    code_1: {
      label: 'Heeft u nog een toelichting voor de SER?',
      placeholder:
        'Voeg een toelichting of opmerking toe voor de SER (niet-openbaar)',
      required: {
        value: false,
      },
    },
  },
}

export default reviewSubmit
