import { useState, useEffect } from 'react'

// Material UI
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// Styles
import './style.scss'
import { COLORS } from '@constants'
import {
  CheckCircleRounded,
  ExpandCircleDown,
  FiberManualRecord,
} from '@mui/icons-material'

const StatusDot = ({ status, isRoot }) => {
  const STATUS = {
    'root.success': {
      node: CheckCircleRounded,
      color: 'success',
    },
    'root.disabled': {
      node: FiberManualRecord,
      style: { color: '#D3E7F4' },
    },
    'root.current': {
      node: ExpandCircleDown,
      style: { color: '#54B4E4', transform: 'rotate(-90deg)' },
    },
    'child.success': {
      node: FiberManualRecord,
      style: {
        color: '#c9cb8a',
        height: '16px',
        width: '16px',
        transform: 'rotate(-90deg)',
      },
    },
    'child.disabled': {
      node: FiberManualRecord,
      style: {
        color: '#D3E7F4',
        height: '16px',
        width: '16px',
        transform: 'rotate(-90deg)',
      },
    },
    'child.current': {
      node: ExpandCircleDown,
      style: {
        color: '#54B4E4',
        height: '16px',
        width: '16px',
        transform: 'rotate(-90deg)',
      },
    },
  }

  const selector = `${isRoot ? 'root' : 'child'}.${status}`
  const Dot = STATUS[selector]

  if (!Dot?.node)
    return (
      <span
        className={selector}
        data-no-status></span>
    )

  return (
    <Dot.node
      className={selector}
      style={{ zIndex: 1, ...Dot.style }}
      color={Dot.color}
    />
  )
}

const SideMenuSection = ({
  section,
  currentSection,
  activeStep,
  activeSubsection,
  currentStep,
  handleNavigation,
  declarationCompleted,
}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (section === currentSection) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [currentSection, section])

  const disabled = section !== currentSection && !section.completed
  const isRootStep = section?.rootForm ?? section?.forms?.length === 1

  const Heading = ({ name, completed, current = true }) => (
    <div className={disabled ? 'section disabled' : 'section'}>
      <StatusDot
        status={
          completed || declarationCompleted
            ? 'success'
            : disabled || !current
            ? 'disabled'
            : 'current'
        }
        isRoot
      />
      {name}
    </div>
  )

  const renderStep = (form, index, subSection = {}, isEnabled) => {
    if (form.skip) return null

    const step = `${section.slug}-${form.slug}`
    let classNames = ['step']
    isRootStep && classNames.push('section')
    form.completed && classNames.push('navigate')
    declarationCompleted && classNames.push('navigate')
    form.slug === activeStep &&
      (activeSubsection ? activeSubsection?.slug === subSection?.slug : true) &&
      classNames.push('active')
    if (isEnabled || step === currentStep) classNames.push('navigate')
    step !== currentStep &&
      !isEnabled &&
      !form.completed &&
      classNames.push('disabled')
    const stepClasses = classNames.join(' ')

    return (
      <li
        key={index}
        className={stepClasses}
        onClick={() => {
          handleNavigation({ ...section, subSection }, form)
        }}>
        <StatusDot
          status={
            form.completed || section.completed || declarationCompleted
              ? 'success'
              : step === currentStep || isEnabled
              ? 'current'
              : 'disabled'
          }
          isRoot={isRootStep}
        />
        <span>{form.name}</span>
      </li>
    )
  }

  const renderSectionStep = (form, index) => {
    if (form.skip) return null

    const isCurrentSectionActive = !!form?.forms?.find?.(({ slug }) =>
      currentStep.includes(slug)
    )

    return (
      <Accordion
        key={form.id}
        defaultExpanded={isCurrentSectionActive}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            minHeight: '42px !important',
            paddingRight: 0,
            '.MuiAccordionSummary-content': {
              marginBottom: 0,
              marginTop: 0,
              '&.Mui-expanded': {
                margin: '14px 0px',
              },
            },
          }}>
          <span
            style={{
              display: 'flex',
              alignContent: 'center',
              color: COLORS.text.main,
              fontWeight: 500,
            }}>
            <Heading {...form} />
          </span>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
          }}>
          {form?.forms.map((child, index) =>
            renderStep(child, index, form, true)
          )}
        </AccordionDetails>
      </Accordion>
    )
  }

  const content = (
    <ul>
      {section?.sections.map(renderSectionStep)}
      {section?.forms.map((child, index) => renderStep(child, index))}
    </ul>
  )

  return isRootStep ? (
    content
  ) : (
    <Accordion
      disabled={disabled}
      expanded={open}
      onChange={() => setOpen(!open)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          minHeight: '42px !important',
          opacity: '1 !important',
          '&.Mui-disabled .section, &.Mui-disabled > *': {
            color: 'rgba(0, 0, 0, .38) !important',
          },
          '.MuiAccordionSummary-content': {
            marginBottom: 0,
            marginTop: 0,
            '&.Mui-expanded': {
              margin: '14px 0px',
            },
          },
        }}>
        <span
          style={{
            display: 'flex',
            alignContent: 'center',
            color: COLORS.text.main,
            fontWeight: 500,
          }}>
          <Heading {...section} />
        </span>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
        }}>
        {content}
      </AccordionDetails>
    </Accordion>
  )
}

export default SideMenuSection
