/* eslint-disable max-len */
export const CONTENT = {
  ui: {
    button: {
      nextStep: 'Volgende stap',
      previousStep: 'Vorige stap',
      activate: 'Activeren',
      loading: 'Een moment geduld',
      disabled: 'n.v.t.',
    },
  },
  register: {
    headline: {
      title:
        'Welkom bij het SER Diversiteitsportaal - een portaal voor en door bedrijven',
      content:
        'Dit portaal is voor de rapportage en monitoring van genderdiversiteit in de top en subtop van het bedrijfsleven. Hier kunt u jaarlijks rapporteren over uw streefcijfers, plannen van aanpak, en de man-vrouwverhouding in de top en subtop.',
    },
    success: {
      heading: 'Uw gebruikersaccount is aangemaakt',
      content: [
        'Bevestig uw emailadres via de knop in de bevestigingsmail. Hierna kunt u inloggen met de gegevens die u zojuist heeft opgesteld.',
        'U kunt de pagina nu sluiten.',
      ],
    },
  },
  passwordReset: {
    success_guest: {
      heading: 'Wachtwoord resetten voltooid',
      content: [
        'Vanaf nu kunt u met uw nieuwe wachtwoord inloggen in het Diversiteitsportaal van de Sociaal-Economische Raad.',
        'U kunt deze pagina nu sluiten',
      ],
    },
  },
  forgotPassword: {
    success_guest: {
      heading: 'Wachtwoord resetten',
      content: [
        'Als u een account heeft, kunt u van ons binnen enkele minuten een mail ontvangen.',
        'U kunt de pagina nu sluiten',
      ],
    },
  },
  activation: {
    content: '',
    success_authenticated: {
      heading: 'Activatie gelukt!',
      content: [
        'De genodigden zullen een email uitnodiging ontvangen om deel te nemen aan het diversiteitsportaal.',
      ],
      button: 'Ga naar bedrijfsoverzicht',
    },
    success_guest: {
      heading: 'Activatie gelukt',
      content: [
        'We hebben een uitnodiging gestuurd naar het e-mailadres van de contactpersonen om een persoonlijk gebruikersaccount aan te maken.',
        'U kunt de pagina nu sluiten.',
      ],
      button: 'Naar inlogscherm',
    },
  },
  dashboard: {
    buttons: {
      start_declaration: 'Start Rapportage',
      start_consolidated_declaration: 'Start Geconsolideerde Rapportage',
      view_declaration: 'Rapportage Inzien',
      start_over: 'Herstart rapportage',
      start_intake: 'Start Intake',
      view_intake: 'Bekijk intake',
    },
    declaration_responsibility: {
      parent: 'U rapporteert',
      subsidiary: 'Rapporteert zelf',
      request: {
        parent: 'Verzoekt u te rapporteren',
        subsidiary: 'Verzoekt dochter te rapporteren',
      },
    },
    status: {
      pre_declaration: 'Nog niet begonnen',
      not_started: 'Nog niet begonnen',
      in_progress: 'Bezig met invullen',
      finished: 'Rapportage Afgerond',
    },
    additionalInformation: {
      title: 'Meer informatie',
      cards: [
        {
          id: 1,
          title: 'Over de wet',
          content:
            'Wat houdt de wet “Evenwichtiger man-vrouwverhouding in de top en subtop van het bedrijfsleven” in?',
          button: {
            variant: 'text',
            color: 'primary',
            text: 'Samenvatting bekijken',
            icon: true,
            path: '/explainer/legislation',
          },
        },
        {
          id: 2,
          title: 'Veelgestelde vragen',
          content:
            'Lees de antwoorden op veelgestelde vragen over het diversiteitsportaal van de SER.',
          button: {
            variant: 'text',
            color: 'primary',
            text: 'Veelgestelde vragen bekijken',
            icon: true,
            path: 'https://www.ser.nl/nl/thema/diversiteitsportaal/helpdesk/faq',
            externalLink: true,
          },
        },
        {
          id: 3,
          title: 'Streefcijfers opstellen en rapporteren',
          content:
            'Hoe stelt u streefcijfers op, hoe rapporteert u aan de SER en wat doet de SER met uw rapportage?',
          button: {
            variant: 'text',
            color: 'primary',
            text: 'Handleiding bekijken',
            icon: true,
            path: '/pdf/Handleiding_SER_Diversiteitsportaal_Boekjaar_2022_nov.pdf',
            externalLink: true,
          },
        },
        {
          id: 4,
          title: 'Genderidentiteit en privacy',
          content:
            'Lees meer over de benadering van gender in de wet en het portaal.',
          button: {
            variant: 'text',
            color: 'primary',
            text: 'Lees over genderbenadering',
            icon: true,
            path: '/explainer/gender-identity',
          },
        },
      ],
    },
  },
  welcome: {
    modal: {
      intro: {
        heading: 'Welkom bij de SER',
        content:
          'Heeft u behoefte aan ondersteuning en wilt u informatie, tips en tools van de SER ontvangen over de wet, de rapportage en het plan van aanpak?',
      },
      questions: {
        receive_info: {
          label: (
            <>
              <strong>Tips en tools van de SER ontvangen</strong>
              <div>
                Ja, de SER mag mijn e-mailadres gebruiken om tips en tools te
                sturen.
              </div>
            </>
          ),
        },
      },
      button: {
        label: 'Ga naar home',
      },
    },
  },
  profile: {
    preferences: {
      heading: 'Mijn mailvoorkeuren wijzigen',
      questions: {
        receive_info: {
          label: (
            <>
              <strong>Tips en tools van de SER ontvangen</strong>
              <div>
                Ja, de SER mag mijn e-mailadres gebruiken om tips en tools te
                sturen.
              </div>
            </>
          ),
        },
      },
      submit_button: {
        label: 'Voorkeuren opslaan',
      },
    },
  },
  declaration: {
    success: {
      intro: {
        heading: 'Bevestiging',
        alert: 'Bedankt, het indienen is gelukt.',
        content:
          'Mocht u vragen of opmerkingen hebben over uw rapportage dan kunt u contact opnemen met onze helpdesk.',
        button_download: {
          label: 'Download rapportage',
        },
      },
      showcase: {
        content: (
          <>
            <h3>Delen van good practices?</h3>
            <p>
              Wanneer u van mening bent dat uw bedrijf een good practice is op
              het gebied van (gender)diversiteit in de top en/of subtop, kunt u
              dat hier aangeven. De SER kan u dan benaderen om uw good practice
              te delen op{' '}
              <a href="https://diversiteitsportaal.nl">
                diversiteitsportaal.nl
              </a>
              .
            </p>
          </>
        ),
      },
      trending: {
        heading: 'SER Topvrouwen',
        content:
          'Blijf op de hoogte van evenementen van en informatie over SER Topvrouwen en de database met board-ready vrouwelijke kandidaten',
        button: {
          label: 'Naar SER Topvrouwen',
          url: 'https://www.ser.nl/nl/thema/topvrouwen',
        },
      },
      publications: {
        heading: 'Verder lezen',
        content: (
          <p>
            Voor meer informatie over de wet ingroeiquotum en streefcijfers kunt
            u terecht op{' '}
            <a href="https://diversiteitsportaal.nl">diversiteitsportaal.nl</a>.
            Hier vindt u ook informatie, tools en handreikingen voor succesvol
            diversiteitsbeleid.
          </p>
        ),
        button_1: {
          label: 'Tools en handreikingen',
          url: 'https://www.ser.nl/nl/thema/diversiteitsportaal/tools',
        },
        button_2: {
          label: 'Publicaties',
          url: 'https://www.ser.nl/nl/thema/diversiteitsportaal/publicaties',
        },
      },
      questions: {
        receive_info: {
          label: 'Ja, de SER mag mij hiervoor benaderen',
        },
      },
    },
  },
}
