import { useEffect } from 'react'
import { Controller } from 'react-hook-form'

// Components
import { BooleanQuestion } from '@components'
import IntakeParent from '../IntakeParent'
import IntakeSubsidiaries from '../IntakeSubsidiaries'

// Utils
import interpolate from '@helpers/strings/interpolate'

// Content
import forms from '@constants/forms'

// Styles
import { Alert } from '@components'
import { Box } from '@mui/material'
import '../styles.scss'

const IntakeVersionAFormStep2 = ({
  disabled,
  intake,
  control,
  watch,
  setValue,
}) => {
  // State
  const { company, group_head, status, year } = intake
  const { sections, questions } = forms.intake.a

  // Methods
  const handleSubsidiaryChange = subsidiaries => {
    setValue('subsidiaries', subsidiaries)
  }

  const handleParentChange = parent => {
    setValue('parent', parent)
  }

  // Conditionally render inputs based on previous answers
  const isGroup = watch('is_part_of_group')
  const isGroupHead = watch('is_group_owner')
  const parent = watch('parent')
  const subsidiaries = watch('subsidiaries')
  const groupHeadIsNative = watch('group_head_is_native')

  const disableGroupChoices = status === 'prefilled-by-intake-requests'

  useEffect(() => {
    if (!parent && group_head) handleParentChange(group_head)
  }, [group_head])

  return (
    <>
      <h2 style={{ marginTop: 0 }}>{sections.group.heading}</h2>
      <Controller
        control={control}
        name="is_part_of_group"
        render={({
          field: { name, value, ref, onChange },
          fieldState: { error },
        }) => {
          return (
            <BooleanQuestion
              required
              value={value}
              onChange={onChange}
              question={interpolate(questions[name].label, {
                company: company.name,
                year,
              })}
              alert={{ message: error?.message }}
              inputRef={ref}
              disabled={disabled || disableGroupChoices}
            />
          )
        }}
      />

      {isGroup && (
        <div className="fade-in">
          <Controller
            control={control}
            name="is_group_owner"
            render={({
              field: { name, value, ref, onChange },
              fieldState: { error },
            }) => {
              return (
                <BooleanQuestion
                  required
                  value={value}
                  onChange={onChange}
                  question={interpolate(questions[name].label, {
                    company: company.name,
                    year,
                  })}
                  alert={{ message: error?.message }}
                  inputRef={ref}
                  disabled={disabled || disableGroupChoices}
                />
              )
            }}
          />
        </div>
      )}

      {isGroup && isGroupHead === false && (
        <div className="fade-in">
          <Controller
            control={control}
            name="group_head_is_native"
            render={({
              field: { name, value, ref, onChange },
              fieldState: { error },
            }) => {
              return (
                <BooleanQuestion
                  required
                  value={value}
                  onChange={onChange}
                  question={questions[name].label}
                  alert={{ message: error?.message }}
                  inputRef={ref}
                  disabled={disabled || disableGroupChoices}
                />
              )
            }}
          />
        </div>
      )}

      {isGroup && isGroupHead === false && groupHeadIsNative === true && (
        <div className="fade-in">
          <h2 style={{ marginTop: 26 }}>
            Welk bedrijf was in {year} het hoofd van deze groep?
          </h2>
          <Controller
            name="parent"
            control={control}
            render={({ fieldState: { error } }) => {
              return (
                <>
                  {error && (
                    <Alert
                      content="Vul een bedrijf in als hoofd van de groep"
                      severity="error"
                      className=""
                      sx={{ my: 2 }}
                    />
                  )}
                  <IntakeParent
                    button_label="Voeg een hoofd toe"
                    parent={parent === null ? null : parent || group_head}
                    setParent={handleParentChange}
                    current_kvk={company.kvk_number}
                    disabled={
                      disabled || group_head?.locked || disableGroupChoices
                    }
                  />
                </>
              )
            }}
          />
        </div>
      )}

      {isGroup && isGroupHead && (
        <div className="fade-in">
          <h2 style={{ marginTop: 52 }}>
            Welke grote vennootschappen behoorden in {year} tot deze groep?
          </h2>
          <Alert
            severity="info"
            icon={false}
            content={
              <Box
                padding={1}
                lineHeight={2}>
                <span>
                  Voeg alleen groepsmaatschappijen toe die kwalificeren als
                  "grote" vennootschap. <br />
                  Dat is het geval als de jaarrekening twee jaar achter elkaar
                  voldoet aan minimaal twee van de volgende criteria:
                </span>
                <ul style={{ margin: 0 }}>
                  <li>De waarde van activa bedraagt meer dan €20 miljoen</li>
                  <li>De netto-omzet is meer dan €40 miljoen</li>
                  <li>Het gemiddeld aantal werknemers is 250 of meer</li>
                </ul>
              </Box>
            }
          />
          <Controller
            name="subsidiaries"
            control={control}
            render={({ fieldState: { error } }) => {
              return (
                <>
                  {error && (
                    <Alert
                      content="Vul een groepsmaatschappij in"
                      severity="error"
                      className=""
                      sx={{ my: 2 }}
                    />
                  )}
                  <IntakeSubsidiaries
                    year={year}
                    button_label="Voeg een groepsmaatschappij toe"
                    subsidiaries={subsidiaries}
                    setSubsidiaries={handleSubsidiaryChange}
                    current_kvk={company.kvk_number}
                    disabled={disabled}
                  />
                </>
              )
            }}
          />
        </div>
      )}

      {isGroup && isGroupHead === false && parent && groupHeadIsNative && (
        <div className="fade-in">
          <h2 style={{ marginTop: 52 }}>{sections.sub_report.heading}</h2>
          <Controller
            control={control}
            name="parent.childReportsIndependently"
            render={({
              field: { name, value, ref, onChange },
              fieldState: { error },
            }) => {
              return (
                <BooleanQuestion
                  required
                  value={value}
                  onChange={onChange}
                  question={interpolate(questions[name].label, {
                    company: company.name,
                  })}
                  alert={{ message: error?.message }}
                  inputRef={ref}
                  disabled={disabled || parent?.locked}
                />
              )
            }}
          />
        </div>
      )}
    </>
  )
}

export default IntakeVersionAFormStep2
