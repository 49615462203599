import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const globPattern = /^.+$/
const CodeInput = ({
  onComplete,
  label,
  style,
  layout = {},
  length = 0,
  groupOptions,
  pattern: globalPattern = globPattern,
  children,
}) => {
  const inputRef = useRef([])
  const valuesRef = useRef([])
  const [hasErrors, setHasErrors] = useState()

  const [groups, setGroups] = useState([])
  const { colSpacing = 0, cellSpacing = 0 } = layout

  const register = useCallback((idx, pattern, node) => {
    if (node && !inputRef.current.find(n => n.node === node)) {
      inputRef.current[idx] = { idx, node, pattern, value: null }
      valuesRef.current = {
        ...valuesRef.current,
        [idx]: { value: null, touched: null },
      }
    }
  }, [])

  const isCompleted = useCallback(
    values =>
      Object.entries(values).length &&
      Object.entries(values).every(([, value]) => !!value?.value),
    []
  )

  const onCompleteHandler = useCallback(
    (values = null) => {
      const formatValues = vals => {
        if (groupOptions?.separator && groupOptions?.split) {
          return groups
            .reduce((memo, curr, i) => {
              const { start, end } = curr
              const grpValues = vals.slice(start, end + 1).join('')
              memo.push(
                i < groups.length - 1
                  ? grpValues.padEnd(
                      grpValues.length + 1,
                      groupOptions.separator
                    )
                  : grpValues
              )
              return memo
            }, [])
            .join('')
        }
        return vals.join('')
      }
      onComplete(
        values
          ? formatValues(
              Object.entries(values).map(([, value]) => value?.value)
            )
          : null
      )
    },
    [groups, groupOptions, onComplete]
  )

  const keydownChangeHandler = useCallback(evt => {
    const { key, target } = evt
    const findInputNode = target =>
      inputRef.current.find(input => input?.node === target)
    const { node, idx } = findInputNode(target) || {}
    if (node && key === 'Backspace') {
      if (document.activeElement === node) {
        inputRef.current[idx].node.value = ''
        valuesRef.current[idx] = {
          value: null,
          touched: null,
        }

        inputRef.current[idx - 1]?.node?.select()
        inputRef.current[idx - 1]?.node?.focus()
        evt.preventDefault()
      }
    }
  }, [])

  const inputChangeHandler = useCallback(
    evt => {
      const { target } = evt

      const findInputNode = target =>
        inputRef.current.find(input => input?.node === target)
      const { node, pattern, idx } = findInputNode(target) || {}
      if (node) {
        const { value } = evt.target
        if (!value) {
          valuesRef.current = {
            ...valuesRef.current,
            [idx]: { value: null, touched: null },
          }
          onCompleteHandler()
          return
        }
        const isValidInput =
          (!pattern && true) || (pattern && value.match(pattern))
        const maxIdx = inputRef.current.length
        const nextIdx = Math.min(maxIdx - 1, idx + 1)
        const isLastInput = idx >= maxIdx - 1
        if (!isValidInput) {
          inputRef.current[idx]?.node.select()
          valuesRef.current = {
            ...valuesRef.current,
            [idx]: { value: null, touched: true },
          }
          setHasErrors(true)
          onCompleteHandler()
        } else {
          // valid input
          setHasErrors(false)
          valuesRef.current = {
            ...valuesRef.current,
            [idx]: { value, touched: true },
          }
          // complete?
          !isLastInput && inputRef.current[nextIdx].node.focus()
          if (inputRef.current[nextIdx].node.value) {
            inputRef.current[nextIdx].node.select()
          }
          if (isCompleted(valuesRef.current)) {
            onCompleteHandler(valuesRef.current)
          }
        }
      }
    },
    [onCompleteHandler, isCompleted]
  )

  useEffect(() => {
    document.body.addEventListener('input', inputChangeHandler)
    return () => {
      document.body.removeEventListener('input', inputChangeHandler)
    }
  }, [inputChangeHandler])

  useEffect(() => {
    document.body.addEventListener('keydown', keydownChangeHandler)
    return () =>
      document.body.removeEventListener('keydown', keydownChangeHandler)
  }, [keydownChangeHandler])

  useEffect(() => {
    const pasteEventhandler = evt => {
      const data = (evt.clipboardData || window.clipboardData).getData('text')
      if (data) {
        try {
          data
            .split('-')
            .join('')
            .split('')
            .forEach((code, idx) => {
              inputRef.current[idx].node.value = code
              valuesRef.current[idx] = { value: code, touched: true }
            })
        } catch (e) {
          // console.log(
          //   `Given code ${data} probably is not a correct activation code`
          // )
        }
      }
    }
    if (inputRef.current.length) {
      inputRef.current[0].node?.addEventListener('paste', pasteEventhandler)
    }
    return () => {
      inputRef.current[0].node?.removeEventListener('paste', pasteEventhandler)
    }
  }, [])

  const renderGroups = useMemo(() => {
    const { split = [length], pattern = [] } = groupOptions || {}
    const groups = split.reduce(
      (memo, curr, i) => {
        const prevEnd = memo[i - 1]?.end || -1
        const start = prevEnd + 1
        const end = start + curr - 1
        memo[i] = {
          length: curr,
          pattern: pattern[i] || globalPattern,
          start,
          end,
          hasErrors,
          values: valuesRef.current,
          cellSpacing,
          register,
        }
        return memo
      },
      [hasErrors]
    )

    if (!groups) return null
    setGroups(
      groups.map(grp => ({
        start: grp.start,
        end: grp.end,
        length: grp.length,
      }))
    )
    return groups.map((grp, i) => (
      <InputGroup
        key={i}
        {...grp}>
        {children}
      </InputGroup>
    ))
  }, [
    length,
    hasErrors,
    groupOptions,
    children,
    cellSpacing,
    globalPattern,
    register,
  ])

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
      }}>
      {label && <div style={{ flexGrow: 1, width: '100%' }}>{label}</div>}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: `${colSpacing}rem`,
          flexWrap: 'nowrap',
        }}>
        {renderGroups}
      </div>
    </div>
  )
}

const InputGroup = ({
  length,
  values,
  start,
  pattern,
  register,
  className,
  cellSpacing,
  children,
}) => {
  const renderGroup = useMemo(() => {
    if (!children) return null
    return Array.from(Array(length).keys()).map(n => {
      const isValid = !!values[start + n]?.value
      const touched = values[start + n]?.touched
      const elem = children(isValid, touched)
      return React.cloneElement(elem, {
        ...elem.props,
        key: `code-input-${start + n}`,
        ref: node => register(start + n, pattern, node),
        className,
      })
    })
  }, [children, pattern, values, className, length, register, start])

  return (
    <div style={{ display: 'inline-flex', gap: `${cellSpacing}rem` }}>
      {renderGroup}
    </div>
  )
}

CodeInput.Group = InputGroup
export { CodeInput }
