// Store
import { useSharedState } from '@store/ui.store'
import { modal } from '@store/modal.state'

// Material UI
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'

// UI
import { Button } from '@components'

// Icons
import Close from '@mui/icons-material/Close'

const Modal = () => {
  // State
  const modalState = useSharedState('modal')

  const handleClose = () => {
    if (!modalState?.hideClose) modal.setClose()
  }

  return (
    <div>
      {modalState && (
        <Dialog
          open={modalState.open}
          onClose={handleClose}
          disableScrollLock
          {...modalState.props}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: !modalState.hideClose ? '0 50px 0 0' : 0,
            }}>
            {modalState.customHeading ? (
              modalState.customHeading
            ) : (
              <DialogTitle
                sx={{
                  maxWidth: '100%',
                }}>
                {modalState.heading}
              </DialogTitle>
            )}
            {!modalState?.hideClose && (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: theme => theme.palette.primary.main,
                }}>
                <Close />
              </IconButton>
            )}
          </div>
          <DialogContent>{modalState.content}</DialogContent>
          {modalState.buttons?.length > 0 && (
            <DialogActions>
              {modalState.buttons?.map(({ text, ...button }, key) => {
                return (
                  <Button
                    key={key}
                    {...{ onClick: handleClose, ...button }}>
                    {text}
                  </Button>
                )
              })}
            </DialogActions>
          )}
        </Dialog>
      )}
    </div>
  )
}

export default Modal
