/* eslint-disable max-len */
import single from '@assets/graphics/intake/single.png'
import consolidated from '@assets/graphics/intake/consolidated.png'

export const INTAKE_VERSION_A = {
  sections: {
    criteria: {
      heading: 'Voldeed {{company}} in {{year}} aan de volgende criteria?',
    },
    extended_year: {
      heading: 'Gebroken boekjaar?',
    },
    obligations: {
      heading: 'Gezamenlijke rapportage?',
      intro: (
        <>
          <p>
            Als u tot een groep behoort, kunt u bepalen of de grote
            vennootschappen binnen de groep zelf rapporteren of dat het
            groepshoofd dat voor hen doet. Groepsmaatschappijen spreken samen af
            op welke manier aan de rapportageverplichting wordt voldaan.
          </p>
          <p>
            Hieronder kunt u per groepsmaatschappij aangeven of u als hoofd
            namens een groepsmaatschappij wil rapporteren of niet.
          </p>
        </>
      ),
    },
    group: {
      heading: 'Wel of geen groep?',
    },
    subsidiaries: {
      heading: 'Groepsmaatschappijen toevoegen',
      no_subsidiaries: 'U heeft geen dochterondernemingen.',
      has_subsidiaries: 'Eerder geaccepteerde dochterondernemingen.',
      add_subsidiaries: 'Voeg een bedrijf toe aan uw groep',
    },
    report: {
      heading:
        'Rapporteert u voor de groepsmaatschappijen gezamenlijk of apart?',
      intro:
        'Wanneer u als hoofd van de groep rapporteert voor één of meer groepsmaatschappijen, kunt u kiezen om de rapportage per bedrijf in te vullen of gezamenlijk. Hoe wilt u de rapportage opstellen?',
    },
    sub_report: {
      heading: 'Wie rapporteert?',
    },
    alert: {
      finalize:
        'Na het afronden van de intake kunt u de intake niet meer wijzigen. Toch iets veranderen? Start de intake dan opnieuw.',
    },
  },
  questions: {
    is_private_or_limited_company: {
      label: 'Is {{company}} een vennootschap (bv of nv)?',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    has_other_legal_form: {
      label: 'Anders:',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      options: [
        {
          id: 1,
          value: 'stichting',
          label: 'Stichting',
        },
        {
          id: 2,
          value: 'cooperatie',
          label: 'Coöperatie',
        },
        {
          id: 3,
          value: 'vereniging',
          label: 'Vereniging',
        },
        {
          id: 4,
          value: 'zbo',
          label: 'ZBO',
        },
        {
          id: 5,
          value: 'gemeenschappelijke-regeling',
          label: 'Gemeenschappelijke Regeling',
        },
        {
          id: 6,
          value: 'publiekrechtelijke-orgaan',
          label: 'Publiekrechtelijke orgaan',
        },
        {
          id: 7,
          value: 'anders',
          label: 'Anders',
        },
      ],
    },
    has_stock_exchange_listing: {
      label: 'Had {{company}} in {{year}} een beursnotering in Nederland?',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    has_minimum_asset_value: {
      label:
        'De waarde van de activa bedraagt volgens de balans meer dan € 20 miljoen',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    has_minimum_net_sales: {
      label: 'De netto-omzet bedraagt meer dan € 40 miljoen',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    has_minimum_employees: {
      label: 'Het gemiddeld aantal werknemers is 250 of meer',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    is_part_of_group: {
      label: 'Behoorde {{company}} in {{year}} tot een groep?',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    is_group_owner: {
      label: 'Was {{company}} hoofd van deze groep?',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    group_head_is_native: {
      label:
        'Was het hoofd van uw groep een Nederlandse ‘grote’ vennootschap (bv/nv)?',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    report_for_others: {
      label: 'Rapporteert u als hoofd van de groep namens {{company}}?',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    consolidated: {
      label: 'Hoe stelt u de rapportage op?',
      required: {
        value: true,
        message: 'Deze vraag is verplicht',
      },
      options: [
        {
          id: 2,
          value: true,
          label: 'Geconsolideerd',
          title:
            'We rapporteren gezamenlijk voor meerdere vennootschappen binnen de groep',
          content:
            'U vult de rapportage één keer in, voor alle vennootschappen samen. U stelt een streefcijfer op dat geldt voor alle besturen / raden van commissarissen van de vennootschappen in de gezamenlijke rapportage. U definieert de subtop op het niveau van de groep en stelt hier één streefcijfer voor op. U maakt één plan van aanpak dat geldt voor alle vennootschappen in de gezamenlijke rapportage. ',
          selected: false,
          unavailable: false,
          image: {
            src: consolidated,
            objectFit: 'contain',
          },
        },
        {
          id: 1,
          value: false,
          label: 'Afzonderlijk',
          title: 'We rapporteren voor elke vennootschap afzonderlijk',
          content:
            'U vult de rapportage apart in voor elke vennootschap waarvoor u rapporteert. U stelt voor elke vennootschap afzonderlijk streefcijfers vast voor het bestuur, de rvc en de subtop en u stelt voor elke vennootschap een plan van aanpak op.',
          selected: false,
          image: {
            src: single,
            objectFit: 'contain',
          },
        },
      ],
    },
    has_extended_tax_year: {
      label: 'Heeft {{company}} een gebroken boekjaar?',
    },
    start_tax_year_at: {
      label: 'Selecteer de startdatum van uw boekjaar:',
    },
    end_tax_year_at: {
      label: 'Controleer de einddatum van uw boekjaar:',
    },
    'parent.childReportsIndependently': {
      label: 'Rapporteert {{company}} zelf?',
    },
  },
}
