const currentOverview = {
  sections: {
    overview: {
      heading: 'Overzicht',
    },
    balance: {
      heading:
        'Man-vrouwverhouding in de top, subtop en organisatie op {{taxYear}}',
      grid: {
        men: 'Aantal mannen',
        women: 'Aantal vrouwen',
        percentage: '% Vrouwen',
        target: 'Streefcijfer (Streefjaar)',
        boardDirectors: 'Bestuur',
        supervisoryBoard: 'RvC',
        subtop: 'Subtop',
        total: 'Organisatie',
        subtopDefinition: 'Definitie subtop',
      },
    },
  },
  questions: {
    code_1: {
      label: 'Toelichting voor de dataverkenner',
      required: {
        value: false,
      },
    },
  },
}

export default currentOverview
