// Components
import { ExplainerScreen } from '@components'

export const LegislationView = () => {
  const title = 'De wetgeving in het kort'
  const content = (
    <>
      <p>
        <strong>
          Voor grote bedrijven in Nederland geldt sinds 1 januari 2022 een wet
          die als doel heeft om de verhouding tussen vrouwen en mannen in de top
          en subtop van grote bedrijven evenwichtiger te maken. De wet is bekend
          om het ingroeiquotum - ook bekend als het 'vrouwenquotum' of
          'diversiteitsquotum' - maar bevat ook andere regelingen.
        </strong>
      </p>
      <p>
        De wet bestaat uit een streefcijferregeling en transparantieverplichting
        voor de 5.000 'grote' vennootschappen in Nederland{' '}
        <a
          className="link"
          href="https://www.ser.nl/nl/thema/diversiteitsportaal/inhoud-wet"
          target="_blank"
          rel="noreferrer">
          (Lees hier meer informatie)
        </a>
        . Grote vennootschappen worden verplicht zelf passende en ambitieuze
        streefcijfers te formuleren voor de verhouding tussen vrouwen en mannen
        in het bestuur, de RvC en de subtop en plannen te maken om de
        streefcijfers te realiseren. Zij moeten jaarlijks rapporteren aan de SER
        over de streefcijfers en de voortgang.
      </p>
      <p>
        Grote beursvennootschappen stellen uitsluitend streefcijfers en plannen
        op voor het bestuur en de subtop vanwege het ingroeiquotum voor de RvC.
      </p>
      <p>
        De rapportage gebeurt in het Diversiteitsportaal van de SER, via een
        vast format. De SER maakt de streefcijfers, plannen en voortgang van elk
        bedrijf jaarlijks op een vast tijdstip zichtbaar voor publiek via de{' '}
        <a
          href="https://www.ser.nl/nl/thema/diversiteitsportaal/dataverkenner"
          target="_blank"
          rel="noreferrer">
          SER Dataverkenner
        </a>
        .
      </p>
      <p>
        Het SER diversiteitsportaal maakt de komende jaren inzichtelijk hoe het
        ervoor staat met de man-vrouwverhouding in de top van grote bedrijven en
        is een bron van informatie en inspiratie voor bedrijven.
      </p>
    </>
  )
  return (
    <main>
      <ExplainerScreen
        title={title}
        content={content}
      />
    </main>
  )
}
