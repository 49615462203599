import { COLORS } from '@constants'

const styles = {
  page: {
    backgroundColor: COLORS.secondary.main,
  },
  heading: {
    marginTop: 0,
  },
  invite_cards: {
    display: 'grid',
    gap: '24px',
  },
  company_cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',
  },
  card: {
    backgroundColor: COLORS.white.main,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  card__title: {
    margin: 0,
  },
  card__content: {
    margin: '12px 0 24px 0px',
    whiteSpace: 'pre-line',
  },
  card__button: {
    marginTop: 'auto',
    marginBottom: 0,
  },
}

export default styles
