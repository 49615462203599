import { styled } from '@mui/system'

export const StyledSection = styled('section', {
  shouldForwardProp: prop => prop !== 'columns',
})(({ theme, color = 'primary' }) => ({
  backgroundColor: theme.palette.background[color],
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  minHeight: '80vh',
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
}))
