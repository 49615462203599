import { ErrorBoundary, Provider } from '@rollbar/react'
import React from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import Rollbar from 'rollbar'
import { createRoutes } from './router/routes'

// Store
import { startAuthSubscription } from '@store/auth.state'

// Styles
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'

// Components
import { Modal, Toaster } from '@components'
import ScrollToTop from './utils/components/ScrollToTop'

startAuthSubscription()

const ROLLBAR_KEY = process.env.REACT_APP_ROLLBAR_KEY
const ROLLBAR_ENVIRONMENT = process.env.REACT_APP_ROLLBAR_ENVIRONMENT

const RollbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  if (!(ROLLBAR_KEY && ROLLBAR_ENVIRONMENT)) return <>{children}</>

  const rollbarConfig = new Rollbar({
    accessToken: ROLLBAR_KEY,
    environment: ROLLBAR_ENVIRONMENT,
  })

  window.Rollbar = rollbarConfig

  return (
    <Provider instance={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  )
}

const App: React.FC = () => {
  const routes = useRoutes(createRoutes(useLocation()))

  return (
    <RollbarProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ScrollToTop />
        {routes}
        <Toaster />
        <Modal />
      </ThemeProvider>
    </RollbarProvider>
  )
}

export default App
