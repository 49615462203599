/* eslint-disable max-len */
const ratioDirectors = {
  sections: {
    total: {
      subHeading: '',
      heading: 'Man-vrouwverhouding in het bestuur van {{company_name}}',
      number_heading: 'Hoe staat het er voor?',
      intro: 'Hoeveel vrouwen en mannen had het bestuur in {{year}}?',
      director: 'Voorzitter',
    },
  },
  questions: {
    code_1: {
      label:
        'N.v.t., deze groepsmaatschappij heeft geen bestuur dat uit een natuurlijke personen bestaat.',
    },
    'code_2.male': {
      col_label: 'Aantal mannen',
      label: 'Totaal op {{taxYear}}',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_2.female': {
      col_label: 'Aantal vrouwen',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_3.male': {
      label: 'Herbenoemd dit boekjaar',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_3.female': {
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_4.male': {
      label: 'Nieuw benoemd dit boekjaar',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_4.female': {
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_5.male': {
      label: 'Afgetreden dit boekjaar',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_5.female': {
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    code_6: {
      label: 'Aantal niet ingevulde vacatures {{taxYear}}',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    code_7: {
      label: 'De voorzitter identificeert zich als:',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      options: [
        {
          id: 1,
          value: 'male',
          label: 'Man',
        },
        {
          id: 2,
          value: 'female',
          label: 'Vrouw',
        },
        {
          id: 3,
          value: 'non-binary',
          label: 'Non-binair',
        },
        {
          id: 4,
          value: 'no-chair',
          label: 'Er is geen voorzitter',
          style: {
            marginTop: '12px',
            flex: 1,
          },
        },
      ],
    },
  },
}

export default ratioDirectors
