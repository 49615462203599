/* eslint-disable max-len */
import fallback from '@constants/forms/fallback'
import regex from '@validation/regex'

const companyDetails = {
  sections: {
    details: {
      heading: 'Bedrijfsgegevens',
    },
    contact: {
      heading: 'Contactgegevens',
      intro:
        'De SER gebruikt deze adresgegevens uitsluitend voor het versturen van activatiecodes en uitnodigingen voor de jaarlijkse rapportage.',
    },
    employees: {
      heading: 'Genderdiversiteit',
      intro:
        'Bedrijven kunnen rekening houden met personen die zich niet thuis voelen binnen het klassieke man-vrouwhokje. Gelet op de privacy, kan het onwenselijk zijn als dit soort gegevens te herleiden zijn tot bepaalde personen. Daarom kunnen personen uit deze categorie meegeteld worden bij het ondervertegenwoordigde geslacht.',
    },
    employee_balance: {
      heading: 'Aantal werknemers',
      subheading:
        'Hoeveel werknemers waren in uw bedrijf in dienst op {{taxYear}}?',
    },
  },
  questions: {
    code_1: {
      label: 'KvK-nummer',
      required: {
        value: true,
        message: fallback.required,
      },
      regex: {
        value: regex.kvk_number,
        message: 'Uw KVK nummers bestaat uit 8 cijfers',
      },
      disabled: true,
    },
    code_2: {
      label: 'Statutaire naam',
      required: {
        value: true,
        message: fallback.required,
      },
      disabled: true,
    },
    code_3: {
      label: 'Bedrijfsnaam / Groepsnaam',
      required: {
        value: true,
        message: fallback.required,
      },
    },
    code_4: {
      label: 'Bedrijfstak (hoofdactiviteit van uw bedrijf)',
      required: {
        value: true,
        message: fallback.required,
      },
      options: [
        {
          id: 1,
          value: 'A',
          label: 'A - Landbouw, bosbouw en visserij',
        },
        {
          id: 2,
          value: 'B',
          label: 'B - Delfstoffenwinning',
        },
        {
          id: 3,
          value: 'C',
          label: 'C - Industrie',
        },
        {
          id: 4,
          value: 'D',
          label: 'D - Energievoorziening',
        },
        {
          id: 5,
          value: 'E',
          label: 'E - Waterbedrijven en afvalbeheer',
        },
        {
          id: 6,
          value: 'F',
          label: 'F - Bouwnijverheid',
        },
        {
          id: 7,
          value: 'G',
          label: 'G - Handel',
        },
        {
          id: 8,
          value: 'H',
          label: 'H - Vervoer en opslag',
        },
        {
          id: 9,
          value: 'I',
          label: 'I - Horeca',
        },
        {
          id: 10,
          value: 'J',
          label: 'J - Informatie en communicatie',
        },
        {
          id: 11,
          value: 'K',
          label: 'K - Financiële dienstverlening',
        },
        {
          id: 12,
          value: 'L',
          label: 'L - Verhuur en handel van onroerend goed',
        },
        {
          id: 13,
          value: 'M',
          label: 'M - Specialistische zakelijke diensten',
        },
        {
          id: 14,
          value: 'N',
          label: 'N - Verhuur en overige zakelijke diensten',
        },
        {
          id: 15,
          value: 'O',
          label: 'O - Openbaar bestuur en overheidsdiensten',
        },
        {
          id: 16,
          value: 'P',
          label: 'P - Onderwijs',
        },
        {
          id: 17,
          value: 'Q',
          label: 'Q - Gezondheids- en welzijnszorg',
        },
        {
          id: 18,
          value: 'R',
          label: 'R - Cultuur, sport en recreatie',
        },
        {
          id: 19,
          value: 'S',
          label: 'S - Overige dienstverlening',
        },
        {
          id: 20,
          value: 'T',
          label: 'T - Huishoudens',
        },
        {
          id: 21,
          value: 'U',
          label: 'U - Extraterritoriale organisaties',
        },
      ],
    },
    code_5: {
      label: 'Adres (postadres)',
      required: {
        value: true,
        message: fallback.required,
      },
    },
    code_6: {
      label: 'Postcode',
      regex: {
        value: regex.postal_code,
        message: 'Vul een valide postcode in',
      },
      required: {
        value: true,
        message: fallback.required,
      },
    },
    code_7: {
      label: 'Plaats',
      required: {
        value: true,
        message: fallback.required,
      },
    },
    code_8: {
      label: 'Telefoonnummer',
      required: {
        value: true,
        message: fallback.required,
      },
    },
    code_9: {
      label:
        'Worden in deze rapportage personen die zich niet thuis voelen binnen het klassieke man-vrouwhokje meegeteld bij het ondervertegenwoordigde geslacht?',
      required: {
        value: true,
        message: fallback.required,
      },
    },
    code_10: {
      male: {
        label: 'Aantal mannen',
        required: {
          value: true,
          message: fallback.required,
        },
        inputProps: {
          min: 0,
        },
      },
      female: {
        label: 'Aantal vrouwen',
        required: {
          value: true,
          message: fallback.required,
        },
        inputProps: {
          min: 0,
        },
      },
      total: {
        label: 'Totaal aantal werknemers',
      },
    },
  },
}

export default companyDetails
