import { useState, useEffect } from 'react'

interface Props {
  initialVal: number
  newVal: number
  duration?: number
}

const NumberCounter: React.FC<Props> = ({
  initialVal,
  newVal,
  duration = 50,
}) => {
  const [count, setCount] = useState(initialVal || 0)

  useEffect(() => {
    if (count === Math.round(newVal)) return

    const interval = window.setInterval(() => {
      if (count !== newVal) {
        let change = (newVal - count) / 10
        change = change >= 0 ? Math.ceil(change) : Math.floor(change)
        setCount(count + change)
      }
    }, duration)

    return () => window.clearInterval(interval)
  }, [newVal, count, duration])

  return <>{count}</>
}

export default NumberCounter
