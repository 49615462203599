import './style.scss'

const Skeleton = ({
  width = '100%',
  height = 'auto',
  borderRadius = '5px',
  margin = '6px 0px',
}) => {
  return (
    <div
      className="skeleton"
      style={{
        width,
        height,
        borderRadius,
        margin,
      }}></div>
  )
}

export default Skeleton
