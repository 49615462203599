// React
import { useEffect, useMemo, useState } from 'react'
import {
  generatePath,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'

// Store & services
import { intakeHooks } from '@services'

// MUI
import { Container } from '@mui/material'

// Components
import { Alert, Breadcrumb, Loader, Panel } from '@components'
import IntakeFormVersionController from '@partials/forms/intake/IntakeFormVersionController'
import { toaster } from '@store/toaster.state'

const IntakeView = () => {
  // State
  const [loading, setLoading] = useState(true)
  const [loadingError, setLoadingError] = useState('')
  const [intake, setIntake] = useState(null)
  const { setSectionColor } = useOutletContext()

  // Hooks
  const navigate = useNavigate()
  const { intake_id } = useParams()
  const { useGetIntake } = intakeHooks
  const [fetchIntake, , res, err] = useGetIntake()

  const breadcrumbItems = useMemo(
    () => [
      { name: 'Home', path: '/dashboard' },
      {
        name: res?.data?.company?.name,
        path:
          res?.data?.company?.id &&
          generatePath('/company/:company_id', {
            company_id: res?.data?.company?.id,
          }),
      },
      { name: 'Intake ' + res?.data?.year },
    ],
    [res]
  )

  // Intake
  useEffect(() => {
    setSectionColor('default')
    if (!intake_id) {
      setLoadingError('We hebben geen rapportage kunnen vinden')
      setLoading(false)
    } else {
      fetchIntake(intake_id)
    }
  }, [intake_id, setSectionColor, fetchIntake])

  // Fetch initial declaration
  useEffect(() => {
    if (res && res.data) {
      // Intake content
      const { intake_version } = res.data

      // TEMP Version addition > Test 'B' to switch
      let _version = intake_version ?? 'B'

      setIntake({ ...res.data, intake_version: _version })

      setLoading(false)
    }
    if (err?.errors) {
      toaster.error(err.errors)
      setLoading(false)
      setLoadingError('We hebben geen intake kunnen vinden.')
    }
  }, [res, err, setLoading, setLoadingError, navigate])

  if (loading) {
    return (
      <Container>
        <Panel
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh',
          }}>
          <Loader text="Intake aan het laden" />
        </Panel>
      </Container>
    )
  }

  if (loadingError) {
    return (
      <Container>
        <Panel>
          <Alert
            severity="error"
            content={loadingError}
          />
        </Panel>
      </Container>
    )
  }

  return (
    <div style={{ marginTop: '-56px' }}>
      <Container>
        <Breadcrumb items={breadcrumbItems} />
      </Container>
      <IntakeFormVersionController {...intake} />
    </div>
  )
}

export default IntakeView
