// State
import { modal } from '@store/modal.state'

// Components
import { Button } from '@components'
import AddSubsidiaryModal from '@partials/modals/AddSubsidiaryModal'

// Icons
import AddIcon from '@mui/icons-material/Add'

// Styles
import { IntakeCompany } from './intakeCompany'
import './styles.scss'

const IntakeParent = ({
  intake_version = 'A',
  button_label,
  parent,
  setParent,
  current_kvk,
  disabled,
}) => {
  const pushParent = parent => {
    setParent(parent)
    modal.setClose()
  }

  const openSubsidiaryModal = () => {
    const modalBody = {
      heading: 'Een bedrijf aan uw groep toevoegen',
      content: (
        <AddSubsidiaryModal
          push={pushParent}
          current_kvk={current_kvk}
        />
      ),
      buttons: [],
      props: {
        maxWidth: 'unset',
      },
    }
    modal.setOpen(modalBody)
  }

  return (
    <div className="company-list">
      {!disabled && (
        <Button
          type="button"
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={openSubsidiaryModal}
          disabled={!!parent}>
          {button_label}
        </Button>
      )}
      {parent?.kvk_number && (
        <IntakeCompany
          version={intake_version}
          company={parent}
          onRemove={() => setParent(null)}
          disabled={disabled || parent.locked}
        />
      )}
    </div>
  )
}

export default IntakeParent
