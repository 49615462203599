import { Alert } from '@components'

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

// Styles
import { COLORS } from '@constants'

interface Props {
  visibility: 'public' | 'private'
  customText?: string
}

const Visibility: React.FC<Props> = ({ visibility = 'public', customText }) => {
  const isPublic = !visibility || visibility === 'public'
  let content = 'Deze informatie is zichtbaar voor het publiek. '

  if (!isPublic && !customText) {
    content = 'Deze informatie is niet zichtbaar voor het publiek. '
  }

  if (customText) {
    content = customText
  }
  return (
    <Alert
      severity="info"
      content={content}
      icon={
        isPublic ? (
          <VisibilityIcon htmlColor={COLORS.text.main} />
        ) : (
          <VisibilityOffIcon htmlColor={COLORS.text.main} />
        )
      }
    />
  )
}

export default Visibility
