import { styled } from '@mui/system'

export const StyledPanel = styled('div', {
  shouldForwardProp: prop => prop !== 'start',
})(({ theme, start = 1, end }) => ({
  gridColumnStart: start,
  ...(end && {
    gridColumnEnd: `span ${end}`,
  }),
  gridColumn: `auto / span ${end}`,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.white.main,
}))

export const StyledGridItem = styled('div', {
  shouldForwardProp: prop => prop !== 'start',
})(({ theme, start, end }) => ({
  ...(start && {
    gridColumn: `${start} / span ${end}`,
  }),
  ...(!start && {
    gridColumn: `auto / span ${end}`,
  }),
  backgroundColor: theme.palette.white.main,
}))

const styles = {
  panel: {
    backgroundColor: '#FFFFFF',
    padding: 40,
    '& > div': {
      backgroundColor: 'red',
    },
  },
}

export default styles
