import { COLORS } from '@constants'

const styles = {
  actionButton: {
    color: COLORS.black.main,
    background: 'none',
    padding: '0px',
    textDecoration: 'underline',
    alignSelf: 'center',
    marginBottom: '8px',
  },
}

export default styles
