import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

interface Props {
  text?: string
  style?: object
}

const Loader: React.FC<Props> = ({ text, style }) => {
  return (
    <div style={{ ...styles.loader, ...style }}>
      <span style={styles.text}>{text}</span>
      <CircularProgress />
    </div>
  )
}

const styles = {
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
    height: '100%',
  },
  text: {
    marginBottom: 24,
    fontSize: 24,
    fontWeight: 400,
  },
}

export default Loader
