export const COLORS = {
  background: {
    default: '#F5F5F5',
    primary: '#D3E7F4',
    main: '#F5F5F5',
    contrastText: '#37493B',
  },
  primary: {
    main: '#241D6D',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#D3E7F4',
    contrastText: '#241D6D',
  },
  tertiary: {
    main: 'rgba(84, 180, 228, 1)',
    contrastText: '#FFFFFF',
  },
  lightBlue: {
    main: 'rgb(84, 180, 228)',
    contrastText: '#FFFFFF',
  },
  buttonIcon: {
    main: '#FFFFFF',
  },
  navigation: {
    main: '#006864',
    mainTransparent: 'rgba(0, 104, 100, 0.5)',
    active: '#4CB6B6',
    contrastText: '#fff',
  },
  text: {
    main: '#37474F',
    primary: '#37474F',
  },
  header: {
    main: '#EEE',
  },
  white: {
    main: '#ffffff',
    contrastText: '#37474F',
  },
  black: {
    main: '#000',
    contrastText: '#ffffff',
  },
  gray: {
    main: 'rgba(185, 185, 186, 1)',
    contrastText: '#37474F',
    disabled: 'rgba(185, 185, 186, 1)',
  },
  success: {
    main: '#999E19',
    light: 'rgba(217, 219, 162)',
    contrastText: '#8D932C',
    dark: '#3C9214',
  },
  error: {
    // main: '#FF0000',
    main: '#F5E0DE',
    contrastText: '#C43C2C',
  },
  warning: {
    main: '#D89427',
    light: '#FBD79E',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#D3E7F4',
    contrastText: '#241D6D',
  },
  disabled: {
    main: 'rgba(238, 238, 238, 1)',
    contrastText: 'rgba(185, 185, 186, 1)',
  },
}
