import SideMenuSection from './SideMenuSection'

// styles
import './style.scss'

const SideMenu = ({
  year,
  company,
  sections,
  activeSubsection,
  activeStep,
  currentStep,
  handleFormNavigation,
  declarationCompleted,
  hidden,
}) => {
  const currentSection = sections.filter(section => !section.completed)[0]

  const handleNavigation = (section, form) => {
    handleFormNavigation({ section, form })
  }

  return (
    <div className={`side-menu ${hidden ? 'hidden' : ''}`}>
      <div className="side-menu__current">
        <span className="text--medium">Rapportage {year}</span>
        <span>{company.name}</span>
        {company?.group && <span>{company.group}</span>}
      </div>
      <div className="side-menu__nav">
        {sections &&
          sections.map((section, i) => {
            return (
              <SideMenuSection
                key={i}
                section={section}
                currentSection={currentSection}
                activeSubsection={activeSubsection}
                activeStep={activeStep}
                currentStep={currentStep}
                handleNavigation={handleNavigation}
                declarationCompleted={declarationCompleted}
              />
            )
          })}
      </div>
    </div>
  )
}

export default SideMenu
