/* eslint-disable max-len */

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

// API
import { intakeHooks } from '@services'
import { toaster } from '@store/toaster.state'

// Material
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Container } from '@mui/material'

// Constants
import intakeForm from '@constants/forms/intake'
import { INTAKE_FORM } from '../intakeFormVersions.constant'

// Components
import { Alert, Button, Panel } from '@components'

// Styles
import '../styles.scss'

const IntakeFormVersionA = ({ intake }) => {
  // Hooks
  const navigate = useNavigate()
  const steps = useMemo(() => INTAKE_FORM.A.STEPS, [])
  const { usePatchIntake } = intakeHooks
  const [submitIntake, loading, submitSuccess, submitError] = usePatchIntake()
  const [currentStep, setCurrentStep] = useState(1)
  const STEP_COUNT = useMemo(() => steps?.length, [steps])
  const disabled = useMemo(() => intake.status === 'submitted', [intake])

  const {
    consolidated,
    is_private_or_limited_company,
    has_other_legal_form,
    has_stock_exchange_listing,
    has_minimum_asset_value,
    has_minimum_net_sales,
    has_minimum_employees,
    is_part_of_group,
    group_head_is_native,
    is_group_owner,
    group_head,
    subsidiaries,
    type,
    has_extended_tax_year,
    start_tax_year_at,
    end_tax_year_at,
  } = intake

  let formValues = useMemo(
    () => ({
      consolidated,
      is_private_or_limited_company: is_private_or_limited_company ?? null,
      group_head_is_native: group_head_is_native ?? null,
      has_other_legal_form: has_other_legal_form ?? '',
      has_stock_exchange_listing: has_stock_exchange_listing ?? null,
      has_minimum_asset_value: has_minimum_asset_value ?? null,
      has_minimum_net_sales: has_minimum_net_sales ?? null,
      has_minimum_employees: has_minimum_employees ?? null,
      is_part_of_group: is_part_of_group ?? null,
      is_group_owner: is_group_owner ?? null,
      group_head: group_head ?? null,
      subsidiaries: subsidiaries,
      type: type ?? null,
      has_extended_tax_year: has_extended_tax_year ?? null,
      start_tax_year_at: start_tax_year_at ?? null,
      end_tax_year_at: end_tax_year_at ?? null,
    }),
    [intake]
  )

  // Forms
  const { control, watch, setValue, setError, clearErrors, handleSubmit } =
    useForm({
      defaultValues: formValues,
    })

  const isGroup = watch('is_part_of_group')
  const isGroupHead = watch('is_group_owner')

  // Form submit
  const onSubmit = useCallback(
    async (body, finalize = false) => {
      // Empty any subsidiaries if the company is not part of a group (should be emtpy already, just an extra check)
      if (body.is_part_of_group === false) body.is_group_owner = null
      if (body.is_part_of_group === false || !body.is_group_owner) {
        body.subsidiaries = []
      }

      // Empty parent if company is group owner
      if (body.is_group_owner) {
        body.parent = null
      }

      // Empty group_head_is_native if
      if (
        body.is_part_of_group === false ||
        (body.is_part_of_group && body.is_group_owner)
      ) {
        body.group_head_is_native = null
      }

      body.step = currentStep
      body.finalize = finalize

      if (body.has_extended_tax_year) {
        body.end_tax_year_at = new Date(body.end_tax_year_at)
        body.start_tax_year_at = new Date(body.start_tax_year_at)

        body.end_tax_year_at.setHours(8)
        body.start_tax_year_at.setHours(8)

        if (body.end_tax_year_at.getTime() < body.start_tax_year_at.getTime()) {
          return setError('end_tax_year_at', {
            message: 'Einddatum moet later zijn dan de startdatum',
          })
        } else clearErrors()
      }

      if (disabled) return setCurrentStep(step => step + 1)

      try {
        const submitResponse = await submitIntake({
          intake_id: intake.id,
          body,
        })

        if (submitResponse.data?.status === 'submitted') {
          return navigate(`/intake/success/${intake.id}`)
        }

        if (submitResponse?.errors) return

        if (currentStep < 3) setCurrentStep(step => step + 1)
      } catch (e) {
        console.error(e)
      }
    },
    [currentStep]
  )

  const currentIndex = useMemo(() => currentStep - 1, [currentStep])

  const showLastStep = useMemo(
    () => (intake.status === 'submitted' ? !!isGroupHead : true),
    [intake, isGroupHead]
  )

  // Form submit fail
  useEffect(() => {
    if (submitError) {
      if (submitError?.errors?.forEach) {
        let toasterErrors = []
        submitError.errors.forEach((error, i) => {
          const err = Object.keys(error)[0]
          const msg = Object.values(error)[0]
          toasterErrors.push(msg)
          if (err === 'subsidiaries' || err === 'parent') setError(err, msg)
        })
        toaster.error(toasterErrors)
      } else {
        toaster.error('Er is een fout opgetreden bij het opslaan van de intake')
      }
    }
  }, [submitError])

  return (
    <Container className="intake">
      <h1 style={{ marginBottom: '16px' }}>Intake {intake.year}</h1>
      <Alert
        className={`intake__alert ${currentStep !== 1 ? 'hidden' : ''}`}
        severity="info"
        content="De intake wordt gebruikt om de rapportage voor uw bedrijf in te richten. Na de intake kunt u de rapportage starten."
      />
      <form onSubmit={handleSubmit(body => onSubmit(body))}>
        <Panel style={{ margin: '24px 0 0 0' }}>
          <div className="intake__content">
            <div className="intake__pagination">
              Pagina {currentStep}/{showLastStep ? STEP_COUNT : STEP_COUNT - 1}
            </div>
            {currentStep &&
              React.createElement(steps[currentIndex], {
                ...{
                  intake,
                  formValues,
                  disabled,
                  control,
                  watch,
                  setValue,
                },
              })}
          </div>
        </Panel>
        <div className="intake__actions">
          {currentStep !== 1 && (
            <Button
              type="button"
              color="primary"
              variant="contained"
              disabled={loading}
              startIcon={<ArrowBack />}
              onClick={() => setCurrentStep(step => step - 1)}>
              Vorige
            </Button>
          )}
          {(currentStep !== 2 || showLastStep) && (
            <div className="intake__submit">
              {currentStep === 3 ? (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    loading={loading}
                    disabled={disabled}>
                    Opslaan
                  </Button>{' '}
                  <Button
                    color="primary"
                    variant="contained"
                    loading={loading}
                    disabled={disabled}
                    endIcon={<ArrowForward />}
                    style={{ marginLeft: 'auto' }}
                    onClick={handleSubmit(body => onSubmit(body, true))}>
                    Intake afronden
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  loading={loading}
                  endIcon={<ArrowForward />}
                  style={{ marginLeft: 'auto' }}>
                  Volgende
                </Button>
              )}
            </div>
          )}
        </div>
      </form>
      {isGroup && isGroupHead && (
        <div className="intake__alert-wrapper">
          <Alert
            className={`intake__alert--bottom ${
              currentStep !== 3 || disabled ? 'hidden' : ''
            }`}
            severity="info"
            content={intakeForm.a.sections.alert.finalize}
          />
        </div>
      )}
    </Container>
  )
}

export default IntakeFormVersionA
