/* eslint-disable max-len */
import fallback from '@constants/forms/fallback'
import regex from '@validation/regex'

export const DECLARATION_VERSION_B_DETAILS = {
  sections: {
    details: {
      heading: 'Bedrijfsgegevens {{company}}',
    },
    large_company: {
      heading: 'Grote vennootschap',
      accordion: {
        heading: 'Lees meer over de groottecriteria',
        content: (
          <>
            <p>
              De Wet 'ingroeiquotum en streefcijfers' is van toepassing op
              naamloze en besloten vennootschappen die volgens het
              jaarrekeningrecht een 'grote rechtspersoon' zijn Dit is het geval
              als een vennootschap op twee opeenvolgende balansdata voldoet aan
              ten minste twee van de volgende drie criteria:
            </p>
            <ul>
              <li>de waarde van de activa bedraagt meer dan € 25 miljoen;</li>
              <li>de netto-omzet is meer dan € 50 miljoen;</li>
              <li>het gemiddeld aantal werknemers is 250 of meer.</li>
            </ul>
            <p>
              Deze zijn in 2024 gewijzigd en zijn van toepassing op boekjaren
              die zijn aangevangen op of na 1 januari 2024, en kunnen worden
              toegepast op de boekjaren die zijn aangevangen op of na 1 januari
              2023.
            </p>
            <p>
              Wanneer uw organisatie niet kwalificeert als 'grote vennootschap'
              is het niet wettelijk verplicht deze rapportage in te vullen. U
              kunt wel vrijwillig rapporteren.
            </p>
            <p>
              Meer informatie is te vinden in artikelen 2:166 lid 1 BW, 2:276
              lid 1 BW en 2:397 lid 1 BW.
            </p>
          </>
        ),
      },
    },
    stock_exchange_listing: {
      heading: 'Beursgenoteerd',
      accordion: {
        heading: 'Lees meer over beursnotering',
        content: (
          <>
            <p>
              Een bedrijf is een beursgenoteerde vennootschap wanneer de
              aandelen of certificaten van aandelen zijn toegelaten tot de
              handel op een gereglementeerde markt als bedoeld in artikel 1:1
              van de Wet op het financieel toezicht in Nederland.
            </p>
            <p>
              Wanneer uw bedrijf niet in Nederland (Euronext Amsterdam) maar in
              een ander land aan de beurs genoteerd is, kunt u hier 'nee'
              invullen.
            </p>
          </>
        ),
      },
    },
    tier: {
      heading: 'Bestuursmodel',
      content: 'Welk bestuursmodel had {{company}} in het boekjaar {{year}}?',
    },
  },
  questions: {
    code_1: {
      name: 'code_1',
      label: 'KvK-nummer',
      required: {
        value: true,
        message: fallback.required,
      },
      regex: {
        value: regex.kvk_number,
        message: 'Uw KVK nummers bestaat uit 8 cijfers',
      },
      disabled: true,
    },
    code_2: {
      name: 'code_2',
      label: 'Statutaire naam',
      required: {
        value: true,
        message: fallback.required,
      },
      disabled: true,
    },
    code_3: {
      type: 'boolean',
      name: 'code_3',
      label: 'Is {{company}} een vennootschap (bv of nv)?',
    },
    code_3_type: {
      type: 'autocomplete',
      name: 'code_3_type',
      label: 'Rechtsvorm',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      options: [
        {
          id: 1,
          value: 'stichting',
          label: 'Stichting',
        },
        {
          id: 2,
          value: 'cooperatie',
          label: 'Coöperatie',
        },
        {
          id: 3,
          value: 'vereniging',
          label: 'Vereniging',
        },
        {
          id: 4,
          value: 'zbo',
          label: 'ZBO',
        },
        {
          id: 5,
          value: 'gemeenschappelijke-regeling',
          label: 'Gemeenschappelijke Regeling',
        },
        {
          id: 6,
          value: 'publiekrechtelijke-orgaan',
          label: 'Publiekrechtelijke orgaan',
        },
        {
          id: 7,
          value: 'anders',
          label: 'Anders',
        },
      ],
    },
    code_4: {
      type: 'boolean',
      name: 'code_4',
      label: `Kwalificeerde {{company}} in het boekjaar {{year}} als 'groot?'`,
    },
    code_5: {
      type: 'boolean',
      name: 'code_5',
      label: 'Had {{company}} in {{year}} een beursnotering in Nederland?',
    },
    code_6: {
      name: 'code_6',
      label: 'Welk bestuursmodel had {{company}} op {{date}}?',
      options: [
        {
          id: 1,
          value: 'one-tier',
          title: 'One-tier board',
          content:
            'Één bestuur, waarin zowel uitvoerende als niet-uitvoerende bestuurders zitten (bestuur en rvc in één)',
          selected: false,
        },
        {
          id: 2,
          value: 'two-tier',
          title: 'Two-tier board met bestuur en RvC',
          content:
            'Een apart bestuur dat de dagelijkse leiding heeft en een aparte raad van commissarissen die toezicht houdt op het bestuur',
          selected: false,
        },
        {
          id: 3,
          value: 'no-supervisory',
          title: 'Two-tier board, geen RvC ingesteld',
          content:
            'Uitsluitend een bestuur/directie, geen toezichthouders en geen toezichthoudend orgaan',
          selected: false,
        },
      ],
    },
  },
}
