/* eslint-disable max-len */
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'

// Components
import { Grid, Container } from '@mui/material'
import { TextInput } from '@components'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

// Validation
import regex from '@validation/regex'

// Hooks
import { Panel } from '@components'
import { Button } from '@components'
import { profileHooks } from '@services'

const { useChangePassword } = profileHooks

export const ChangePasswordForm = ({ onSuccess, onError }) => {
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
  })

  const [sendChangePassword, loading, res, err] = useChangePassword()

  const changePasswordHandler = useCallback(
    ({ current_password, password, password_confirmation }) => {
      sendChangePassword({
        current_password,
        password,
        password_confirmation,
      })
    },
    [sendChangePassword]
  )

  useEffect(() => {
    err && onError(err)
    if (res) {
      reset()
      onSuccess(res)
    }
  }, [res, err, onSuccess, onError, reset])

  return (
    <form onSubmit={handleSubmit(changePasswordHandler)}>
      <Container style={{ maxWidth: '1152px' }}>
        <Panel style={{ flexDirection: 'column' }}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingTop: 0 }}>
            <h2 style={{ marginTop: 0 }}>Wachtwoord wijzigen</h2>
            <TextInput
              horizontal
              type={'password'}
              label={'Huidig wachtwoord'}
              {...register('current_password', {
                required: {
                  value: true,
                  message: 'Huidig wachtwoord is een verplicht veld.',
                },
              })}
              {...(errors.current_password?.message && {
                alert: {
                  message: errors.current_password?.message,
                },
              })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingTop: 0 }}>
            <TextInput
              horizontal
              type="password"
              label={'Nieuw wachtwoord'}
              {...register('password', {
                required: {
                  value: true,
                  message: 'Wachtwoord is een verplicht veld.',
                },
                pattern: {
                  value: regex.password,
                  message:
                    'Je wachtwoord moet bestaan uit tenminste 8 tekens, een hoofdletter, een kleine letter en een cijfer',
                },
              })}
              {...(errors.password?.message && {
                alert: {
                  message: errors.password?.message,
                },
              })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingTop: 0 }}>
            <TextInput
              horizontal
              type="password"
              label={'Herhaal nieuw wachtwoord'}
              {...register('password_confirmation', {
                required: {
                  value: true,
                  message: 'Wachtwoord herhalen is verplicht.',
                },
                pattern: {
                  value: regex.password,
                  message:
                    'Je wachtwoord moet bestaan uit tenminste 8 tekens, een hoofdletter, een kleine letter en een cijfer',
                },
                validate: val =>
                  val === watch('password') ||
                  'Wachtwoorden komen niet overeen.',
              })}
              {...(errors.password_confirmation?.message && {
                alert: {
                  message: errors.password_confirmation?.message,
                },
              })}
            />
          </Grid>
          <div style={styles.buttonWrapper}>
            <Button
              type="submit"
              variant="contained"
              loading={loading}
              endIcon={<ArrowForwardIcon />}>
              Opslaan
            </Button>
          </div>
        </Panel>
      </Container>
    </form>
  )
}

const styles = {
  buttonWrapper: {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}
