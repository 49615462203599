/* eslint-disable max-len */
import fallback from '@constants/forms/fallback'

export const DECLARATION_VERSION_B_PROFILE = {
  sections: {
    details: {
      heading: 'Bedrijfsprofiel',
    },
    visiblity: {
      heading: 'Zichtbaarheid in de Dataverkenner',
    },
    contact: {
      heading: 'Contactgegevens',
      visiblity: 'Dit is niet zichtbaar voor in de publieke dataverkenner.',
      intro:
        'De SER gebruikt deze adresgegevens uitsluitend voor het versturen van activatiecodes en uitnodigingen voor de jaarlijkse rapportage.',
    },
    diversity: {
      heading: 'Genderdiversiteit',
      intro:
        'Bedrijven kunnen rekening houden met personen die zich niet thuis voelen binnen het klassieke man-vrouwhokje. Gelet op de privacy, kan het onwenselijk zijn als dit soort gegevens te herleiden zijn tot bepaalde personen. Daarom kunnen personen uit deze categorie meegeteld worden bij het ondervertegenwoordigde geslacht.',
      visiblity: 'private',
      accordion: {
        heading: 'Lees meer over genderdiversiteit',
        content: (
          <>
            <p>
              Wanneer u rapporteert over bestuurslagen met daarin personen die
              zich niet thuis voelen binnen de klassieke man-vrouwhokjes, kunt u
              dit melden in de rapportage. U mag deze personen in de rapportage
              meetellen bij het ondervertegenwoordigde geslacht.
            </p>
            <p>
              Een voorbeeld: Een grote vennootschap heeft een streefcijfer van
              33% m/v voor een RvC met drie zetels, waarvan twee zetels al door
              mannen worden bezet. Wanneer een nieuwe commissaris wordt benoemd
              die zich identificeert als non-binair, dan kan deze worden
              meegeteld bij het ondervertegenwoordigde geslacht. De benoeming
              draagt bij aan het evenwichtiger maken van de man- vrouwverhouding
              in de RvC en de vennootschap voldoet aan het streefcijfer van 33%.
            </p>
          </>
        ),
      },
    },
    employee_balance: {
      heading: 'Aantal werknemers',
      subheading:
        'Hoeveel werknemers waren in uw bedrijf in dienst op {{taxYear}}?',
      accordion: {
        heading: 'Lees meer over het aantal werknemers in uw bedrijf',
        content: (
          <>
            <p>Het gaat hier om het aantal werkzame personen (head count). </p>
            <p>
              Wanneer u voor meerdere groepsmaatschappijen gezamenlijk
              rapporteert, geldt het aantal werknemers van de groep. Hoe de
              berekening van het aantal werknemers gebeurt, kunt u zelf bepalen.
              Wel is het uitgangspunt dat de gekozen methode een goede weergave
              moet zijn van de onderneming.{' '}
            </p>
            <p>
              Aanbevolen wordt uit te gaan van het geconsolideerde geheel in
              Nederland.
            </p>
            <p>
              Het aantal werknemers en het percentage vrouwen en mannen worden
              in de dataverkenner getoond. De SER gebruikt het werknemersaantal
              ook om uw bedrijf in te delen in grootteklassen in de
              dataverkenner, zodat bedrijven met een vergelijkbare
              werknemersomvang met elkaar kunnen worden vergeleken.
            </p>
          </>
        ),
      },
    },
  },
  questions: {
    code_1: {
      label: 'Bedrijfsnaam / Groepsnaam',
      required: {
        value: true,
        message: fallback.required,
      },
    },
    code_2: {
      label: 'Bedrijfstak (hoofdactiviteit van de onderneming)',
      required: {
        value: true,
        message: fallback.required,
      },
      options: [
        {
          id: 1,
          value: 'A',
          label: 'A - Landbouw, bosbouw en visserij',
        },
        {
          id: 2,
          value: 'B',
          label: 'B - Delfstoffenwinning',
        },
        {
          id: 3,
          value: 'C',
          label: 'C - Industrie',
        },
        {
          id: 4,
          value: 'D',
          label: 'D - Energievoorziening',
        },
        {
          id: 5,
          value: 'E',
          label: 'E - Waterbedrijven en afvalbeheer',
        },
        {
          id: 6,
          value: 'F',
          label: 'F - Bouwnijverheid',
        },
        {
          id: 7,
          value: 'G',
          label: 'G - Handel',
        },
        {
          id: 8,
          value: 'H',
          label: 'H - Vervoer en opslag',
        },
        {
          id: 9,
          value: 'I',
          label: 'I - Horeca',
        },
        {
          id: 10,
          value: 'J',
          label: 'J - Informatie en communicatie',
        },
        {
          id: 11,
          value: 'K',
          label: 'K - Financiële dienstverlening',
        },
        {
          id: 12,
          value: 'L',
          label: 'L - Verhuur en handel van onroerend goed',
        },
        {
          id: 13,
          value: 'M',
          label: 'M - Specialistische zakelijke diensten',
        },
        {
          id: 14,
          value: 'N',
          label: 'N - Verhuur en overige zakelijke diensten',
        },
        {
          id: 15,
          value: 'O',
          label: 'O - Openbaar bestuur en overheidsdiensten',
        },
        {
          id: 16,
          value: 'P',
          label: 'P - Onderwijs',
        },
        {
          id: 17,
          value: 'Q',
          label: 'Q - Gezondheids- en welzijnszorg',
        },
        {
          id: 18,
          value: 'R',
          label: 'R - Cultuur, sport en recreatie',
        },
        {
          id: 19,
          value: 'S',
          label: 'S - Overige dienstverlening',
        },
        {
          id: 20,
          value: 'T',
          label: 'T - Huishoudens',
        },
        {
          id: 21,
          value: 'U',
          label: 'U - Extraterritoriale organisaties',
        },
      ],
    },
    code_3: {
      label:
        'Worden in deze rapportage personen die zich niet thuis voelen binnen het klassieke man-vrouwhokje meegeteld bij het ondervertegenwoordigde geslacht?',
      required: {
        value: true,
        message: fallback.required,
      },
    },
    code_4: {
      male: {
        label: 'Aantal mannen',
        required: {
          value: true,
          message: fallback.required,
        },
        inputProps: {
          min: 0,
        },
      },
      female: {
        label: 'Aantal vrouwen',
        required: {
          value: true,
          message: fallback.required,
        },
        inputProps: {
          min: 0,
        },
      },
      total: {
        label: 'Totaal aantal werknemers',
      },
    },
  },
}
