import { useMemo } from 'react'

const StartDeclarationModal = ({
  year,
  company,
  consolidated,
  subsidiaries,
}) => {
  const companiesInDeclaration = useMemo(() => {
    if (!consolidated) return []
    if (!subsidiaries?.length) return []
    return subsidiaries.filter(
      declaration =>
        declaration.request.type ===
          'you-are-my-subsidiary-and-i-will-take-care-of-your-declaration' ||
        declaration.request.type ===
          'you-are-my-group-head-and-you-will-take-care-of-my-declaration'
    )
  }, [consolidated, subsidiaries])

  return (
    <div>
      <span>
        U vult nu de rapportage in over het boekjaar {year} voor de volgende
        bedrijven:
      </span>
      <ul>
        <li>
          {company.name} - KvK: {company.kvk_number}
        </li>
        {companiesInDeclaration.map((subsidiary, i) => {
          return (
            <li key={i}>
              {subsidiary.company.name} - KVK: {subsidiary.company.kvk_number}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default StartDeclarationModal
