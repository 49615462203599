/* eslint-disable max-len */
const submitSuccess = {
  sections: {
    confirmation: {
      heading: 'Bevestiging',
      alert: {
        severity: 'success',
        content: 'Bedankt, het indienen is gelukt',
      },
      intro: (
        <p>
          Mocht u vragen of opmerkingen hebben over uw rapportage dan kunt u
          contact opnemen met onze helpdesk
        </p>
      ),
    },
    questions: {
      heading: 'Meepraten of mee doen?',
      intro: 'Ik heb interesse in:',
    },
    readMore: {
      heading: 'Verder lezen',
      intro:
        'Op de website van de SER vindt u meer informatie over het rapportageproces en -tijdlijn en een platform dat hulpbronnen en informatie voor succesvol diversiteitsbeleid bevat.',
    },
    buttons: [
      {
        label: 'Tools en handreikingen',
        url: 'https://www.ser.nl/nl/thema/diversiteitsportaal/tools',
      },
      {
        label: 'Publicaties',
        url: 'https://www.ser.nl/nl/thema/diversiteitsportaal/publicaties',
      },
      {
        label: 'Nieuwsbrief',
        url: 'https://www.ser.nl/nl/actueel/nieuwsbrief-aanmelden',
      },
    ],
  },
  questions: {
    share_dialogs: {
      label:
        'Dialoogsessies met andere bedrijven om ervaringen uit te wisselen, gefaciliteerd door de SER',
      required: {
        value: false,
      },
    },
    share_profile: {
      label: `Onszelf profileren met een showcase op de publieke pagina's van het SER bedrijvenportaal`,
      required: {
        value: false,
      },
    },
    receive_info: {
      label:
        'Evenementen van en informatie over SER Topvrouwen en de database met board-ready vrouwelijke kandidaten',
      required: {
        value: false,
      },
    },
    receive_tips: {
      label:
        'Tips en best practices delen met de SER om in het diversiteitsportaal te verwerken',
      required: {
        value: false,
      },
    },
  },
}

export default submitSuccess
