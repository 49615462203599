import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { PasswordForgetForm } from '@partials/forms/authentication/password.forget.form'
import { toaster } from '@store/toaster.state'

export const PasswordForgetView = () => {
  const navigate = useNavigate()

  const successHandler = useCallback(
    () => navigate('/password-forget/success'),
    [navigate]
  )

  const errorHandler = useCallback(({ errors }) => {
    if (!errors?.forEach) return toaster.error('Er is een fout opgetreden')

    toaster.error(errors.map(err => Object.values(err)[0]))
  }, [])

  return (
    <PasswordForgetForm
      onSuccess={successHandler}
      onError={errorHandler}
    />
  )
}
