import { useCallback } from 'react'

// State
import { modal } from '@store/modal.state'

// Components
import { Button } from '@components'
import AddSubsidiaryModal from '@partials/modals/AddSubsidiaryModal'
import { toaster } from '@store/toaster.state'

// Icons
import AddIcon from '@mui/icons-material/Add'

// Styles
import { IntakeCompany } from './intakeCompany'
import './styles.scss'

const IntakeSubsidiaries = ({
  year,
  intake_version = 'A',
  button_label,
  subsidiaries,
  setSubsidiaries,
  current_kvk,
  disabled,
}) => {
  const pushToSubsidiaries = company => {
    const alreadyInArray = subsidiaries.find(
      item => item.kvk_number == company.kvk_number
    )
    if (alreadyInArray) {
      return toaster.error(`${company.name} bestaat al in uw groep.`)
    }

    setSubsidiaries([...subsidiaries, company])
    modal.setClose()
  }

  const removeFromSubsidiaries = useCallback(
    companyIndex => {
      const updatedSubsidiaries = [...subsidiaries]
      updatedSubsidiaries.splice(companyIndex, 1)

      setSubsidiaries(updatedSubsidiaries)
    },
    [subsidiaries]
  )

  const openSubsidiaryModal = () => {
    const modalBody = {
      heading: 'Een bedrijf aan uw groep toevoegen',
      content: (
        <AddSubsidiaryModal
          push={pushToSubsidiaries}
          current_kvk={current_kvk}
        />
      ),
      buttons: [],
      props: {
        maxWidth: 'unset',
      },
    }
    modal.setOpen(modalBody)
  }

  return (
    <div className="company-list">
      {year && (
        <p>
          Welke grote vennootschappen behoorden in het boekjaar {year} tot deze
          groep?
        </p>
      )}
      <Button
        type="button"
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={openSubsidiaryModal}
        disabled={disabled}>
        {button_label}
      </Button>
      {subsidiaries.length > 0 && (
        <>
          {subsidiaries.map((subsidiary, index) => {
            return (
              <IntakeCompany
                key={subsidiary.id}
                version={intake_version}
                company={subsidiary}
                onRemove={() => removeFromSubsidiaries(index)}
                disabled={disabled || subsidiary.locked}
              />
            )
          })}
        </>
      )}
    </div>
  )
}

export default IntakeSubsidiaries
