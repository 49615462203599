import React from 'react'

import styles from './styles'

// Components
import { Button } from '../../core/index.core.components'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

// Content
import { CONTENT } from '@constants'

const AdditionalInformation: React.FC = () => {
  return (
    <div>
      <h2>{CONTENT.dashboard.additionalInformation.title}</h2>
      <div style={styles.cards}>
        {CONTENT.dashboard.additionalInformation.cards.map(card => {
          return (
            <div
              style={styles.card as React.CSSProperties}
              key={card.id}>
              <div>
                <h2 style={styles.card__title}>{card.title}</h2>
                <p style={styles.card__content as React.CSSProperties}>
                  {card.content}
                </p>
              </div>
              {card.button?.text && (
                <div style={styles.card__button}>
                  {card.button.externalLink ? (
                    <a
                      style={{ width: 'max-content' }}
                      href={card.button.path}
                      target="_blank"
                      rel="noreferrer">
                      <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<ArrowForwardIcon />}>
                        {card.button.text}
                      </Button>
                    </a>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      endIcon={<ArrowForwardIcon />}
                      to={card.button.path}>
                      {card.button.text}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AdditionalInformation
