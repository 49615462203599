/* eslint-disable max-len */

import { useEffect, useMemo } from 'react'
import { dashboardHooks } from '@services'
import { getState } from '@store/ui.store'
import { useOutletContext, useParams } from 'react-router-dom'
import { dashboard } from '@store/dashboard.state'

// Components
import { Container } from '@mui/material'
import { DashboardAccordion } from '@components'
import { Alert } from '@components'
import { Loader } from '@components'
import { Breadcrumb } from '@components'

// Styles
import styles from './styles'
import { AdditionalInformation } from '@components'

export const CompanyDashboardView = () => {
  // State
  const auth = getState('auth')

  // Hooks
  const { useLoadDashboardCompany } = dashboardHooks
  const [fetchDashboard, loadingDashboard, response, error] =
    useLoadDashboardCompany()
  const { setSectionColor, setRenderClose } = useOutletContext()

  // Reactive
  const { company_id = '' } = useParams()

  const current_company = useMemo(() => {
    if (!auth?.data?.companies) return null
    return auth.data.companies.find(({ id }) => id == parseInt(company_id))
  }, [auth?.data?.companies, company_id])

  useEffect(() => {
    dashboard.setDashboardLoading(true)
    fetchDashboard(company_id)
  }, [])

  useEffect(() => {
    setSectionColor('primary')
    setRenderClose(false)
  }, [setSectionColor, setRenderClose])

  return (
    <div style={styles.page}>
      <Container style={styles.breadcrumbContainer}>
        <Breadcrumb
          items={[
            { name: 'Home', path: '/dashboard' },
            { name: current_company?.name },
          ]}
        />
      </Container>
      <Container>
        {error?.errors && (
          <div>
            <Alert
              severity="warning"
              title="Er is iets mis gegaan"
              content={
                error?.errors ||
                'We kunnen op dit moment geen data voor dit bedrijf ophalen. Probeer het later nog een keer.'
              }
            />
          </div>
        )}
        {error?.errors ? (
          ''
        ) : !response || loadingDashboard ? (
          <Loader
            text="Dashboard aan het laden..."
            style={{ minHeight: '60vh' }}
          />
        ) : (
          <>
            <div>
              <h1>Welkom {auth.data.name}</h1>
              <h2>
                Bekijk hieronder je rapportages voor {current_company?.name}
              </h2>
              <div>
                {response?.data &&
                  response?.data.map((item, index) => {
                    return (
                      <DashboardAccordion
                        key={index}
                        data={item}
                      />
                    )
                  })}
              </div>
            </div>
            <div style={{ marginTop: '84px' }}>
              <AdditionalInformation />
            </div>
          </>
        )}
      </Container>
    </div>
  )
}

export default CompanyDashboardView
