import { createContext, useContext, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  BooleanQuestion,
  NumberInput,
  RadioButtonGroup,
  Row,
  TextArea,
  TextInput,
} from '../index.core.components'
import { Checkbox, FormControlLabel } from '@mui/material'

const HIDE_LABEL = ['boolean', 'number-input', 'checkbox']

export const FormInputContext = createContext({
  disabled: false,
})

const FormInput = ({
  rootElement = 'row',
  rootProps = {},
  type,
  name,
  label,
  labelSize = 'p',
  options = {},
  ...props
}) => {
  const context = useContext(FormInputContext)
  const { control } = useFormContext()

  const rules = useMemo(() => {
    if (!options) return {}

    return {
      required: options.required,
      pattern: options.regex,
    }
  }, [options])

  const dynamicProps = useMemo(() => {
    return {
      label: label || options?.label,
      labelSize: labelSize || options?.labelSize,
      name: name || options?.name,
      type: type || options?.type,
    }
  }, [label, name, type, options])

  const RootElement = useMemo(() => {
    switch (true) {
      case dynamicProps.type === 'boolean':
        return 'div'
      case rootElement === 'row':
        return Row
      default:
        return rootElement
    }
  }, [dynamicProps.type, rootElement])

  const renderLabel = useMemo(() => {
    if (!HIDE_LABEL.includes(dynamicProps.type) && dynamicProps.label)
      if (labelSize === 'h3') {
        return <h3>{dynamicProps.label}</h3>
      } else {
        return <p>{dynamicProps.label}</p>
      }
  }, [dynamicProps.type, dynamicProps.label, dynamicProps.labelSize])

  if (!dynamicProps.name) throw Error('Input name is required')

  const renderInput = ({
    field: { name, value, ref, onChange },
    fieldState: { error },
  }) => {
    const { disabled, required } = options

    const defaultProps = {
      name,
      value,
      onChange,
      required: required?.value,
      disabled: disabled || props?.disabled,
      inputRef: ref,
    }

    if (typeof defaultProps.disabled !== 'boolean')
      defaultProps.disabled = context.disabled

    switch (dynamicProps.type) {
      case 'boolean':
        return (
          <BooleanQuestion
            {...props}
            {...defaultProps}
            question={dynamicProps.label}
            alert={{ message: error?.message }}
          />
        )
      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                {...props}
                {...defaultProps}
                value={void 0}
                label={dynamicProps.label}
                checked={value}
              />
            }
            label={dynamicProps.label}
          />
        )

      case 'textarea':
        return (
          <TextArea
            {...props}
            {...defaultProps}
            type={dynamicProps.type}
            alert={{ message: error?.message }}
            value={value}
          />
        )

      case 'number-input':
        return (
          <NumberInput
            {...props}
            {...defaultProps}
            label={dynamicProps.label}
            alert={{ message: error?.message }}
            min={options?.inputProps?.min}
            max={options?.inputProps?.max}
            toFixed={options?.inputProps?.toFixed}
            InputProps={{
              ...props.InputProps,
              inputProps: options?.inputProps,
              step: options?.inputProps?.step,
            }}
          />
        )

      case 'radio-button-group':
        return (
          <RadioButtonGroup
            {...props}
            {...defaultProps}
            items={options?.items}
            alert={{ message: error?.message }}
          />
        )

      default:
        return (
          <TextInput
            {...props}
            {...defaultProps}
            type={dynamicProps.type}
            alert={{ message: error?.message }}
          />
        )
    }
  }

  return (
    <RootElement {...rootProps}>
      {renderLabel}

      <Controller
        name={dynamicProps.name}
        control={control}
        rules={rules}
        render={renderInput}
      />
    </RootElement>
  )
}

export default FormInput
