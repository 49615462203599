import { useState, useEffect } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form'

// Store
import { declarationHooks } from '@services'

// MUI
import { Container } from '@mui/material'

// Constants
import { CONTENT } from '@constants'
const { intro, showcase, publications, trending, questions } =
  CONTENT.declaration.success

// Components
import { DownloadReport, Panel } from '@components'
import { Button } from '@components'
import { toaster } from '@store/toaster.state'
import { Checkbox } from '@components'
import { Alert } from '@components'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import fileGreen from '@assets/graphics/forms/file-green.svg'

const DeclarationPreferences = ({ update, submitting }) => {
  const { control, watch, handleSubmit } = useFormContext()

  useEffect(() => {
    const watcher = watch(handleSubmit(update))
    return () => watcher.unsubscribe()
  }, [handleSubmit, watch])

  return (
    <form
      onSubmit={handleSubmit(update)}
      style={{
        margin: '32px 0',
        padding: '8px 16px',
        border: '2px solid #241D6D',
      }}>
      <Controller
        name="receive_info"
        control={control}
        render={({ field: { value, ref, onChange } }) => {
          const { label } = questions.receive_info
          return (
            <Checkbox
              label={<strong>{label}</strong>}
              checked={value || false}
              onChange={onChange}
              inputRef={ref}
              disabled={submitting}
            />
          )
        }}
      />
    </form>
  )
}

const IntakeSuccessView = () => {
  // Hooks
  const { usePreferencesUpdate, useDeclaration } = declarationHooks
  const [updatePreferences, submitting, submitted, error] =
    usePreferencesUpdate()
  const [fetchDeclaration, , declaration] = useDeclaration()
  const { declaration_id } = useParams()
  const { setSectionColor } = useOutletContext()
  const methods = useForm()

  // State
  const [year, setYear] = useState(null)
  const update = body => {
    updatePreferences({ declaration_id, body })
  }

  // Reactive
  useEffect(() => {
    setSectionColor('default')
    fetchDeclaration(declaration_id)
  }, [declaration_id, setSectionColor])

  useEffect(() => {
    if (declaration?.data?.year) {
      setYear(declaration?.data?.year)
    }
  }, [declaration])

  useEffect(() => {
    if (submitted) toaster.success('Uw voorkeur is opgelagen')
  }, [submitted])
  useEffect(() => {
    if (error) toaster.error('Uw voorkeur is niet opgeslagen')
  }, [error])

  return (
    <FormProvider {...methods}>
      <Container>
        <Panel style={{ display: 'block' }}>
          <img src={fileGreen} />
          <h1>{intro.heading}</h1>
          <Alert
            severity="success"
            content={intro.alert}
          />
          <div style={{ display: 'flex', margin: '20px 0' }}>
            <DownloadReport
              declaration_id={declaration_id}
              year={year}
            />
          </div>
          {intro.content}
          {showcase.content}
          <DeclarationPreferences {...{ submitting, update }} />
          <h3>{trending.heading}</h3>
          <p>{trending.content}</p>
          <Button
            variant="outlined"
            endIcon={<ArrowForwardIcon />}
            href={trending.button.url}
            component="a"
            target="_blank">
            {trending.button.label}
          </Button>
          <h3>{publications.heading}</h3>
          {publications.content}
          <div style={{ display: 'flex', gap: '40px', marginTop: '20px' }}>
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIcon />}
              href={publications.button_1.url}
              component="a"
              target="_blank">
              {publications.button_1.label}
            </Button>
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIcon />}
              href={publications.button_2.url}
              component="a"
              target="_blank">
              {publications.button_2.label}
            </Button>
          </div>
        </Panel>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 24,
          }}>
          <Button
            variant="contained"
            to={'/'}
            endIcon={<ArrowForwardIcon />}>
            Home
          </Button>
        </div>
      </Container>
    </FormProvider>
  )
}

export default IntakeSuccessView
