import { intakeResource } from './resource'

export const intakeService = {
  getIntake: (intake_id, signal) =>
    intakeResource.get(`/intakes/${intake_id}`, {
      signal: signal(),
    }),
  createIntake: ({ intake_id, body }, signal) =>
    intakeResource.post(
      `/intakes/${intake_id}`,
      { ...body },
      {
        signal: signal(),
      }
    ),
  patchIntake: ({ intake_id, body }, signal) =>
    intakeResource.put(
      `/intakes/${intake_id}`,
      { ...body },
      {
        signal: signal(),
      }
    ),
  acceptIntakeRequest: ({ id, accept }, signal) =>
    intakeResource.put(
      `intake-requests/${id}`,
      { accept },
      {
        signal: signal(),
      }
    ),
  resetIntake: ({ intake_id }, signal) =>
    intakeResource.delete(`/intakes/${intake_id}/restart`, {
      signal: signal(),
    }),
}
