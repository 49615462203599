import { useMemo } from 'react'

// Components
import { Button, Panel } from '@components'
import { Container, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// Styles
import styles from './styles'

import { CONTENT } from '@constants'

import { getState } from '@store/ui.store'

const SuccessView = ({ page }) => {
  const navigate = useNavigate()
  const authenticated = getState('auth.token')

  const state = useMemo(
    () =>
      page === 'activation' && authenticated
        ? 'success_authenticated'
        : 'success_guest',
    [page, authenticated]
  )

  const heading = CONTENT[page][state].heading
  let content = CONTENT[page][state].content

  if (!content) {
    content = ['U kunt deze pagina sluiten.']
  }

  return (
    <main>
      <Container>
        <Grid
          container
          spacing={3}
          justifyContent="center">
          <Grid
            item
            xs={12}
            sm={6}>
            <Panel>
              <Grid
                item
                xs={12}>
                <h2>{heading}</h2>
                {content.map((paragraph, i) => {
                  return <p key={i}>{paragraph}</p>
                })}
              </Grid>
            </Panel>
            <div style={styles.buttonWrapper}>
              <Button
                variant="contained"
                onClick={() => navigate('/login')}>
                {CONTENT.activation[state].button}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </main>
  )
}

export default SuccessView
