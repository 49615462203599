// Components
import { RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { Alert } from '@components'
import { useFormContext } from 'react-hook-form'

// styles
import styles from './styles'
import { COLORS } from '@constants'

// Icons
import CircleIcon from '@mui/icons-material/Circle'
import { useEffect } from 'react'
interface Props {
  vertical?: boolean
  id?: string | undefined
  question: string
  value: any
  defaultValue: any
  name: string
  disabled?: boolean
  required?: boolean
  alert?: {
    severity?: 'error' | 'warning' | 'info' | 'success'
    message?: string
  }
  onChange: (value: boolean, id: string | undefined) => void
  labels?: {
    true?: string
    false?: string
  }
  inputRef?: any
}

const BooleanQuestion: React.FC<Props> = ({
  id,
  question,
  defaultValue,
  name,
  disabled,
  required,
  alert,
  value,
  onChange,
  labels = { true: 'Ja', false: 'Nee' },
  inputRef,
  vertical,
}) => {
  const ctx: any = useFormContext()

  if (!labels?.true) labels.true = 'Ja'
  if (!labels?.false) labels.false = 'Nee'

  let outline = COLORS.gray.main
  let textColor = COLORS.background.contrastText

  if (!ctx?.isPDFExport && disabled) {
    outline = COLORS.gray.disabled
    textColor = COLORS.gray.disabled
  }
  if (alert?.severity) {
    switch (alert.severity) {
      case 'error':
        outline = COLORS.error.contrastText
        textColor = COLORS.error.contrastText
        break
      case 'warning':
        outline = COLORS.warning.contrastText
        textColor = COLORS.warning.contrastText
        break
      case 'info':
        outline = COLORS.info.contrastText
        textColor = COLORS.info.contrastText
        break
      case 'success':
        outline = COLORS.success.contrastText
        textColor = COLORS.success.contrastText
        break

      default:
        outline = COLORS.error.contrastText
        textColor = COLORS.error.contrastText
        break
    }
  }

  return (
    <>
      <div
        style={vertical ? styles.wrapperVertical : styles.wrapper}
        ref={inputRef}>
        <span style={{ color: textColor, paddingRight: 24 }}>{question}</span>
        {ctx?.isPDFExport ? (
          <div className="pdf-export__input">{value ? 'Ja' : 'Nee'}</div>
        ) : (
          <RadioGroup
            id={id}
            row
            value={value}
            defaultValue={defaultValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(JSON.parse(e.target.value), id)
            }
            name={name}
            aria-labelledby={name}
            sx={styles.radioGroup}>
            <FormControlLabel
              sx={{
                ...styles.radioBox,
                borderColor: outline,
                marginLeft: vertical ? 0 : null,
              }}
              value={true}
              label={labels.true}
              labelPlacement="end"
              disabled={disabled}
              control={
                <Radio
                  required={required}
                  sx={styles.radio}
                  checkedIcon={<CircleIcon fontSize="small" />}
                />
              }
            />
            <FormControlLabel
              sx={{ ...styles.radioBox, borderColor: outline, marginRight: 0 }}
              value={false}
              label={labels.false}
              labelPlacement="end"
              disabled={disabled}
              control={
                <Radio
                  required={required}
                  sx={styles.lastRadio}
                  checkedIcon={<CircleIcon fontSize="small" />}
                />
              }
            />
          </RadioGroup>
        )}
      </div>
      {alert?.message && (
        <Alert
          severity={alert.severity || 'error'}
          content={alert.message}
        />
      )}
    </>
  )
}

export default BooleanQuestion
