/* eslint-disable max-len */
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import regex from '@validation/regex'

// Components
import { Panel } from '@components'
import { Container, Grid } from '@mui/material'
import { TextInput } from '@components'
import { Button } from '@components'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { authHooks } from '@services'
import { Link } from 'react-router-dom'

const { useCreateAccount, useInvitations } = authHooks

export const CreateAccountForm = ({ token, onSuccess, onError }) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      password_confirmation: '',
    },
  })

  const [sendUserData, loading, res, err] = useCreateAccount()
  const [getInvitations, loadingInvitations, invitations, invitationError] =
    useInvitations()
  const [enabled, setEnabled] = useState(true)

  const createAccountHandler = useCallback(
    ({ firstName, lastName, email, password, password_confirmation }) => {
      sendUserData({
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        password_confirmation,
        token,
      })
    },
    [sendUserData, token]
  )

  useEffect(() => {
    err && onError(err)
    invitationError?.errors && onError(invitationError)
    res && onSuccess(res)
  }, [res, err, onSuccess, onError, invitationError])

  useEffect(() => {
    getInvitations(token)
  }, [])

  useEffect(() => {
    setEnabled(
      Boolean(invitationError?.errors?.[0]?.email || !invitationError?.errors)
    )
  }, [invitationError])

  useEffect(() => {
    if (!invitations?.data) return

    const { first_name, last_name, email } = invitations.data
    reset({ firstName: first_name, lastName: last_name, email })
  }, [invitations])

  return (
    <form onSubmit={handleSubmit(createAccountHandler)}>
      <Container maxWidth={'sm'}>
        <Panel style={styles.panel}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingTop: 0 }}>
            <h2 style={{ marginTop: 0 }}>Gebruikersaccount aanmaken</h2>
            <p>
              Hieronder kunt u uw gebruikersaccount aanmaken voor het SER
              Diversiteitsportaal. Daar kunt u de rapportage invullen en
              indienen, om zo te voldoen aan de wet ingroeiquotum en
              streefcijfers.
            </p>
            <p style={{ marginBottom: 40 }}>
              Heeft u al een account?{' '}
              <strong>
                <Link to="/login">Log in</Link>
              </strong>
            </p>
            {invitationError?.errors?.[0]?.email && (
              <p>
                <strong>
                  Let op, u heeft mogelijk al een account met het opgegeven
                  e-mailadres.
                </strong>
                <br />
                Geef een nieuw e-mailadres op of{' '}
                <strong>
                  <Link to="/login">log in</Link>
                </strong>{' '}
                met het bestaande account.
                <br />
                <br />
              </p>
            )}
            {enabled === true && (
              <>
                <TextInput
                  required
                  label="Voornaam"
                  {...register('firstName', {
                    required: {
                      value: true,
                      message: 'Dit veld is verplicht.',
                    },
                  })}
                  {...(errors.firstName?.message && {
                    alert: {
                      message: errors.firstName?.message,
                    },
                  })}
                />
                <TextInput
                  required
                  label="Achternaam"
                  {...register('lastName', {
                    required: {
                      value: true,
                      message: 'Dit veld is verplicht.',
                    },
                  })}
                  {...(errors.lastName?.message && {
                    alert: {
                      message: errors.lastName?.message,
                    },
                  })}
                />
                <TextInput
                  required
                  id="email"
                  label="E-mail"
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'Dit veld is verplicht.',
                    },
                    pattern: {
                      value: regex.email,
                      message: 'Dit is geen geldig email adres.',
                    },
                  })}
                  {...(errors.email?.message && {
                    alert: {
                      message: errors.email?.message,
                    },
                  })}
                />
                <TextInput
                  required
                  id="password"
                  label="Wachtwoord"
                  type="password"
                  {...register('password', {
                    required: {
                      value: true,
                      message: 'Dit veld is verplicht.',
                    },
                    pattern: {
                      value: regex.password,
                      message:
                        'Je wachtwoord moet bestaan uit tenminste 8 tekens, een hoofdletter, een kleine letter en een cijfer',
                    },
                  })}
                  {...(errors.password?.message && {
                    alert: {
                      message: errors.password?.message,
                    },
                  })}
                />
                <TextInput
                  required
                  id="password_check"
                  label="Herhaal wachtwoord"
                  type="password"
                  {...register('password_confirmation', {
                    required: {
                      value: true,
                      message: 'Dit veld is verplicht.',
                    },
                    pattern: {
                      value: regex.password,
                      message:
                        'Je wachtwoord moet bestaan uit tenminste 8 tekens, een hoofdletter, een kleine letter en een cijfer',
                    },
                    validate: val =>
                      val === watch('password') ||
                      'Wachtwoorden komen niet overeen.',
                  })}
                  {...(errors.password_confirmation?.message && {
                    alert: {
                      message: errors.password_confirmation?.message,
                    },
                  })}
                />
              </>
            )}
            {enabled === false && (
              <p>
                <strong>
                  Oeps, er is iets fout gegaan. We kunnen niet vinden wat je
                  zoekt. Neem contact op met{' '}
                  <a
                    href="mailto:diversiteitsportaal@ser.nl"
                    target="_blank"
                    rel="noreferrer">
                    diversiteitsportaal@ser.nl
                  </a>
                </strong>
              </p>
            )}
          </Grid>
        </Panel>
        <div style={styles.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            loading={loading || loadingInvitations}
            endIcon={<ArrowForwardIcon />}
            disabled={!enabled}>
            Maak account aan
          </Button>
        </div>
      </Container>
    </form>
  )
}

const styles = {
  panel: {
    marginBottom: 12,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}
