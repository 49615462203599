import {
  FormGroup,
  Checkbox as MUICheckbox,
  FormControlLabel,
  SxProps,
} from '@mui/material'

// styles
import { COLORS } from '@constants'

interface Props {
  label: string
  checked: boolean
  error?: boolean
  name?: string
  disabled?: boolean
  required?: boolean
  labelStyle?: SxProps
  onChange: () => void
}

const Checkbox: React.FC<Props> = ({
  label,
  labelStyle,
  disabled,
  required,
  checked,
  error,
  onChange,
}) => {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          sx={labelStyle}
          disabled={disabled}
          control={
            <MUICheckbox
              required={required}
              checked={checked}
              onChange={onChange}
              sx={{
                ...(error && {
                  border: '1px solid',
                  borderColor: COLORS.error.contrastText,
                }),
              }}
            />
          }
          label={label}
        />
      </FormGroup>
    </div>
  )
}

export default Checkbox
