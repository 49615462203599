/* eslint-disable max-len */
export const DECLARATION_VERSION_B_RATIO_DIRECTORS = {
  sections: {
    top: {
      subHeading: 'Man-vrouwverhouding',
      heading: 'In het bestuur van {{company}}',
      accordion: {
        styles: {
          marginTop: 2,
        },
        heading:
          'Lees meer over het wel of niet meetellen van rechtspersoon-bestuurders',
        content: (
          <>
            <p>
              Het is mogelijk dat de bestuurder van de vennootschap een
              rechtspersoon is.
            </p>
            <p>
              Telt een rechtspersoon-bestuurder wel of niet mee voor de
              man-vrouwverhouding in het bestuur? De wet Ingroeiquotum en
              streefcijfers lijkt hier geen rekening mee te houden.
            </p>
            <p>
              U kunt overwegen om 'door de rechtspersoon heen te kijken' en de
              bestuurders van deze rechtspersoon-bestuurder mee te tellen bij
              het bepalen van de man-vrouwverhouding in het bestuur.
            </p>
            <p>
              Dit is niet verplicht. Indien u deze personen wel meetelt, vragen
              we u duidelijk aan te geven{' '}
              <strong>
                in de toelichting aan de SER aan het einde van deze rapportage
              </strong>{' '}
              hoe is omgegaan met de telling van rechtspersoon-bestuurders.
            </p>
            <p>
              Wanneer het bestuur wordt gevormd door één of meer{' '}
              <strong>persoonlijke holdings</strong>, wordt aanbevolen de
              bestuurders van deze rechtspersoon-bestuurder(s){' '}
              <strong>wel</strong> mee te tellen.
            </p>
            <p>
              Wanneer het bestuur wordt gevormd door een{' '}
              <strong>tussenholding of topholding</strong> die kwalificeert als
              'grote' vennootschap, wordt aanbevolen de bestuurders van deze
              rechtspersoon-bestuurders <strong>niet</strong> mee te tellen.
              Voor deze vennootschap wordt dan immers ook al op een andere plek
              gerapporteerd over de man-vrouwsamenstelling van het bestuur. Het
              meetellen van deze bestuurders zou dan kunnen leiden tot een
              'dubbeltelling'.
            </p>
            <p>
              (Zie o.a. Stroeve, L.E. en Cremers, M.A.J. (2022). De
              beursvennootschap, AVA-seizoen 2023. In: Tijdschrift
              Ondernemingspraktijk, nr 8, p.27-32)
            </p>
          </>
        ),
      },
    },
    total: {
      heading: 'Hoeveel vrouwen en mannen had het bestuur op {{taxYear}}?',
    },
    appointments: {
      heading:
        'Hoeveel benoemingen en herbenoemingen zijn er geweest in het bestuur het boekjaar {{year}}?',
    },
    director: {
      heading: 'Voorzitter',
    },
    evaluation: {
      heading: 'Hoe staat het er voor?',
      subheading: 'Evaluatie',
      intro:
        'U heeft een streefcijfer vastgesteld voor de man-vrouwverhouding in het bestuur van {{target}}% voor het jaar {{year}}.',
      no_target:
        'U heeft geen streefcijfer vastgesteld voor het bestuur. Dit blok bevat daarom geen verdere vragen.',
      no_reporting_requirement:
        'U heeft geen streefcijfer vastgesteld voor het bestuur. Dit blok bevat daarom geen verdere vragen.',
    },
  },
  questions: {
    code_1: {
      name: 'code_1',
      type: 'checkbox',
      label:
        'N.v.t. het bestuur bestaat uitsluitend uit rechtspersoon-bestuurders en wij rapporteren daarom niet over de man-vrouwverhouding.',
    },
    'code_2.male': {
      input: {
        type: 'number-input',
        name: 'code_2.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
      col_label: 'Aantal mannen',
      label: 'Totaal op {{taxYear}}',
    },
    'code_2.female': {
      input: {
        type: 'number-input',
        name: 'code_2.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
      col_label: 'Aantal vrouwen',
    },
    code_3: {
      label: 'Aantal niet ingevulde vacatures {{taxYear}}',
      input: {
        type: 'number-input',
        name: 'code_3',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_4.male': {
      label: 'Herbenoemd',
      col_label: 'Aantal mannen',
      input: {
        type: 'number-input',
        name: 'code_4.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_4.female': {
      col_label: 'Aantal vrouwen',
      input: {
        type: 'number-input',
        name: 'code_4.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_5.male': {
      label: 'Nieuw benoemd',
      input: {
        type: 'number-input',
        name: 'code_5.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_5.female': {
      input: {
        type: 'number-input',
        name: 'code_5.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_6.male': {
      label: 'Afgetreden',
      input: {
        type: 'number-input',
        name: 'code_6.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_6.female': {
      input: {
        type: 'number-input',
        name: 'code_6.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    code_7: {
      name: 'code_7',
      type: 'radio-button-group',
      label: 'De voorzitter identificeert zich als:',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      items: [
        {
          id: 1,
          value: 'male',
          label: 'Man',
        },
        {
          id: 2,
          value: 'female',
          label: 'Vrouw',
        },
        {
          id: 3,
          value: 'non-binary',
          label: 'Non-binair',
        },
        {
          id: 4,
          value: 'other',
          label: 'Anders dan bovenstaande',
          style: {
            marginTop: '12px',
          },
        },
        {
          id: 5,
          value: 'no-chair',
          label: 'Er is geen voorzitter',
          style: {
            marginTop: '12px',
          },
        },
        {
          id: 6,
          value: 'not-saying',
          label: 'Dat zeggen wij liever niet',
          style: {
            marginTop: '12px',
          },
        },
      ],
    },
    code_8: {
      name: 'code_8',
      type: 'radio-button-group',
      label: 'Heeft u dit streefcijfer op {{taxYear}} gehaald?',
      items: [
        {
          id: 1,
          value: 'yes',
          label: 'Ja',
          style: {
            flex: 1,
            marginRight: '12px',
          },
        },
        {
          id: 2,
          value: 'not_yet',
          label: 'Nee, maar het streefjaar is nog niet bereikt',
        },
        {
          id: 3,
          value: 'no',
          label: 'Nee, en het streefjaar is al wel bereikt',
          style: {
            marginTop: '12px',
          },
        },
      ],
    },
    code_8_yes: {
      name: 'code_8_yes',
      type: 'textarea',
      label: 'Kunt u vertellen hoe u het streefcijfer heeft gerealiseerd?',
    },
    code_8_no: {
      name: 'code_8_no',
      type: 'textarea',
      label: 'Wat is de reden dat dit streefcijfer (nog) niet is gehaald?',
    },
    code_8_not_yet: {
      name: 'code_8_not_yet',
      type: 'radio-button-group',
      label: 'Ligt u op koers om dit streefcijfer te halen?',
      items: [
        {
          id: 1,
          value: 'yes',
          label:
            'Ja, de man-vrouwverhouding is voldoende verbeterd om het streefcijfer te gaan halen',
        },
        {
          id: 2,
          value: 'no',
          label:
            'Nee, de man-vrouwverhouding is onvoldoende verbeterd om het streefcijfer te gaan halen',
          style: {
            marginTop: '12px',
          },
        },
        {
          id: 3,
          value: 'unknown',
          label: 'Weet niet',
          style: {
            marginTop: '12px',
            flex: 1,
          },
        },
      ],
    },
    code_9: {
      name: 'code_9',
      type: 'textarea',
      label:
        'Wat gaat u de komende tijd doen om het streefcijfer alsnog te halen?',
    },
  },
}
