// React
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import ActivateCodeForm from '@partials/forms/activation'
import { Container } from '@mui/material'
import { toaster } from '@store/toaster.state'

const ActivationCode = () => {
  const navigate = useNavigate()

  const successHandler = useCallback(
    res => {
      navigate('/activate/success')
      const { message } = res
      toaster.success(message)
    },
    [navigate]
  )
  const errorHandler = useCallback(({ errors }) => {
    if (!errors?.forEach) return toaster.error('Er is een fout opgetreden')

    errors = errors.map(err => Object.values(err)[0])
    toaster.error(errors)
  }, [])

  return (
    <Container>
      <ActivateCodeForm
        onSuccess={successHandler}
        onError={errorHandler}
      />
    </Container>
  )
}

export default ActivationCode
