import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { getState } from '@store/ui.store'

// Components
import { Grid, Container } from '@mui/material'
import { Checkbox } from '@components'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

// Hooks
import { Panel } from '@components'
import { Button } from '@components'
import { dashboardHooks, profileHooks } from '@services'

import { CONTENT } from '@constants'

const { useUpdatePreferences } = profileHooks
const { useLoadCompanies } = dashboardHooks
const {
  profile: { preferences },
} = CONTENT

export const PreferencesForm = ({ onSuccess, onError }) => {
  const auth = getState('auth')
  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    receive_tips: false,
  })

  const [updatePreferences, submitting, res, err] = useUpdatePreferences()
  const [fetchWhoAmI, fetching, whoAmI] = useLoadCompanies()

  const update = useCallback(
    body => {
      updatePreferences({ user_id: auth?.data?.id, body })
    },
    [updatePreferences]
  )

  useEffect(() => {
    fetchWhoAmI()
  }, [])
  useEffect(() => {
    if (whoAmI?.data) {
      const { receive_tips } = whoAmI.data
      setValue('receive_tips', receive_tips)
    }
  }, [whoAmI])

  useEffect(() => {
    err && onError(err)
    if (res) {
      reset(res?.data)
      onSuccess(res)
    }
  }, [res, err, onSuccess, onError, reset])

  return (
    <form onSubmit={handleSubmit(update)}>
      <Container style={{ maxWidth: '1152px' }}>
        <Panel style={{ flexDirection: 'column' }}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingTop: 0 }}>
            <h2 style={{ marginTop: 0 }}>{preferences.heading}</h2>
            <div style={{ padding: '16px', border: '1px solid #B9B9BA' }}>
              <Controller
                name="receive_tips"
                control={control}
                render={({ field: { value, ref, onChange } }) => {
                  const { label } = preferences.questions.receive_info
                  return (
                    <Checkbox
                      label={label}
                      checked={value || false}
                      onChange={onChange}
                      inputRef={ref}
                      disabled={submitting || fetching}
                    />
                  )
                }}
              />
            </div>
          </Grid>
          <div style={styles.buttonWrapper}>
            <Button
              type="submit"
              variant="contained"
              loading={submitting || fetching}
              endIcon={<ArrowForwardIcon />}>
              {preferences.submit_button.label}
            </Button>
          </div>
        </Panel>
      </Container>
    </form>
  )
}

const styles = {
  buttonWrapper: {
    marginTop: '24px',
  },
}
