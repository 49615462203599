import { createFetchHooks } from '@acato/react-fetch'
import { profileService } from './service'

const handlers = (resCb = null, errCb = null) => ({
  responseHandler: res => (resCb && resCb(res?.data)) || res?.data,
  errorHandler: ({ errors, raw }) =>
    (errCb && errCb({ errors, raw })) || { errors, raw },
})

export const profileHooks = createFetchHooks(
  {
    name: 'useChangeName',
    fetcher: profileService.changeName,
    ...handlers(),
  },
  {
    name: 'useChangePassword',
    fetcher: profileService.changePassword,
    ...handlers(),
  },
  {
    name: 'useClaimCompany',
    fetcher: profileService.claimCompany,
    ...handlers(),
  },
  {
    name: 'useSwitchCompany',
    fetcher: profileService.switchCompany,
    ...handlers(),
  },
  {
    name: 'useUpdatePreferences',
    fetcher: profileService.updatePreferences,
    ...handlers(),
  },
  {
    name: 'useListConnectedUsers',
    fetcher: profileService.listConnectedUsers,
    ...handlers(),
  },
  {
    name: 'useDeactivateUser',
    fetcher: profileService.deactivateUser,
    ...handlers(),
  },
  {
    name: 'useAddContact',
    fetcher: profileService.addContact,
    ...handlers(res => {
      //Since the response is an empty string we check that response and return true.
      if (typeof res === 'string') {
        return true
      }
    }),
  }
)
