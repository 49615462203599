const regex = {
  activation_code: /.{16,}/, // min 16 chars
  kvk_number: /.{8,}/, // min 8 chars
  email: /\S+@\S+\.\S+/,
  // min 8 chars, one uppercase, one lowercase, one number
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
  postal_code: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
}

export default regex
