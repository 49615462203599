/* eslint-disable max-len */
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'

// Components
import { Grid, Container } from '@mui/material'
import { TextInput } from '@components'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

// Validation
import regex from '@validation/regex'

// Hooks
import { Panel } from '@components'
import { Button } from '@components'
import { profileHooks } from '@services'
import { getState } from '@store/ui.store'

const { useChangeName } = profileHooks

export const ChangeNameForm = ({ onSuccess, onError, setUsername }) => {
  const auth = getState('auth')

  let formValues = {
    name: auth?.data?.name,
  }

  const {
    register,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: formValues,
  })

  const [sendChangeName, loading, res, err] = useChangeName()

  const changeNameHandler = useCallback(
    ({ name }) => {
      sendChangeName({
        user_id: auth?.data?.id,
        name,
      })
    },
    [sendChangeName, auth]
  )

  useEffect(() => {
    err && onError(err)
    if (res) {
      onSuccess(res)
      reset()
      setValue('name', auth?.data?.name)
      setUsername(auth?.data?.name)
    }
  }, [res, err, onSuccess, onError, reset, setValue, auth])

  return (
    <form onSubmit={handleSubmit(changeNameHandler)}>
      <Container style={{ maxWidth: '1152px' }}>
        <Panel style={{ flexDirection: 'column' }}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingTop: 0 }}>
            <TextInput
              horizontal
              type="text"
              label={'Naam'}
              {...register('name', {
                required: {
                  value: true,
                  message: 'Naam is een verplicht veld.',
                },
              })}
              {...(errors.name?.message && {
                alert: {
                  message: errors.name?.message,
                },
              })}
            />
          </Grid>
          <div style={styles.buttonWrapper}>
            <Button
              type="submit"
              variant="contained"
              loading={loading}
              endIcon={<ArrowForwardIcon />}>
              Opslaan
            </Button>
          </div>
        </Panel>
      </Container>
    </form>
  )
}

const styles = {
  buttonWrapper: {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}
