import { COLORS } from '@constants'

const styles = {
  page: {
    backgroundColor: COLORS.secondary.main,
  },
  breadcrumbContainer: {
    marginTop: '-56px',
  },
}

export default styles
