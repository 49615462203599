/* eslint-disable max-len */

import { useState, useEffect } from 'react'
import { intakeHooks } from '@services'

// Components
import Grid from '@mui/material/Grid'
import { Alert } from '@components'
import { Button } from '@components'
import { TextInput } from '@components'
import CircularProgress from '@mui/material/CircularProgress'

// State
import { modal } from '@store/modal.state'
import { toaster } from '@store/toaster.state'

// Style
import './styles.scss'

const ResetDeclarationModal = ({ year, company, intake_id }) => {
  const [input, setInput] = useState('')
  const [error, setError] = useState(null)
  const [delay, setDelay] = useState(false)

  // Hooks
  const { useResetIntake } = intakeHooks
  const [resetIntake, loading, res, err] = useResetIntake()

  // Methods
  const confirmDelete = () => {
    if (company.kvk_number.toString() !== input) {
      return setError(
        'Het KVK nummer komt niet overeen. Weet u zeker dat u het juiste nummer heeft ingevoerd?'
      )
    } else {
      resetIntake({ intake_id })
      setDelay(true)
    }
  }

  useEffect(() => {
    if (res) {
      toaster.success('Rapportage verwijderd, u kunt weer opnieuw beginnen')
      setTimeout(() => {
        modal.setClose()
        window.location.reload()
      }, 2500)
    }
    if (err) {
      setDelay(false)
      err?.errors &&
        toaster.error(
          'Er is iets mis gegaan bij het herstarten van de rapportage. Probeer het later nog een keer.'
        )
    }
  }, [res, err])

  return (
    <div className="modal">
      <p style={{ marginTop: 0 }}>
        U staat op het punt om alle data van de rapportage over {year} te
        verwijderen.
      </p>
      <Alert
        variant="outlined"
        severity="warning"
        content="Als u tot een groep behoort, worden alle openstaande en reeds geaccepteerde verzoeken van groepsmaatschappijen ook verwijderd."
      />
      <h4>Weet u zeker dat u de rapportage over {year} wilt verwijderen?</h4>
      <TextInput
        id="kvk_number"
        label="Vul hieronder uw KvK-nummer in ter controle"
        value={input}
        onChange={e => setInput(e.target.value)}
        type="number"
        required
        data-kvk={company?.kvk_number}
      />
      {error && (
        <Alert
          severity="error"
          content={error}
          sx={{ marginBottom: '24px' }}
        />
      )}
      <Grid
        container
        spacing={5}>
        <Grid
          item
          xs={12}
          sm={6}>
          <Button
            id="accept"
            fullWidth
            onClick={confirmDelete}
            color="primary"
            variant="outlined"
            disabled={loading || delay}>
            Herstart rapportage
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}>
          <Button
            id="reject"
            fullWidth
            onClick={modal.setClose}
            color="primary"
            variant="contained"
            disabled={loading || delay}>
            Annuleren
          </Button>
        </Grid>
      </Grid>
      <div className={`loading-overlay ${loading || delay ? 'loading' : ''}`}>
        <CircularProgress />
      </div>
    </div>
  )
}

export default ResetDeclarationModal
