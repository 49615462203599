import { setPartialState, subscribe } from './ui.store'

export const startAuthSubscription = () => {
  setPartialState('auth')(JSON.parse(localStorage.getItem('auth')) || null)
  subscribe(value => {
    if (!value) {
      localStorage.removeItem('auth')
    } else if (!localStorage.getItem('auth'))
      localStorage.setItem('auth', JSON.stringify(value))
  }, 'auth')
}
