import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'

// Services
import regex from '@validation/regex'

// Components
import { Panel, Button, CodeInput, TextInput } from '@components'
import { Grid, InputLabel } from '@mui/material'
import { activationHooks } from '@services/activation/hooks'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const { useActivateCode } = activationHooks

const ActivateCodeForm = ({ onSuccess, onError }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      activation_code: '',
      kvk_number: '',
      contacts: [
        {
          firstName: '',
          lastName: '',
          email: '',
          type: 'regular',
        },
        {
          firstName: '',
          lastName: '',
          email: '',
          type: 'administrative',
        },
      ],
    },
  })

  const requiredCodesValid =
    watch(['activation_code', 'kvk_number']).filter(Boolean).length === 2

  const [activate, loading, res, err] = useActivateCode()

  const registerHandler = useCallback(
    body => {
      activate(body)
    },
    [activate]
  )

  useEffect(() => {
    err && onError(err)
    res && onSuccess(res)
  }, [res, err, onSuccess, onError])

  return (
    <>
      <Panel spacing={5}>
        <Grid
          item
          xs={12}>
          <h2>Uw bedrijf aanmelden</h2>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <p>
            U heeft van de SER en het ministerie van OCW een uitnodiging
            ontvangen om uw bedrijf aan te melden bij het SER
            Diversiteitsportaal.
          </p>
          <p>Wat heeft u nodig?</p>
          <ul>
            <li>Het KVK-nummer van het bedrijf dat u aanmeldt.</li>
            <li>
              De unieke activatiecode van uw bedrijf. De activatiecode vindt u
              in uw uitnodigingsbrief.
            </li>
            <li>
              De namen en e-mailadressen van twee contactpersonen die de
              rapportages voor uw bedrijf gaan beheren.
            </li>
          </ul>

          <p>
            <strong>Aanmelden</strong>
            <br />
            Het aanmelden van uw bedrijf bij het SER Diversiteitsportaal gaat in
            3 stappen:
          </p>
          <p>
            <strong>
              Stap 1: Uw bedrijf aanmelden bij het SER Diversiteitsportaal
            </strong>
            <br />U meldt uw bedrijf aan door het KVK-nummer en de unieke
            activatiecode die u van de SER heeft ontvangen hier in te vullen.
          </p>
          <p>
            Heeft u geen activatiecode ontvangen geef dit dan zo spoedig
            mogelijk door aan de helpdesk van de SER.
          </p>
          <p>
            <strong>
              Stap 2: Uw contactpersonen aanmelden bij het SER
              Diversiteitsportaal
            </strong>
            <br />
            Wanneer u de activatiecode en het KVK-nummer heeft ingevoerd, geeft
            u de namen en e-mailadressen door van twee contactpersonen die de
            rapportage(s) voor dit bedrijf gaan beheren. Ten minste één van deze
            contactpersonen is een bestuurlijk verantwoordelijke.
          </p>

          <p style={{ fontStyle: 'italic' }}>
            Het e-mailadres van uw contactpersonen wordt gebruikt voor hun
            persoonlijke gebruikersaccount. Geef daarom voor iedere
            contactpersoon een uniek e-mailadres door.
          </p>

          <p>
            <strong>
              Stap 3: Contactpersonen maken een persoonlijk gebruikersaccount
              aan
            </strong>
            <br />
            Uw contactpersonen ontvangen op het e-mailadres dat u bij de
            aanmelding hebt opgegeven een uitnodiging om een persoonlijk
            gebruikersaccount aan te maken. Contactpersonen kunnen met één
            gebruikersaccount de rapportages voor meerdere, verschillende
            bedrijven beheren.
          </p>

          <p style={{ fontStyle: 'italic' }}>
            Hebben contactpersonen al een gebruikersaccount? Dan kunnen zij deze
            stap overslaan en direct inloggen. Uw bedrijf wordt na de aanmelding
            automatisch toegevoegd aan hun persoonlijke gebruikersaccount.
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <CodeInput
            style={{ marginBottom: '12px' }}
            label={<InputLabel>Activatiecode *</InputLabel>}
            onComplete={value => {
              setValue('activation_code', value?.toUpperCase())
            }}
            layout={{
              colSpacing: 1.3,
              cellSpacing: 0.2,
            }}
            pattern={/^[0-9A-Za-z]$/}
            groupOptions={{
              split: [4, 4, 4, 4],
              separator: '-',
            }}>
            {(isValid, touched) => (
              <TextInput
                variant={'standard'}
                {...(!isValid &&
                  touched && {
                    color: 'error',
                  })}
                {...(touched &&
                  isValid && {
                    color: 'success',
                  })}
                inputProps={{
                  maxLength: 1,
                  style: {
                    textTransform: 'uppercase',
                    width: '25px',
                    textAlign: 'center',
                  },
                }}
              />
            )}
          </CodeInput>

          <TextInput
            fullWidth
            label="KVK-nummer"
            required
            {...register('kvk_number', {
              required: {
                value: true,
                message: 'Dit veld is verplicht.',
              },
            })}
            {...(errors?.kvk_number?.message && {
              alert: {
                message: errors?.kvk_number?.message,
              },
            })}
          />
        </Grid>
      </Panel>
      <form onSubmit={handleSubmit(registerHandler)}>
        {requiredCodesValid && (
          <Panel spacing={5}>
            <Grid
              item
              xs={12}>
              <h2>Contactpersonen doorgeven</h2>
              <p>
                Hieronder kunt u de namen invullen van twee contactpersonen die
                de rapportage voor uw bedrijf gaan beheren. Tenminste één van
                deze personen is statutair bestuurder.
                <br />
                <br />
                Beide contactpersonen ontvangen kort hierna een e-mail met een
                uitnodiging om een persoonlijk account aan te maken voor het SER
                Diversiteitsportaal. Met dit account krijgen zij toegang tot het
                portaal en kunnen zij de rapportages voor uw bedrijf invullen en
                indienen. Zij zijn tevens aanspreekpunt voor de SER.
              </p>
            </Grid>
            <div></div>
            <Grid
              item
              xs={12}
              md={6}>
              <h3>Contactpersoon voor de SER</h3>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <h3>Bestuurlijk verantwoordelijke (CEO, directeur, etc.)</h3>
              <p>
                Het gaat hier om een statutair bestuurder. Wanneer een
                rechtspersoon bestuurder is, vult u hier de gegevens in van
                degene die op grond van de wet, de statuten of een volmacht
                bevoegd is de onderneming te vertegenwoordigen.
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <TextInput
                label="Voornaam"
                required
                {...register('contacts[0].firstName', {
                  required: {
                    value: true,
                    message: 'Dit veld is verplicht.',
                  },
                })}
                {...(errors?.contacts?.[0]?.firstName?.message && {
                  alert: {
                    message: errors?.contacts?.[0]?.firstName?.message,
                  },
                })}
              />
              <TextInput
                label="Achternaam"
                required
                {...register('contacts[0].lastName', {
                  required: {
                    value: true,
                    message: 'Dit veld is verplicht.',
                  },
                })}
                {...(errors?.contacts?.[0]?.lastName?.message && {
                  alert: {
                    message: errors?.contacts?.[0]?.lastName?.message,
                  },
                })}
              />
              <TextInput
                label="Email"
                required
                {...register('contacts[0].email', {
                  required: {
                    value: true,
                    message: 'Dit veld is verplicht.',
                  },
                  pattern: {
                    value: regex.email,
                    message: 'Vul een geldig email adres in.',
                  },
                })}
                {...(errors?.contacts?.[0]?.email?.message && {
                  alert: {
                    message: errors?.contacts?.[0]?.email?.message,
                  },
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <TextInput
                label="Voornaam"
                required
                {...register('contacts[1].firstName', {
                  required: {
                    value: true,
                    message: 'Dit veld is verplicht.',
                  },
                })}
                {...(errors?.contacts?.[1]?.firstName?.message && {
                  alert: {
                    message: errors?.contacts?.[1]?.firstName?.message,
                  },
                })}
              />
              <TextInput
                label="Achternaam"
                required
                {...register('contacts[1].lastName', {
                  required: {
                    value: true,
                    message: 'Dit veld is verplicht.',
                  },
                })}
                {...(errors?.contacts?.[1]?.lastName?.message && {
                  alert: {
                    message: errors?.contacts?.[1]?.lastName?.message,
                  },
                })}
              />
              <TextInput
                label="Email"
                required
                {...register('contacts[1].email', {
                  required: {
                    value: true,
                    message: 'Dit veld is verplicht.',
                  },
                  pattern: {
                    value: regex.email,
                    message: 'Vul een geldig email adres in.',
                  },
                })}
                {...(errors?.contacts?.[1]?.email?.message && {
                  alert: {
                    message: errors?.contacts?.[1]?.email?.message,
                  },
                })}
              />
            </Grid>
          </Panel>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 12,
          }}>
          <Button
            disabled={!requiredCodesValid}
            type="submit"
            variant="contained"
            color="primary"
            loading={loading}
            endIcon={<ArrowForwardIcon />}>
            Volgende
          </Button>
        </div>
      </form>
    </>
  )
}

export default ActivateCodeForm
