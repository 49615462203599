const canvasDownload = {
  sections: {
    download: {
      button: {
        label: 'Download uw canvas',
        href: '#',
      },
    },
  },
}

export default canvasDownload
