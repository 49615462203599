import { useCallback, useMemo } from 'react'

// Components
import { IntakeGroupRelationChangeCompany } from '@components'
import Grid from '@mui/material/Grid'

const getStatusFilter = filterType => {
  switch (filterType) {
    case 'UPSERT':
      return change =>
        change?.status === 'added' || change?.status === 'updated'
    case 'DELETED':
      return change => change?.status === 'removed'
  }
}

const IntakeGroupRelationChange = ({ changes = [] }) => {
  const changedRelations = useCallback(
    filterType => changes.filter(getStatusFilter(filterType)),
    [changes]
  )

  const renderRelations = useCallback(
    filterType => {
      const filteredChanges = changedRelations(filterType)

      return (
        <Grid
          container
          gap={1}>
          {filteredChanges.map((company, index) => (
            <IntakeGroupRelationChangeCompany
              key={index}
              {...company}
            />
          ))}
        </Grid>
      )
    },
    [changes]
  )

  const hasDeletedChanges = useMemo(() => {
    return !!changedRelations('DELETED')?.length
  }, [changedRelations])

  const hasUpsertedChanges = useMemo(() => {
    return !!changedRelations('UPSERT')?.length
  }, [changedRelations])

  return (
    <div className="modal">
      <p style={{ marginTop: 0 }}>
        Na het afronden van de intake kunt u de intake niet meer wijzigen.
      </p>
      <p>
        U heeft één of meer wijzigingen doorgegeven die ook van invloed zijn op
        andere groepsmaatschappijen. Hieronder ziet u om welke wijzigingen het
        gaat.
      </p>
      {hasUpsertedChanges && (
        <>
          <p>
            U heeft doorgegeven dat de volgende bedrijven nu onderdeel uitmaken
            van uw groep. Zij ontvangen via het diversiteitsportaal een verzoek
            om de voorgestelde wijziging te accepteren.
          </p>
          {renderRelations('UPSERT')}
        </>
      )}
      {hasDeletedChanges && (
        <>
          <p>
            U heeft doorgegeven dat de volgende bedrijven geen onderdeel meer
            uitmaken van uw groep. Zij krijgen hiervan geen bericht.
          </p>
          {renderRelations('DELETED')}
        </>
      )}
    </div>
  )
}

export default IntakeGroupRelationChange
