import { useEffect } from 'react'
import { Controller } from 'react-hook-form'

// Material
import { Grid } from '@mui/material'

// Components
import { BooleanQuestion, Datepicker } from '@components'

// Utils
import addDays from '@helpers/dates/addDays'
import interpolate from '@helpers/strings/interpolate'

// Content
import forms from '@constants/forms'

// Styles
import { COLORS } from '@constants'
import '../styles.scss'

const IntakeVersionBFormStep1 = ({
  intake,
  disabled,
  control,
  watch,
  setValue,
}) => {
  // State
  const { company } = intake
  const { sections, questions } = forms.intake.b

  // Conditionally render inputs based on previous answers
  const otherLegalEntity = watch('is_private_or_limited_company')
  const has_extended_tax_year = watch('has_extended_tax_year')
  const start_tax_year_at = watch('start_tax_year_at')

  useEffect(() => {
    if (otherLegalEntity) setValue('has_other_legal_form', null)
  }, [otherLegalEntity, setValue])

  // Handle default dates
  useEffect(() => {
    if (has_extended_tax_year && !start_tax_year_at) {
      setValue('start_tax_year_at', new Date(`01-01-${intake.year}`))
      setValue('end_tax_year_at', new Date(`01-31-${intake.year}`))
    }
    if (!has_extended_tax_year) {
      setValue('start_tax_year_at', null)
      setValue('end_tax_year_at', null)
    }
  }, [has_extended_tax_year, intake.year, setValue])

  return (
    <>
      <h2>{sections.extended_year.heading}</h2>
      <Controller
        control={control}
        name="has_extended_tax_year"
        render={({
          field: { name, value, ref, onChange },
          fieldState: { error },
        }) => {
          return (
            <BooleanQuestion
              required
              value={value}
              disabled={disabled}
              onChange={onChange}
              question={interpolate(questions[name].label, {
                company: company.name,
              })}
              alert={{ message: error?.message }}
              inputRef={ref}
            />
          )
        }}
      />

      {has_extended_tax_year && (
        <div className="fade-in">
          <Grid
            container
            sx={{
              marginTop: '6px',
              color: disabled ? COLORS.gray.disabled : undefined,
            }}>
            <Grid
              item
              xs={12}
              md={6}>
              <p>{questions.start_tax_year_at.label}</p>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <Controller
                name="start_tax_year_at"
                control={control}
                render={({
                  field: { name, value, ref, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Datepicker
                      date={new Date(value)}
                      setDate={date => {
                        onChange(date)
                        setValue('end_tax_year_at', addDays(date, 364))
                      }}
                      label={questions[name].label}
                      fullWidth
                      alert={error?.message}
                      inputRef={ref}
                      defaultValue={new Date(`01-01-${intake.year}`)}
                      minDate={new Date(`01-01-${intake.year}`)}
                      maxDate={new Date(`12-31-${intake.year}`)}
                      disabled={disabled}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              marginTop: '6px',
              color: disabled ? COLORS.gray.disabled : undefined,
            }}>
            <Grid
              item
              xs={12}
              md={6}>
              <p>{questions.end_tax_year_at.label}</p>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <Controller
                name="end_tax_year_at"
                control={control}
                render={({
                  field: { name, value, ref, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Datepicker
                      date={value}
                      setDate={onChange}
                      label={questions[name].label}
                      fullWidth
                      alert={error?.message}
                      inputRef={ref}
                      minDate={new Date(`01-01-${intake.year}`)}
                      disabled={disabled}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default IntakeVersionBFormStep1
