/* eslint-disable max-len */
import ReactMarkdown from 'react-markdown'
import { modal } from '@store/modal.state'

// Components
import { Chip } from '@components'
import { Button } from '@components'
import RequestModal from '@partials/modals/HandleRequestModal'
import StartDeclarationModal from '@partials/modals/StartDeclarationModal'
import Tooltip from '@mui/material/Tooltip'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

// Utils
import renderStatusChip from '@helpers/view/renderStatusChip'
import renderRequestChip from '@helpers/view/renderRequestChip'
import renderDeclarationButton from '@helpers/view/renderDeclarationButton'

// styles
import './styles.scss'

const SubSidiary = ({
  year,
  parent,
  subsidiary,
  openRequests,
  consolidated,
  parentDeclarationStatus,
}) => {
  const { company, declaration_id, declaration_status, request } = subsidiary

  const allowDeclaration = declaration_id ? true : false
  const status =
    consolidated &&
    (request.type ===
      'you-are-my-subsidiary-and-i-will-take-care-of-your-declaration' ||
      request.type ===
        'you-are-my-group-head-and-you-will-take-care-of-my-declaration')
      ? renderStatusChip(parentDeclarationStatus)
      : renderStatusChip(declaration_status)

  const requestChip = renderRequestChip(request, company, parent, year)
  const isDenied = request?.status === 'denied-by-company'

  // Methods
  const openRequestModal = () => {
    modal.setOpen({
      heading: 'Beoordeel openstaand rapportageverzoek',
      content: (
        <RequestModal
          id={request.id}
          content={<ReactMarkdown>{requestChip.message}</ReactMarkdown>}
        />
      ),
    })
  }

  const openInfoModal = () => {
    modal.setOpen({
      heading: 'Informatie over uw openstaande verzoeken',
      content: <ReactMarkdown>{requestChip.message}</ReactMarkdown>,
    })
  }

  const startDeclaration = () => {
    const modalBody = {
      heading: 'Let op',
      content: (
        <StartDeclarationModal
          year={year}
          company={company}
        />
      ),
      buttons: [
        {
          id: 1,
          text: 'Ga verder',
          to: `/declaration/${declaration_id}`,
          type: 'contained',
          color: 'primary',
        },
      ],
    }
    modal.setOpen(modalBody)
  }

  return (
    <div className="subsidiariesGrid">
      <div>
        <span
          style={{
            fontSize: '20px',
            fontWeight: 500,
          }}>
          {company?.name}
        </span>
        <br />
        <span style={{ color: '#666666' }}>{company?.kvk_number}</span>
      </div>
      <Chip
        variant={requestChip.variant}
        text={requestChip.text}
        color={requestChip.severity}
        icon={requestChip.icon}
        sx={{ width: 'fit-content' }}
        onClick={
          request.status === 'open'
            ? request.incoming_request
              ? openRequestModal
              : openInfoModal
            : null
        }
      />
      {!isDenied && status && (
        <Chip
          variant="outlined"
          color="primary"
          text={status.message}
          sx={{ width: 'fit-content' }}
        />
      )}
      {isDenied && (
        <Tooltip title="Het acceptatieverzoek is afgewezen. Herstart de rapportage om opnieuw te beginnen.">
          <div>
            <Chip
              variant="filled"
              color="error"
              text="Verzoek afgewezen"
              sx={{ width: 'fit-content' }}
            />
          </div>
        </Tooltip>
      )}

      {request?.status === 'open' && request.incoming_request && (
        <Button
          variant="outlined"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={() => openRequestModal(company)}>
          Bekijk verzoek
        </Button>
      )}

      {openRequests && (
        <Tooltip title="Beoordeel eerst alle openstaande verzoeken binnen uw groep, voordat u begint met de rapportage.">
          <div className="tooltip">
            <Button
              variant="contained"
              disabled>
              {renderDeclarationButton(declaration_status)}
            </Button>
          </div>
        </Tooltip>
      )}

      {allowDeclaration && (
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={startDeclaration}
          disabled={!allowDeclaration}
          sx={{ width: 'fit-content' }}>
          {renderDeclarationButton(declaration_status)}
        </Button>
      )}
    </div>
  )
}

export default SubSidiary
