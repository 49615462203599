import { useState } from 'react'
import {
  Accordion as MUIAccordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useFormContext } from 'react-hook-form'

interface Props {
  id?: string
  heading: any
  content?: any
  disabled?: boolean
  expanded?: boolean
  expandIcon?: any
  sx?: any
}

const Accordion: React.FC<Props> = ({
  id,
  heading,
  content,
  disabled,
  expanded = false,
  expandIcon = <ExpandMoreIcon />,
  sx,
}) => {
  const [openState, setOpenState] = useState(expanded)
  const ctx: any = useFormContext()

  if (ctx?.isPDFExport) return null

  return (
    <MUIAccordion
      onChange={() => setOpenState(!openState)}
      expanded={openState}
      disabled={disabled}
      sx={sx}
      disableGutters>
      <AccordionSummary
        expandIcon={expandIcon}
        id={id}>
        {heading}
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </MUIAccordion>
  )
}

export default Accordion
