import { useState, useEffect } from 'react'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'

// Store
import { intakeHooks } from '@services'

// MUI
import { Container } from '@mui/material'

// Components
import { Panel } from '@components'
import { Alert } from '@components'
import { Button } from '@components'
import { Loader } from '@components'
import { toaster } from '@store/toaster.state'

const IntakeSuccessView = () => {
  // Hooks
  const { useGetIntake } = intakeHooks
  const [fetchIntake, , res, err] = useGetIntake()
  const { intake_id } = useParams()
  const { setSectionColor } = useOutletContext()
  const navigate = useNavigate()

  // State
  const [loading, setLoading] = useState(true)
  const [intake, setIntake] = useState(null)
  const [content, setContent] = useState('')
  const [alert, setAlert] = useState('')
  const [severity, setSeverity] = useState('')

  // Reactive
  useEffect(() => {
    fetchIntake(intake_id)
    setSectionColor('default')
  }, [intake_id, fetchIntake, setSectionColor])

  useEffect(() => {
    if (res) {
      setIntake(res.data)
      setLoading(false)
    }

    if (err?.errors) {
      toaster.error(err.errors)
      return navigate('/dashboard')
    }
  }, [res, err, navigate])

  useEffect(() => {
    if (intake) {
      const {
        status,
        subsidiaries,
        group_head,
        is_part_of_group,
        group_head_is_native,
        company,
      } = intake

      if (status !== 'submitted') {
        return navigate(`/intake/${intake_id}`)
      }

      if (!is_part_of_group || group_head_is_native === false) {
        setSeverity('success')
        setAlert('Bedankt voor het invullen van de intake.')
        setContent(
          <p>
            U kunt nu terug naar de bedrijfspagina om met uw rapportage te
            beginnen.
          </p>
        )
      } else {
        setSeverity('info')
        setAlert(
          `Bedankt! De intake voor ${
            company?.name || 'uw bedrijf'
          } is succesvol afgerond.`
        )
      }

      if (subsidiaries?.length > 0) {
        setContent(
          <>
            <p>
              We hebben een e-mailbericht gestuurd naar de contactpersonen van
              de groepsmaatschappij(en) in uw groep.
            </p>
            <p>
              Zodra alle openstaande verzoeken zijn geaccepteerd, kan gestart
              worden met rapporteren. U kunt u de verzoeken inzien op uw
              bedrijfspagina.
            </p>
          </>
        )
      }

      if (group_head) {
        setContent(
          <>
            <p>
              We hebben een e-mailbericht gestuurd naar de contactpersonen van
              het hoofd van uw groep.
            </p>
            <p>
              Zodra alle openstaande verzoeken zijn geaccepteerd, kan gestart
              worden met rapporteren. U kunt u de verzoeken inzien op uw
              bedrijfspagina.
            </p>
          </>
        )
      }
    }
  }, [intake, intake_id, navigate])

  if (loading) {
    return (
      <Container>
        <Panel
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh',
          }}>
          <Loader text="Een moment geduld..." />
        </Panel>
      </Container>
    )
  }

  return (
    <Container>
      <Panel style={{ display: 'flex', flexDirection: 'column' }}>
        <h1>Rapportage {intake.year}</h1>
        {alert && (
          <Alert
            severity={severity || 'info'}
            content={alert}
            sx={{ width: 'fit-content' }}
          />
        )}
        {content && <div style={{ whiteSpace: 'pre-line' }}>{content}</div>}
      </Panel>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 24,
        }}>
        <Button
          variant="contained"
          to={'/company/' + intake?.company?.id}>
          Terug naar bedrijfspagina
        </Button>
      </div>
    </Container>
  )
}

export default IntakeSuccessView
