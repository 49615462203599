// MUI X
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import nlLocale from 'date-fns/locale/nl'

// MUI
import TextField from '@mui/material/TextField'
import { Alert } from '@components'

interface Props {
  date: Date
  setDate: (newDate: Date | null) => void
  inputFormat?: string
  label?: string
  fullWidth?: boolean
  alert?: string
  inputRef: any
  defaultValue?: any
  minDate?: any
  maxDate?: any
  disabled?: boolean
}

const Datepicker: React.FC<Props> = ({
  date,
  disabled,
  setDate,
  inputFormat = 'dd MMMM yyyy',
  label,
  fullWidth,
  alert,
  inputRef,
  defaultValue,
  minDate,
  maxDate,
}) => {
  const value = !date ? defaultValue : date

  return (
    <div>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={nlLocale}>
        <MobileDatePicker
          label={label}
          inputFormat={inputFormat}
          value={value}
          onChange={setDate}
          inputRef={inputRef}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth={fullWidth}
              InputLabelProps={{ shrink: false }}
              sx={{
                '& .MuiInputLabel-root': {
                  display: 'none',
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
      {alert && (
        <Alert
          severity="error"
          content={alert}
        />
      )}
    </div>
  )
}

export default Datepicker
