import { declarationResource } from './resource'

export const declarationService = {
  fetchDeclaration: (declaration_id, signal) =>
    declarationResource.get(`/declarations/${declaration_id}`, {
      signal: signal(),
    }),
  updateDeclaration: ({ endpoint, body }, signal) =>
    declarationResource.patch(
      endpoint,
      {
        answers: {
          ...body,
        },
      },
      { signal: signal() }
    ),
  fetchPreferences: (declaration_id, signal) =>
    declarationResource.get(`/declarations/${declaration_id}/preferences`, {
      signal: signal(),
    }),
  updatePreferences: ({ declaration_id, body }, signal) =>
    declarationResource.patch(
      `/declarations/${declaration_id}/preferences`,
      { ...body },
      {
        signal: signal(),
      }
    ),
  addActivity: ({ declaration_id, label }, signal) =>
    declarationResource.post(
      `/declarations/${declaration_id}/activities`,
      {
        label,
      },
      {
        signal: signal(),
      }
    ),
  fetchActivities: ({ declaration_id, library }, signal) =>
    declarationResource.get(
      `/declarations/${declaration_id}/activities/${library}`,
      {
        signal: signal(),
      }
    ),
  setActivityFocus: ({ declaration_id, focus_id, type, field }, signal) =>
    declarationResource.patch(
      `/declarations/${declaration_id}/focus/${focus_id}`,
      { type, field },
      {
        signal: signal(),
      }
    ),
  fetchPlan: (declaration_id, signal) =>
    declarationResource.get(`/declarations/${declaration_id}/plan`, {
      signal: signal(),
    }),
  downloadCanvas: signal =>
    declarationResource.get(
      '/declarations/canvas',
      { responseType: 'blob' },
      { signal: signal() }
    ),
  generateExportPDF: (id, signal) =>
    declarationResource.get(
      `/declarations/${id}/export`,
      { responseType: 'blob' },
      { signal: signal() }
    ),
}
