import html2canvas from 'html2canvas'

export const elementToImage = async (inputEl: HTMLElement) => {
  const config = {
    scale: 1, // Adjust scale to reduce file size
    useCORS: true,
    logging: false,
  }

  try {
    const result = await html2canvas(inputEl, config)
    return Promise.resolve(result)
  } catch (error) {
    return Promise.reject(error)
  }
}
