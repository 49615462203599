import { useCallback, useEffect } from 'react'

// Components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

// Hooks
import { modal } from '@store/modal.state'
import { Button } from '@components'

// Style
import './style.scss'

// Icons
import { Add, ExpandMore } from '@mui/icons-material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { profileHooks } from '@services/profile/hooks'
import { AddContactForm } from './add.contact.form'
import { toaster } from '@store/toaster.state'

const UserRow = ({ user, onUpdate }) => {
  // Hooks
  const { useDeactivateUser } = profileHooks
  const [deactivateUser, loading, res, err] = useDeactivateUser()

  const confirmDeactivation = () => {
    modal.setOpen({
      customHeading: (
        <h2
          style={{
            color: 'black',
            margin: '16px 24px',
            padding: 0,
            lineHeight: '1.6',
          }}>
          Weet u zeker dat u deze gebruiker wilt deactiveren?
        </h2>
      ),
      content: (
        <span>
          {user.name} kan dan niet meer inloggen met de inloggegevens.
        </span>
      ),
      props: {
        width: '400px',
      },
      buttons: [
        {
          id: 1,
          text: 'Ja, ik weet het zeker',
          onClick: () => {
            deactivateUser({ company_id: user.company_id, user_id: user.id })
            modal.setClose()
          },
          variant: 'contained',
          color: 'primary',
          style: { margin: '16px 8px' },
        },
        {
          id: 2,
          text: 'Nee, ga terug',
          variant: 'outlined',
          color: 'primary',
          style: { margin: '16px' },
        },
      ],
    })
  }

  useEffect(() => {
    if (err) {
      modal.setOpen({
        customHeading: (
          <h2
            style={{
              color: 'black',
              margin: '16px 24px',
              padding: 0,
              lineHeight: '1.6',
            }}>
            Deactiveren mislukt
          </h2>
        ),
        content: (
          <span>
            Gebruiker {user.name} kon niet worden gedeactiveerd
            {err?.raw?.users && (
              <ul>
                {err.raw.users.map((error, key) => (
                  <li key={key}>{error}</li>
                ))}
              </ul>
            )}
          </span>
        ),
        props: {
          width: '400px',
        },
        buttons: [
          {
            id: 1,
            text: 'Sluit',
            variant: 'contained',
            color: 'primary',
            style: { margin: '16px' },
          },
        ],
      })

      onUpdate()
    }
  }, [err])
  useEffect(() => {
    if (res) {
      modal.setOpen({
        customHeading: (
          <h2 style={{ color: 'black', margin: '16px 24px' }}>
            Gebruiker gedeactiveerd
          </h2>
        ),
        content: (
          <span>
            {user.name} kan nu niet meer inloggen met de inloggegevens.
          </span>
        ),
        props: {
          width: '400px',
        },
        buttons: [
          {
            id: 1,
            text: 'Sluit',
            variant: 'contained',
            color: 'primary',
            style: { margin: '16px' },
          },
        ],
      })
      onUpdate()
    }
  }, [res])

  return (
    <TableRow>
      <TableCell style={{ padding: '12px 16px' }}>{user.name}</TableCell>
      <TableCell style={{ padding: '12px 16px' }}>{user.email}</TableCell>
      <TableCell style={{ padding: '12px 16px' }}>
        {String.fromCharCode(user.role === 'administrative' ? 10003 : 10006)}
      </TableCell>
      <TableCell style={{ padding: '12px 16px' }}>
        {user.deactivated_at ? (
          'Gedeactiveerd'
        ) : (
          <Button
            onClick={confirmDeactivation}
            variant="outlined"
            style={{ height: '40px', padding: '10px' }}
            endIcon={<ArrowForwardIcon />}>
            Deactiveren
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}

export const ConnectedUsersForm = ({ userData, onUpdate }) => {
  const errorHandler = ({ errors }) => {
    if (!errors?.forEach) return toaster.error('Er is een fout opgetreden')

    errors = errors.map(err => Object.values(err)[0])
    toaster.error(errors)
  }

  const successHandler = ({ first_name, last_name }) => {
    modal.setOpen({
      heading: 'Uitnodiging verstuurd',
      // eslint-disable-next-line max-len
      content: `${first_name} ${last_name} ontvangt binnen enkele minuten een uitnodigingsmail, waarmee het account aangemaakt kan worden.`,
    })
  }

  const handleAddContact = companyId => {
    modal.setOpen({
      heading: 'Een contactpersoon toevoegen',
      content: (
        <AddContactForm
          company_id={companyId}
          onError={errors => errorHandler(errors)}
          onSuccess={successHandler}
        />
      ),
      props: {
        width: '400px',
      },
    })
  }

  return userData.map((company, index) => (
    <Container
      style={{ maxWidth: '1152px', padding: '24px', marginTop: '-20px' }}
      key={index}>
      <Accordion
        className="connected-users-accordion"
        defaultExpanded={!index}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <h2 style={{ margin: '24px 0 24px 18px' }}>{company.name}</h2>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer
            style={{ marginTop: '-24px', padding: '0 18px 18px 18px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Naam</TableCell>
                  <TableCell>E-mailadres</TableCell>
                  <TableCell>Bestuurlijk verantwoordelijke</TableCell>
                  <TableCell>Deactiveren</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {company.users.map((user, key) => (
                  <UserRow
                    key={key}
                    {...{
                      user: { ...user, company_id: company.id },
                      key,
                      onUpdate,
                    }}
                  />
                ))}
              </TableBody>
            </Table>
            <Button
              style={{ padding: '.5rem', marginTop: '18px' }}
              variant="outlined"
              endIcon={<Add />}
              onClick={() => handleAddContact(company.id)}>
              Contactpersoon toevoegen
            </Button>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Container>
  ))
}
