import IconButton from '@mui/material/IconButton'
import {
  DeleteSharp,
  MoreVertSharp,
  VisibilitySharp,
} from '@mui/icons-material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { CONTENT } from '@constants'
import ResetDeclarationModal from '@partials/modals/ResetDeclarationModal'
import { modal } from '@store/modal.state'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ListItemIcon } from '@mui/material'

const OptionsMenu = ({ data, style }) => {
  const navigate = useNavigate()
  // MENU
  const [anchorEl, setAnchorEl] = useState('')
  const open = Boolean(anchorEl)
  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = e => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const resetDeclaration = () => {
    const modalBody = {
      heading: 'Herstart rapportage',
      content: (
        <ResetDeclarationModal
          year={data.year}
          company={data.company}
          intake_id={data.intake_id}
        />
      ),
    }

    modal.setOpen(modalBody)
  }

  const viewIntake = () => {
    return navigate(`/intake/${data.intake_id}`)
  }

  return (
    <div style={style}>
      <IconButton
        aria-label="more"
        id="options-button"
        aria-controls={open ? 'options-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertSharp />
      </IconButton>
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'options-button',
        }}>
        <MenuItem
          onClick={resetDeclaration}
          disabled={!data.actions.can_restart_year}>
          <ListItemIcon>
            <DeleteSharp />
          </ListItemIcon>
          {CONTENT.dashboard.buttons.start_over}
        </MenuItem>
        <MenuItem
          onClick={viewIntake}
          disabled={!data.actions.can_view_intake}>
          <ListItemIcon>
            <VisibilitySharp />
          </ListItemIcon>
          {CONTENT.dashboard.buttons.view_intake}
        </MenuItem>
      </Menu>
    </div>
  )
}
OptionsMenu.displayName = 'OptionsMenu'
export default OptionsMenu
