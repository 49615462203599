/* eslint-disable max-len */
import { INTAKE_VERSION_A } from './intake/intake-version-a'
import { INTAKE_VERSION_B } from './intake/intake-version-b'

const intakeForm = {
  a: INTAKE_VERSION_A,
  b: INTAKE_VERSION_B,
}

export default intakeForm
