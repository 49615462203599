const supervisoryBoard = {
  sections: {
    numbers: {
      heading: 'Streefcijfers',
      intro:
        'Hier kunt u uw streefcijfers doorgeven voor het bestuur, de raad van ' +
        'commissarissen en/of de subtop.',
      accordion: {
        heading: 'Lees meer over het formuleren van streefcijfers',
        content: (
          <>
            <p>
              De wet verplicht grote vennootschappen zelf passende en ambitieuze
              streefcijfers te formuleren voor de verhouding tussen vrouwen en
              mannen in het bestuur, de rvc en de subtop
            </p>
            <p>
              Een streefcijfer is een minimumpercentage dat geldt voor vrouwen
              én mannen. Een streefcijfer van 40% m/v voor het bestuur betekent
              dus dat het bestuur voor ten minste 40% uit vrouwen en voor ten
              minste 40% uit mannen moet bestaan.
            </p>
            <p>
              'Passend' wil zeggen dat het streefcijfer afhangt van de omvang
              van het bestuur, de rvc of de subtop én van de huidige
              man-vrouwverhouding. 'Ambitieus' betekent dat het streefcijfer is
              gericht op een evenwichtiger samenstelling dan in de bestaande
              situatie. Het streefcijfer mag niet 0% zijn.
            </p>
            <p>
              Voor het bestuur, de rvc en de subtop stelt u afzonderlijke
              streefcijfers op. Als uw vennootschap een one-tier board heeft,
              stelt u voor uitvoerende en niet-uitvoerende bestuurders
              afzonderlijke streefcijfers op. Naast het streefcijfer geeft u ook
              een streefjaar door, daarmee geeft u aan op welke termijn u het
              streefcijfer beoogt te behalen.
            </p>
            <p>
              Wanneer het bestuur en de rvc beide uit 1 natuurlijk persoon
              bestaan, stelt u een streefcijfer vast voor beide organen
              gezamenlijk. Het streefcijfer is dan 50%.
            </p>
            <p>
              Wanneer na het behalen van het streefcijfer nog ruimte is voor een
              evenwichtiger m/v-verhouding, formuleert u een nieuw streefcijfer
              dat passend en ambitieus is.
            </p>
          </>
        ),
      },
    },
    board: {
      heading: 'Het bestuur',
      subHeading: 'Wat is het streefcijfer voor het bestuur?',
      intro: (
        <div>
          <p>
            Dit streefcijfer geldt voor <strong>alle</strong> besturen van de
            vennootschappen waarover u in deze rapportage rapporteert.
          </p>
          <p>
            In geval van een one-tier board, vult u hier het streefcijfer voor
            de uitvoerende bestuurders in.
          </p>
          <p>
            Een streefcijfer van 30% betekent: ten minste 30% mannen en ten
            minste 30% vrouwen.
          </p>
        </div>
      ),
    },
    rvc: {
      heading: 'De raad van commissarissen',
      subHeading: 'Wat is het streefcijfer voor de raad van commissarissen?',
      intro: (
        <div>
          <p>
            Dit streefcijfer geldt voor <strong>alle</strong> raden van
            commissarissen van de vennootschappen waarover u in deze rapportage
            rapporteert.
          </p>
          <p>
            In geval van een one-tier board, vult u hier het streefcijfer voor
            de niet-uitvoerende bestuurders in.
          </p>
          <p>
            Wanneer uw bedrijf dit boekjaar beursgenoteerd was in Nederland,
            geldt een quotum voor de RvC. U kunt als streefcijfer 33,3%
            doorgeven. Als streefjaar kiest u 2022.
          </p>
          <p>
            Een streefcijfer van 30% betekent: ten minste 30% mannen en ten
            minste 30% vrouwen.
          </p>
        </div>
      ),
    },
    subtop: {
      heading: 'De subtop',
      subHeading: 'Wat is het streefcijfer voor de subtop?',
      intro: (
        <div>
          <p>
            Omdat u hier voor meerdere groepsmaatschappijen gezamenlijk
            rapporteert, kunt u de subtop op het niveau van de groep definiëren.
            Uw streefcijfer voor de subtop geldt voor de groep als geheel en
            niet voor de groepsmaatschappijen afzonderlijk.
          </p>
        </div>
      ),
    },
    subtopDefinition: {
      heading: 'Beschrijf hier hoe u de subtop definieert',
      subHeading: 'Wat is het streefcijfer voor de subtop?',
      intro: (
        <div>
          <p>
            Grote vennootschappen formuleren ook een streefcijfer voor de
            man-vrouwverhouding in 'leidinggevende functies', de zogenoemde
            subtop.
          </p>
          <p>
            U mag zelf definiëren welke functies en werknemers u tot de subtop
            rekent. Medewerkers die gestationeerd zijn in het buitenland mogen
            worden meegeteld als dat past in uw definitie van de subtop.
          </p>
        </div>
      ),
    },
  },
  questions: {
    'code_1.target': {
      label: 'Streefcijfer',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
      inputProps: {
        min: 0,
        max: 50,
        step: 0.01,
      },
    },
    'code_1.year': {
      label: 'Streefjaar',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
      inputProps: {
        min: 2022,
        max: 2050,
      },
    },
    'code_1.exclude': {
      label:
        'N.v.t. Wij hoeven volgens de Wet voor geen van de vennootschappen in ' +
        'deze rapportage een streefcijfer vast te stellen voor het bestuur.',
    },
    'code_2.target': {
      label: 'Streefcijfer',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
      inputProps: {
        min: 0,
        max: 50,
        step: 0.01,
      },
    },
    'code_2.year': {
      label: 'Streefjaar',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
      inputProps: {
        min: 2022,
        max: 2050,
      },
    },
    'code_2.exclude': {
      label:
        'N.v.t. Wij hoeven volgens de Wet voor geen van de vennootschappen in ' +
        'deze rapportage een streefcijfer vast te stellen voor de raad van commissarissen.',
    },
    'code_3.target': {
      label: 'Streefcijfer',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
      inputProps: {
        min: 0,
        max: 50,
        step: 0.01,
      },
    },
    'code_3.year': {
      label: 'Streefjaar',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
      inputProps: {
        min: 2022,
        max: 2050,
      },
    },
    'code_3.exclude': {
      label: 'N.v.t. Wij hebben geen subtop.',
    },
    code_4: {
      label: (
        <>
          Hoe definieert u de <br /> subtop?
        </>
      ),
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    code_5: {
      label: 'Toelichting voor de dataverkenner',
      required: {
        value: false,
      },
    },
  },
}

export default supervisoryBoard
