/* eslint-disable max-len */
export const DASHBOARD = {
  requests: {
    incoming: {
      you_are_my_group_head_and_i_will_take_care_of_my_declaration:
        '{{subsidiary}} heeft aangegeven dat u ({{parent}}) het hoofd was van de groep waar {{subsidiary}} onderdeel van was in {{year}}. {{subsidiary}} wil zelf de rapportage over dit boekjaar verzorgen. \n \n Gaat u hiermee akkoord?',
      you_are_my_group_head_and_you_will_take_care_of_my_declaration:
        '{{subsidiary}} heeft aangegeven dat u ({{parent}}) het hoofd was van de groep waar {{subsidiary}} onderdeel van was in {{year}}. {{subsidiary}} wil dat u namens {{subsidiary}} de rapportage over dit boekjaar verzorgt. \n \n Gaat u hiermee akkoord?',
      you_are_my_subsidiary_and_you_will_take_care_of_your_declaration:
        '{{parent}} heeft aangegeven hoofd te zijn van een groep waar u onderdeel van was in {{year}}. {{parent}} wil dat u zelf de rapportage over dit boekjaar verzorgt. \n \n Gaat u hiermee akkoord?',
      you_are_my_subsidiary_and_i_will_take_care_of_your_declaration:
        '{{parent}} heeft aangegeven hoofd te zijn van een groep waar u onderdeel van was in {{year}}. {{parent}} zal de  rapportageverplichting over dit boekjaar van u overnemen. \n \n Gaat u hiermee akkoord?',
    },
    outgoing: {
      you_are_my_group_head_and_i_will_take_care_of_my_declaration:
        'U ({{subsidiary}}) heeft aangegeven dat {{parent}} het hoofd was van de groep waar u onderdeel van was in {{year}}. Omdat u uw eigen rapportage wil verzorgen over {{year}}, moet u wachten op goedkeuring van {{parent}}. \n \n Duurt het te lang? \n Neem dan contact op met {{parent}} om uw verzoek te beoordelen.',
      you_are_my_group_head_and_you_will_take_care_of_my_declaration:
        'U ({{subsidiary}}) heeft aangeven dat {{parent}} het hoofd was van de groep was waar u onderdeel van was in {{year}} en dat u {{parent}} heeft verzocht de  rapportage over dit boekjaar te verzorgen. Dit verzoek is nog niet beoordeeld door {{parent}}. \n \n Duurt het te lang? \n Neem dan contact op met {{parent}} om uw verzoek te beoordelen.',
      you_are_my_subsidiary_and_you_will_take_care_of_your_declaration:
        'U ({{parent}}) hebt aangegeven hoofd te zijn van een groep, waar {{subsidiary}} onderdeel van was in {{year}}. U hebt {{subsidiary}} gevraagd zelf de rapportage over dit boekjaar te verzorgen. Dit verzoek is nog niet door {{subsidiary}} beoordeeld. \n \n Duurt het te lang? \n Neem dan contact op met {{subsidiary}} om het verzoek te beoordelen. \n\n Heeft een groepsmaatschappij geen acceptatieverzoek ontvangen? Neem dan contact op met de helpdesk van het SER Diversiteitsportaal.',
      you_are_my_subsidiary_and_i_will_take_care_of_your_declaration:
        'U ({{parent}}) hebt aangegeven hoofd te zijn van een groep, waar {{subsidiary}} onderdeel van was in {{year}}. U hebt aangegeven dat u de rapportageverplichting over {{year}} wil overnemen van {{subsidiary}}. Dit verzoek is nog niet door {{subsidiary}} beoordeeld. \n \n Duurt het te lang? \n Neem dan contact op met {{subsidiary}} om het verzoek te beoordelen. \n\n Heeft een groepsmaatschappij geen acceptatieverzoek ontvangen? Neem dan contact op met de helpdesk van het SER Diversiteitsportaal.',
    },
  },
}
