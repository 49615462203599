const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '12px',
    marginBottom: '12px',
  },
  wrapperVertical: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px',
    marginBottom: '12px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minWidth: 'fit-content',
  },
  radioBox: {
    border: '1px solid',
    padding: '6px 12px',
    height: '50px',
  },
  radio: {
    padding: '0px',
    marginRight: '12px',
  },
  lastRadio: {
    padding: '0px',
    marginRight: '12px',
  },
}

export default styles
