import { createFetchHooks } from '@acato/react-fetch'
import { authService } from './service'

const handlers = (resCb = null, errCb = null) => ({
  responseHandler: res => (resCb && resCb(res?.data)) || res?.data,
  errorHandler: ({ errors }) => (errCb && errCb({ errors })) || { errors },
})

export const authHooks = createFetchHooks(
  {
    name: 'useLogin',
    fetcher: authService.login,
    ...handlers(),
  },
  {
    name: 'useForgotPassword',
    fetcher: authService.forgotPassword,
    ...handlers(),
  },
  {
    name: 'useResetPassword',
    fetcher: authService.resetPassword,
    ...handlers(),
  },
  {
    name: 'useCreateAccount',
    fetcher: authService.createAccount,
    ...handlers(),
  },
  {
    name: 'useInvitations',
    fetcher: authService.getInvitation,
    ...handlers(),
  }
)
