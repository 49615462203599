import { declarationHooks } from '@services'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import DownloadIcon from '@mui/icons-material/Download'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'

// MUI
import { Container } from '@mui/material'

// Components
import { Button, Panel } from '@components'
import { toaster } from '@store/toaster.state'
import { setPartialState } from '@store/ui.store'

// Utils
import getDate from '@helpers/dates/getDate'
import resetScrollPosition from '@helpers/view/resetScrollPosition'

// Styles
import { COLORS } from '@constants'
import './style.scss'
import RenderFormComponentPdf from './components/RenderFormComponentPdf'

// New imports for PDF export
import { elementToImage } from '@helpers/pdf-export/element-to-image'
import { imageToPDF } from '@helpers/pdf-export/image-to-pdf'
import { mergePDFFiles } from '@helpers/pdf-export/merge-pdf-files'

// Page height in pixels before we disallow PDF export button due to performance
const PDF_EXPORT_LIMIT = 35000

const PdfExport = () => {
  const printRef = useRef()

  // Hooks
  const { setSectionColor, setRenderClose } = useOutletContext()
  const { useDeclaration } = declarationHooks
  const [fetchDeclaration, , declaration, err] = useDeclaration()
  const { declaration_id } = useParams()
  const navigate = useNavigate()

  // Store
  const setDeclarationStore = setPartialState('declaration')

  // Initial state
  const [showExportButton, setShowExportButton] = useState(false)
  const [generatingPDF, setGeneratingPDF] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [formLoading, setFormLoading] = useState(false)
  const [loadingTimeout, setLoadingTimeout] = useState(true)
  const [status, setStatus] = useState(null)
  const [taxYear, setTaxYear] = useState(null)
  const [year, setYear] = useState(null)
  const [company, setCompany] = useState(null)
  const [preferences, setPreferences] = useState(null)
  const [sections, setSections] = useState([])
  const [currentSubSection, setCurrentSubSection] = useState(null)
  const [currentForm, setCurrentForm] = useState(null)
  const [wasConcept, setWasConcept] = useState(false)
  const [isDefinitive] = useState(false)
  const [version, setVersion] = useState(null)

  const targetSection = useMemo(() => {
    if (!sections?.length) return

    return sections.find(({ slug }) => slug === 'target')
  }, [sections])

  // Reactive
  useEffect(() => {
    setSectionColor(COLORS.background.default)
    setRenderClose(true)
  }, [setSectionColor, setRenderClose])

  useEffect(() => {
    fetchDeclaration(declaration_id)
  }, [declaration_id, fetchDeclaration])

  const onSubmitError = useCallback(errors => toaster.error(errors), [])

  const successHandler = useCallback(
    declaration => {
      const {
        company,
        end_tax_year_at,
        year,
        sections,
        status,
        share_dialogs,
        share_profile,
        receive_info,
        receive_tips,
        declaration_version,
      } = declaration.data

      if (!sections) return
      if (status === 'finished' && wasConcept)
        return navigate('/declaration/success/' + declaration_id)
      if (status !== 'finished') setWasConcept(true)

      setDeclarationStore({ declaration: declaration.data })

      const tax_year = end_tax_year_at
        ? getDate(end_tax_year_at).pretty
        : `31 December ${declaration.data.year}`
      const openSections = sections.filter(section => !section.completed)
      const openSubSections =
        openSections?.[0]?.sections?.filter?.(section => !section.completed) ||
        []
      const section = openSubSections[0] || openSections[0] || sections.at(-1)
      const openForms = section.forms.filter(
        form => !form.completed && !form.skip
      )
      const form = openForms[0] || section.forms.at(-1)

      setVersion(declaration_version)
      setStatus(status)
      setPreferences({
        share_dialogs,
        share_profile,
        receive_info,
        receive_tips,
      })
      setTaxYear(tax_year)
      setYear(year)
      setCompany(company)
      setSections(sections)
      setCurrentSubSection(openSubSections?.[0] || null)
      handleFormNavigation({ section, form })
      setPageLoading(false)
      setFormLoading(false)

      setTimeout(() => {
        setLoadingTimeout(false)
        if (printRef?.current?.clientHeight < PDF_EXPORT_LIMIT) {
          setShowExportButton(true)
        }
      }, 4000)
    },
    [declaration_id]
  )

  useEffect(() => {
    if (err?.errors) {
      toaster.error(err.errors)
    }
    declaration && successHandler(declaration)
  }, [declaration, err, successHandler])

  // Helper function to calculate endpoint for each form
  const calculateEndpoint = form => {
    const companyEndpoint = '/api/company-portal'
    return form.self.substring(
      form.self.indexOf(companyEndpoint) + companyEndpoint.length
    )
  }

  // Methods - Form Navigation
  const handleFormNavigation = ({ section, form }) => {
    if (typeof section === 'string') {
      section = declaration.data.sections.find(
        _section => _section.slug === section
      )
    }
    if (typeof form === 'string') {
      form = section.forms.find(_form => _form.slug === form)
    }
    if (typeof form === 'number') {
      const currentIndex = section.forms.findIndex(
        form => form.id === currentForm.id
      )
      form = section.forms[currentIndex + form]
    }
    if (section?.subSection) {
      setCurrentSubSection(section.subSection)
    }

    setCurrentForm(form)
  }

  const renderFormSections = async () => {
    const printContainer = printRef.current
    const printForms = printContainer.querySelectorAll('.form-component')

    const promises = []
    printForms.forEach(form => {
      promises.push(elementToImage(form))
    })

    const pageScreenshots = await Promise.allSettled(promises)

    const pdfForms = []
    pageScreenshots.forEach(result => {
      const pdfSection = imageToPDF(result.value)
      if (pdfSection) {
        pdfForms.push(pdfSection)
      }
    })
    return pdfForms
  }

  // Function to handle PDF export
  const renderPDF = async () => {
    setGeneratingPDF(true)

    try {
      const pageScreenshots = await renderFormSections()
      const file = await mergePDFFiles(pageScreenshots)
      const blob = new Blob([file], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'diversiteitsportaal_rapportage.pdf'
      link.click()
    } catch (error) {
      toaster.error(error.message)
    } finally {
      setGeneratingPDF(false)
    }
  }

  const printPDF = () => {
    window?.print()
  }

  return (
    <div className="pdf-export">
      <Container>
        <Panel>
          <div className="pdf-export__header">
            {showExportButton && (
              <Button
                variant="contained"
                loading={formLoading || loadingTimeout || generatingPDF}
                onClick={renderPDF}
                startIcon={<DownloadIcon />}>
                Exporteren naar PDF
              </Button>
            )}
            <Button
              variant="outlined"
              loading={formLoading || loadingTimeout || generatingPDF}
              onClick={printPDF}
              startIcon={<LocalPrintshopIcon />}>
              Rapportage afdrukken
            </Button>
          </div>
        </Panel>
        <hr />
        {!formLoading && !pageLoading && (
          <>
            <Panel>
              <div ref={printRef}>
                <div className="form-component">
                  <div className="pdf-export__cover">
                    <h2>Rapportage {year}</h2>
                    <h1>{company.name}</h1>
                    <p>KVK Nummer: {company.kvk_number}</p>
                    <hr />
                    <p>Sociaal Economische Raad | Diversiteitsportaal</p>
                  </div>
                </div>
                {sections?.map(section => (
                  <div key={section.slug}>
                    <h1>{section.name}</h1>
                    {section.sections.map(nestedSection => {
                      return nestedSection.forms.map((form, i) => {
                        return (
                          <div
                            key={i}
                            className="form-component">
                            <RenderFormComponentPdf
                              key={`${nestedSection.slug}-${form.slug}`}
                              formId={`${nestedSection.slug}-${form.slug}`}
                              endpoint={calculateEndpoint(form)}
                              company={{ name: nestedSection.name }}
                              preferences={preferences}
                              year={year}
                              taxYear={taxYear}
                              section={nestedSection.slug}
                              targetSection={targetSection}
                              currentSection={nestedSection}
                              currentForm={form}
                              currentSubSection={currentSubSection}
                              onSubmitSuccess={successHandler}
                              onSubmitError={onSubmitError}
                              onFormLoaded={resetScrollPosition}
                              declarationCompleted={status === 'finished'}
                              isDefinitive={isDefinitive}
                              version={version}
                              style={'margin-bottom: 20px;'}
                            />
                          </div>
                        )
                      })
                    })}
                    {section?.forms?.map((form, i) => {
                      return (
                        <div
                          key={i}
                          className="form-component">
                          <RenderFormComponentPdf
                            key={`${section.slug}-${form.slug}`}
                            formId={`${section.slug}-${form.slug}`}
                            endpoint={calculateEndpoint(form)}
                            company={company}
                            preferences={preferences}
                            year={year}
                            taxYear={taxYear}
                            section={section.slug}
                            targetSection={targetSection}
                            currentSection={section}
                            currentForm={form}
                            currentSubSection={currentSubSection}
                            onSubmitSuccess={successHandler}
                            onSubmitError={onSubmitError}
                            onFormLoaded={resetScrollPosition}
                            declarationCompleted={status === 'finished'}
                            isDefinitive={isDefinitive}
                            version={version}
                            style={'margin-bottom: 20px;'}
                          />
                        </div>
                      )
                    })}
                  </div>
                ))}
              </div>
            </Panel>
          </>
        )}
      </Container>
    </div>
  )
}

export default PdfExport
