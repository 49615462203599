import styles from './toaster.message.module.css'

const ToasterMessage = ({ messages }) =>
  (Array.isArray(messages) && (
    <ul className={styles['toaster-message']}>
      {messages.map((msg, i) => (
        <li key={i}>{msg}</li>
      ))}
    </ul>
  )) ||
  messages

export default ToasterMessage
