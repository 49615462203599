const canvasPlan = {
  sections: {
    history_summary: {
      heading: 'Plan van aanpak genderdiversiteit in de top en subtop',
      sub_heading:
        'Disclaimer: aan dit plan van aanpak kunnen geen rechten worden ontleend.',
      table: {
        col_head: [
          'Streefcijfers voor de man-vrouwverhouding',
          'Streefjaar',
          'Streefcijfer',
        ],
        row_head: ['Bestuur', 'Raad van commissarissen', 'Subtop'],
      },
    },
    target_1: {
      heading: 'Hoe gaat u uw streefcijfers realiseren?',
      col_1: {
        subheading: 'In het bestuur',
        answer_field: 'code_1',
        statement_label: 'Vul hier een inspirerend statement in',
        statement_field: 'body',
        activities_heading: 'Beleid en maatregelen',
        activities_button: 'Selecteer maatregelen',
        activities_field: 'activities',
        library: 'directors',
      },
      col_2: {
        isRvC: true,
        subheading: 'In de raad van commissarissen',
        answer_field: 'code_2',
        statement_label: 'Vul hier een inspirerend statement in',
        statement_field: 'body',
        activities_heading: 'Beleid en maatregelen',
        activities_button: 'Selecteer maatregelen',
        activities_field: 'activities',
        library: 'directors',
      },
    },
    target_2: {
      subheading: 'Subtop & organisatie',
      col_1: {
        answer_field: 'code_3',
        activities_heading: 'Visie & leiderschap',
        activities_button: 'Selecteer maatregelen',
        activities_field: 'activities',
        library: 'subtop',
      },
      col_2: {
        answer_field: 'code_4',
        activities_heading: 'Management & organisatie',
        activities_button: 'Selecteer maatregelen',
        activities_field: 'activities',
        library: 'subtop',
      },
      col_3: {
        answer_field: 'code_5',
        activities_heading: 'Inclusieve bedrijfscultuur',
        activities_button: 'Selecteer maatregelen',
        activities_field: 'activities',
        library: 'subtop',
      },
    },
    target_3: {
      col_1: {
        answer_field: 'code_6',
        subheading: 'Toelichting voor de dataverkenner',
        statement_label:
          'Voeg een openbare toelichting toe over uw plan van aanpak',
        statement_field: 'body',
      },
      col_2: {
        answer_field: 'code_7',
        statement_field: 'body',
        subheading: 'Toelichting voor de SER',
        statement_label: 'Voeg een toelichting toe voor de SER (niet openbaar)',
      },
    },
    navigation: {
      submit_button: 'Volgende',
    },
    focus_explainer: {
      heading: 'Maatregelen waarderen',
      success:
        'Selecteer 3 maatregelen die het meest succesvol waren het afgelopen jaar',
      attention:
        'Selecteer 1 maatregel die meer aandacht nodig heeft het komende jaar',
    },
  },
}

export default canvasPlan
