// MUI
import { Container } from '@mui/material'

// Components
import { Panel } from '@components'
import { Button } from '@components'

const FormNotFound = () => {
  return (
    <Container>
      <Panel>
        <div>
          <h2>Helaas, wij hebben dit formulier niet kunnen vinden.</h2>
          <Button
            variant="contained"
            to="/dashboard">
            Terug naar het dashboard
          </Button>
        </div>
      </Panel>
    </Container>
  )
}

export default FormNotFound
