// React
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { CreateAccountForm } from '@partials/forms/authentication/create.account.form'
import { toaster } from '@store/toaster.state'

export const CreateAccountView = () => {
  // Hooks
  const navigate = useNavigate()

  const token = location.hash.substring(1)

  // API success & error
  const successHandler = useCallback(
    res => {
      const { message } = res
      toaster.success(message)
      navigate('/invite/success')
    },
    [navigate]
  )

  const errorHandler = useCallback(({ errors }) => {
    if (!errors?.forEach) return toaster.error('Er is een fout opgetreden')

    toaster.error(errors.map?.(err => Object.values(err)[0]) || errors)
  }, [])

  return (
    <main>
      <CreateAccountForm
        token={token}
        onSuccess={successHandler}
        onError={errorHandler}
      />
    </main>
  )
}
