import { DASHBOARD } from '@constants'
import interpolate from '@helpers/strings/interpolate'

// Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

const renderRequestChip = (request, company, parent, year) => {
  let message = ''
  if (!request) return null

  const incoming = request.incoming_request ? 'incoming' : 'outgoing'
  const requestType = request.type.replace(/-/g, '_')
  message = interpolate(
    DASHBOARD.requests[incoming][requestType],
    {
      parent: parent?.name,
      subsidiary: company?.name,
      year,
    },
    {
      bold: true,
    }
  )

  let text = ''
  let severity = 'success'
  let icon = null
  let variant = 'outlined'

  switch (request.type) {
    case 'you-are-my-subsidiary-and-i-will-take-care-of-your-declaration':
      if (request.status === 'open') {
        text = `Verzoek - ${parent?.name}`
      } else {
        text = 'U rapporteert'
      }
      break

    case 'you-are-my-subsidiary-and-you-will-take-care-of-your-declaration':
      if (request.status === 'open') {
        text = `Verzoek - ${company?.name}`
      } else {
        text = `${company?.name} rapporteert zelf`
      }

      break

    case 'you-are-my-group-head-and-you-will-take-care-of-my-declaration':
      if (request.status === 'open') {
        text = `Verzoek - ${parent?.name}`
      } else {
        text = `U rapporteert`
      }

      break

    case 'you-are-my-group-head-and-i-will-take-care-of-my-declaration':
      if (request.status === 'open') {
        text = `Verzoek - ${company.name}`
      } else {
        text = `${company.name} doet rapportage`
      }

      break

    default:
      break
  }

  if (request.status === 'open') {
    severity = 'info'
    icon = <InfoOutlinedIcon />
    variant = 'filled'
  } else {
    severity = 'primary'
    icon = <TaskAltIcon />
    variant = 'outlined'
  }

  return {
    message,
    text,
    severity,
    icon,
    variant,
  }
}

export default renderRequestChip
