import { ChangeEvent } from 'react'
import { TextField, InputLabel, Alert } from '@mui/material'
import { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { COLORS } from '@constants'

interface Props {
  variant?: 'filled' | 'outlined' | 'standard'
  labelType?: 'fixed' | 'floating'
  label?: string
  required?: boolean
  color?: 'success' | 'info' | 'warning' | 'error' | 'primary'
  placeholder?: string
  disabled?: boolean
  alert?: {
    severity?: 'success' | 'info' | 'warning' | 'error'
    message: string
  }
  value?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  rest?: any
}

const TextArea: React.FC<Props> = forwardRef(
  (
    {
      variant,
      labelType = 'fixed',
      label,
      color = 'primary',
      placeholder,
      required,
      alert,
      ...rest
    },
    ref
  ) => {
    if (alert?.message && !alert.severity) {
      alert.severity = 'error'
    }

    if (alert?.severity) {
      color = alert.severity
    }

    const ctx: any = useFormContext()

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {label && labelType === 'fixed' && <InputLabel>{label}</InputLabel>}
        {ctx?.isPDFExport ? (
          <div className="pdf-export__input">{rest?.value}</div>
        ) : (
          <TextField
            multiline
            minRows={2}
            maxRows={6}
            inputRef={ref}
            variant={variant}
            label={labelType === 'floating' ? label : ''}
            placeholder={ctx?.isPDFExport ? '' : placeholder}
            required={required}
            color={color}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:not(.Mui-disabled)': {
                  backgroundColor: 'rgb(255, 255, 255)',
                },
                '& > fieldset': {
                  borderColor: alert?.message
                    ? COLORS[color].contrastText
                    : COLORS.gray.main,
                },
              },
            }}
            {...rest}
          />
        )}
        {alert?.message && (
          <Alert
            severity={alert.severity ? alert.severity : 'error'}
            sx={{ marginTop: '6px', marginBottom: '6px' }}>
            {alert.message}
          </Alert>
        )}
      </div>
    )
  }
)
TextArea.displayName = 'TextArea'
export default TextArea
