// Components
import { ExplainerScreen } from '@components'

export const GenderIdentityView = () => {
  const title = 'Genderidentiteit en privacy'
  const content = (
    <>
      <p>
        <strong>
          De wet 'Evenwichtiger verhouding van zetels tussen mannen en vrouwen
          in het bestuur en de raden van commissarissen van grote naamloze en
          besloten vennootschappen' kent een binaire benadering van geslacht,
          terwijl een ruimere benadering van gender en genderidentiteiten
          mogelijk is. In de wet is gekozen voor een binaire benadering omdat
          dit aansluit bij het probleem dat het kabinet met deze wet wil
          aanpakken, namelijk de onevenwichtige man-vrouwverhouding in de top en
          subtop van bedrijven.
        </strong>
      </p>
      <p>
        Wanneer u rapporteert over bestuurslagen met daarin personen die zich
        niet thuis voelen binnen de klassieke man-vrouwhokjes, kunt u dit melden
        in de rapportage. U mag deze personen in de rapportage meetellen bij het
        ondervertegenwoordigde geslacht.
      </p>
      <p>
        Een voorbeeld: Een grote vennootschap heeft een streefcijfer van 33% m/v
        voor een RvC met drie zetels, waarvan twee zetels al door mannen worden
        bezet. Wanneer een nieuwe commissaris wordt benoemd die zich
        identificeert als non-binair, dan kan deze worden meegeteld bij het
        ondervertegenwoordigde geslacht. De benoeming draagt bij aan het
        evenwichtiger maken van de man-vrouwverhouding in de RvC en de
        vennootschap voldoet aan het streefcijfer van 33%.
      </p>
      <p>
        Bedrijven mogen niet zomaar de genderidentiteit van hun bestuurders,
        commissarissen, en werknemers opvragen. Zij hebben uitsluitend toegang
        tot geslachtsgegevens zoals die op identiteitsbewijzen vermeld staan.
        Dus alleen wanneer bestuurders, commissarissen en werknemers zelf en
        vrijwillig hun genderidentiteit willen vertellen, kun u hiermee rekening
        houden bij tellingen, streefcijfers en plannen van aanpak. Gelet op de
        privacy kan het onwenselijk zijn wanneer dit soort gegevens te herleiden
        zijn tot bepaalde personen. Daarom zal de SER niet openbaar maken
        wanneer u in de rapportage hebt gemeld dat u rekening hebt gehouden met
        personen die zich niet thuis voelen in de klassieke man-vrouwhokjes.
      </p>
    </>
  )
  return (
    <main>
      <ExplainerScreen
        title={title}
        content={content}
      />
    </main>
  )
}
