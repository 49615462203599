export const DECLARATION_VERSION_B_TARGET_DIRECTORS = {
  sections: {
    target: {
      heading: 'Streefcijfer Bestuur',
      subHeading: 'Wat is het streefcijfer voor het bestuur?',
      intro: (
        <>
          <p>Hier kunt u uw streefcijfer doorgeven voor het bestuur.</p>
          <p>
            Dit streefcijfer geldt voor <strong>alle</strong> besturen van de
            vennootschappen waarover u in deze rapportage rapporteert.
          </p>
          <p>
            Als u een one-tier board heeft, vult u hier het streefcijfer voor de
            uitvoerende bestuurders in.
          </p>
          <p>
            Een streefcijfer van 30% betekent: ten minste 30% mannen en ten
            minste 30% vrouwen.
          </p>
        </>
      ),
      accordion: {
        heading: 'Lees meer over het formuleren van streefcijfers',
        content: (
          <>
            <p>
              De wet verplicht grote vennootschappen zelf passende en ambitieuze
              streefcijfers te formuleren voor de verhouding tussen vrouwen en
              mannen in het bestuur, de rvc en de subtop
            </p>
            <p>
              Een streefcijfer is een minimumpercentage dat geldt voor vrouwen
              én mannen. Een streefcijfer van 40% m/v voor het bestuur betekent
              dus dat het bestuur voor ten minste 40% uit vrouwen en voor ten
              minste 40% uit mannen moet bestaan.
            </p>
            <p>
              'Passend' wil zeggen dat het streefcijfer afhangt van de omvang
              van het bestuur, de rvc of de subtop én van de huidige
              man-vrouwverhouding. 'Ambitieus' betekent dat het streefcijfer is
              gericht op een evenwichtiger samenstelling dan in de bestaande
              situatie. Het streefcijfer mag niet 0% zijn.
            </p>
            <p>
              Voor het bestuur, de rvc en de subtop stelt u afzonderlijke
              streefcijfers op. Als uw vennootschap een one-tier board heeft,
              stelt u voor uitvoerende en niet-uitvoerende bestuurders
              afzonderlijke streefcijfers op. Naast het streefcijfer geeft u ook
              een streefjaar door, daarmee geeft u aan op welke termijn u het
              streefcijfer beoogt te behalen.
            </p>
            <p>
              Wanneer het bestuur en de rvc beide uit 1 natuurlijk persoon
              bestaan, stelt u een streefcijfer vast voor beide organen
              gezamenlijk. Het streefcijfer is dan 50%.
            </p>
            <p>
              Wanneer na het behalen van het streefcijfer nog ruimte is voor een
              evenwichtiger m/v-verhouding, formuleert u een nieuw streefcijfer
              dat passend en ambitieus is.
            </p>
          </>
        ),
      },
    },
    explanation: {
      heading: 'Toelichting streefcijfer bestuur',
    },
  },
  questions: {
    //  code_1 is handled by DeclarationTargetNumbers
    code_2: {
      name: 'code_2',
      type: 'textarea',
      label:
        'Wilt u een toelichting geven bij uw streefcijfer voor het bestuur?',
    },
  },
}
