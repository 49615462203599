/* eslint-disable max-len */
export const LABELS = {
  TARGET_NUMBER: 'Streefcijfer (%)',
  TARGET_NUMBER_TOOLTIP:
    'Dit streefcijfer heeft u in een eerdere rapportage doorgegeven en is hier vooraf ingevuld',
  TARGET_YEAR: 'Streefjaar',
  TARGET_YEAR_TOOLTIP:
    'Dit streefjaar heeft u in een eerdere rapportage doorgegeven en is hier vooraf ingevuld',
  TARGET_RENEW: 'Wij hebben een nieuw streefcijfer/streefjaar vastgesteld.',
  TARGET_EXCLUDE: department => {
    if (department === 'de subtop')
      return 'N.v.t. wij hebben (nog) geen streefcijfer vastgesteld voor de subtop.'
    return `N.v.t. wij hebben (nog) geen streefcijfer vastgesteld voor ${department}.`
  },
  TARGET_EXCLUDE_REASON:
    'Geef in de toelichting hieronder duidelijk aan wat hiervan de reden is.',
  NEW_TARGET_NUMBER: 'Nieuw streefcijfer',
}

export const DEPARTMENTS = {
  directors: 'het bestuur',
  supervisory: 'de raad van commissarissen',
  subtop: 'de subtop',
}

export const MODAL_CONTENT = {
  HEADING: department =>
    `Wanneer hoeft u geen streefcijfer vast te stellen voor ${department}?`,
  directors: (
    <>
      <p>
        Een streefcijfer voor evenwichtige samenstelling van het bestuur is niet
        mogelijk bij
      </p>
      <ul>
        <li>een bestuur bestaande uit één persoon.</li>
        <li>
          een bestuur dat niet uit natuurlijke personen bestaat
          (rechtspersoon-bestuurder).
        </li>
      </ul>
      <p>
        Wanneer dat geldt voor <strong>alle besturen</strong> die in deze
        rapportage zijn betrokken, hoeft u geen streefcijfer vast te stellen
        voor het bestuur en uitvoerende bestuurders.
      </p>
      <p>
        <strong>Uitzondering</strong>: Wanneer van een vennootschap in deze
        rapportage zowel het bestuur als de RvC uit één persoon bestaan, stelt u
        een streefcijfer vast voor beide organen gezamenlijk. Dit streefcijfer
        is altijd 50%.
      </p>
    </>
  ),
  supervisory: (
    <>
      <p>
        Een streefcijfer voor evenwichtige samenstelling van de RvC is niet
        mogelijk wanneer:
      </p>
      <ul>
        <li>de RvC uit één persoon bestaat.</li>
        <li>er geen RvC's en niet-uitvoerende bestuurders zijn.</li>
      </ul>
      <p>
        Wanneer dat geldt voor alle RvC's die in deze rapportage zijn betrokken,
        hoeft u geen streefcijfer vast te stellen voor de RvC en
        niet-uitvoerende bestuurders.
      </p>
      <p>
        <strong>Uitzondering</strong>: Wanneer van een vennootschap in deze
        rapportage zowel het bestuur als de RvC uit één persoon bestaan, stelt u
        een streefcijfer vast voor beide organen gezamenlijk. Dit streefcijfer
        is altijd 50%.
      </p>
      <p>
        <strong>Quotum</strong>: Wanneer uw bedrijf dit boekjaar beursgenoteerd
        was in Nederland, geldt een quotum voor de RvC. U kunt als streefcijfer
        33,3% doorgeven. Als streefjaar kiest u 2022.
      </p>
    </>
  ),
  subtop: (
    <>
      <p>
        Een streefcijfer voor evenwichtige samenstelling van de subtop is niet
        mogelijk wanneer:
      </p>
      <ul>
        <li>de subtop uit één persoon bestaat.</li>
        <li>er geen medewerkers met leidinggevende functies zijn.</li>
      </ul>
    </>
  ),
  CONFIRM: 'Dat klopt',
  CANCEL: 'Toch een streefcijfer doorgeven',
}
