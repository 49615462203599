import { Box } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { modal } from '@store/modal.state'
import {
  DEPARTMENTS,
  LABELS,
  MODAL_CONTENT,
} from './DeclarationTargetNumbers.constants'
import { DeclarationTargetNumbersNewTarget } from './DeclarationTargetNumbersNewTarget'
import { DeclarationTargetNumbersFirstSubmit } from './DeclarationTargetNumbersFirstSubmit'
import { DeclarationTargetNumbersPreviousYear } from './DeclarationTargetNumbersPreviousYear'

const DeclarationTargetNumbers = ({
  name,
  disabled,
  department,
  initialExcluded,
  isPrefilled = true,
}) => {
  const { watch, setValue } = useFormContext()

  const setNewTargets = watch(name + '.overwrite_targets_v2')
  const exclude = watch(name + '.excluded_v2')
  const reported_last_year = !!watch(name + '.previous_year_v2')

  const department_name = useMemo(() => DEPARTMENTS[department], [department])

  const showExcludeInfoModal = () => {
    if (initialExcluded) return

    modal.setOpen({
      customHeading: (
        <h2 style={{ color: 'black', margin: '16px 24px' }}>
          {MODAL_CONTENT.HEADING(department_name)}
        </h2>
      ),
      content: MODAL_CONTENT[department],
      hideClose: true,
      props: { width: '400px' },
      buttons: [
        {
          id: 1,
          text: MODAL_CONTENT.CONFIRM,
          variant: 'contained',
          color: 'primary',
          style: { margin: '16px' },
          onClick: () => {
            if (!reported_last_year) {
              setValue(name + '.target_v2', null)
              setValue(name + '.year_v2', null)
            }
            if (reported_last_year) {
              setValue(name + '.new_target_v2', null)
              setValue(name + '.new_year_v2', null)
            }
            modal.setClose()
          },
        },
        {
          id: 2,
          text: MODAL_CONTENT.CANCEL,
          variant: 'outlined',
          color: 'primary',
          style: { margin: '16px' },
          onClick: () => {
            setValue(name + '.excluded_v2', false)
            modal.setClose()
          },
        },
      ],
    })
  }

  useEffect(() => {
    if (exclude === null) {
      setValue(name + '.excluded_v2', false)
    }
    // if (isPrefilled && exclude) {
    //   if (!reported_last_year) {
    //     setValue(name + '.target_v2', null)
    //     setValue(name + '.year_v2', null)
    //   }
    //   if (reported_last_year && exclude) {
    //     setValue(name + '.new_target_v2', null)
    //     setValue(name + '.new_year_v2', null)
    //   }
    // }
    exclude && showExcludeInfoModal()
  }, [exclude])

  useEffect(() => {
    if (setNewTargets || !reported_last_year) return
    setValue(name + '.new_target_v2', null)
    setValue(name + '.new_year_v2', null)
    setValue(name + '.excluded_v2', false)
  }, [setNewTargets])

  const renderTargetNumbers = useMemo(() => {
    if (reported_last_year) {
      return (
        <DeclarationTargetNumbersPreviousYear
          name={name}
          disabled={disabled}
          isPrefilled={isPrefilled}
        />
      )
    } else {
      return (
        <DeclarationTargetNumbersFirstSubmit
          name={name}
          disabled={disabled}
          exclude={exclude}
          department_name={department_name}
          minmax={{
            target: {
              min: 0,
              max: 50,
            },
            year: {
              min: 2023,
              max: 2050,
            },
          }}
        />
      )
    }
  }, [reported_last_year, disabled, exclude, department_name])

  return (
    <>
      {renderTargetNumbers}
      {setNewTargets && (
        <Box
          bgcolor="secondary.main"
          padding={2}
          paddingY={1}>
          <h3>{LABELS.NEW_TARGET_NUMBER}</h3>
          <DeclarationTargetNumbersNewTarget
            name={name}
            disabled={disabled}
            exclude={exclude}
            department_name={department_name}
            minmax={{
              target: {
                min: 0,
                max: 50,
              },
              year: {
                min: 2023,
                max: 2050,
              },
            }}
          />
        </Box>
      )}
    </>
  )
}

export default DeclarationTargetNumbers
