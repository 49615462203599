const renderPDF = (file, filename) => {
  const pdf = new Blob([file], { type: 'application/pdf' })
  const link = document.createElement('a')
  const data = window.URL.createObjectURL(pdf)
  link.href = data
  link.download = filename
  link.click()
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data)
    link.remove()
  }, 100)
}

export default renderPDF
