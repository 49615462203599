import { profileResource } from './resource'

export const profileService = {
  changeName: ({ user_id, name }, signal) =>
    profileResource.patch(`/users/${user_id}`, { name }, { signal: signal() }),
  changePassword: (
    { current_password, password, password_confirmation },
    signal
  ) =>
    profileResource.patch(
      '/profile/change-password',
      { current_password, password, password_confirmation },
      { signal: signal() }
    ),
  switchCompany: (id, signal) =>
    profileResource.patch(
      '/profile/switch-company',
      { company_id: id },
      { signal: signal() }
    ),
  claimCompany: (token, signal) =>
    profileResource.post(
      '/profile/claim-company',
      { token },
      { signal: signal() }
    ),
  listConnectedUsers: (_, signal) =>
    profileResource.get('/users', { signal: signal() }),
  updatePreferences: ({ user_id, body }, signal) =>
    profileResource.patch(`/users/${user_id}/preferences`, body, {
      signal: signal(),
    }),
  deactivateUser: ({ company_id, user_id }, signal) =>
    profileResource.delete(`/users/${user_id}`, {
      params: { company_id },
      signal: signal(),
    }),
  addContact: (
    { first_name, last_name, email, company_id, administrative },
    signal
  ) =>
    profileResource.post(
      '/users',
      { first_name, last_name, email, company_id, administrative },
      { signal: signal() }
    ),
}
