/* eslint-disable max-len */
import { createTheme } from '@mui/material/styles'
import { nlNL } from '@mui/material/locale'
import { COLORS } from '@constants'

// Fonts
import DaxRegular from '@assets/fonts/DaxOT/DaxOT-Regular.woff2'
import DaxMedium from '@assets/fonts/DaxOT/DaxOT-Medium.woff2'
import DaxBold from '@assets/fonts/DaxOT/DaxOT-Bold.woff2'

const theme = createTheme({
  nlNL,
  palette: {
    ...COLORS,
  },
  typography: {
    letterSpacing: 0.8,
    fontSize: 16,
    fontFamily: `'Dax', serif;`,
    h1: {
      fontSize: 36,
      lineHeight: 1.2,
      fontFamily: `'Dax', serif;`,
      fontWeight: 500,
      color: COLORS.black.main,
    },
    h2: {
      fontSize: 25,
      lineHeight: 1.25,
      fontFamily: `'Dax', serif;`,
      fontWeight: 500,
      color: COLORS.black.main,
    },
    h3: {
      fontSize: 20,
      lineHeight: 1.33,
      fontFamily: `'Dax', serif;`,
      fontWeight: 500,
      color: COLORS.black.main,
    },
    body: {
      fontSize: 18,
      lineHeight: 1.1,
      fontFamily: `'Dax', serif;`,
      fontWeight: 400,
    },
    label: {
      fontSize: 28,
      lineHeight: 36,
      fontWeight: 500,
      fontFamily: `'Dax', serif;`,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Dax';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: url(${DaxBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Dax';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: url(${DaxMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Dax';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${DaxRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        `,
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: 80,
        },
        colorPrimary: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          width: '100%',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: '48px',
          marginBottom: '48px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontWeight: 'lighter',
          padding: '10px 24px',
          boxShadow: 'none',
          fontSize: 16,
          textTransform: 'none',
          '&& .MuiSvgIcon-root': {
            transition: 'transform 0.2s ease',
          },
          '&:hover': {
            boxShadow: 'none',
            '& .MuiButton-startIcon': {
              '& .MuiSvgIcon-root': {
                transform: 'translateX(-4px)',
              },
            },
            '& .MuiButton-endIcon': {
              '& .MuiSvgIcon-root': {
                transform: 'translateX(4px)',
              },
            },
          },
        },
        containedPrimary: {
          backgroundColor: COLORS.primary.main,
          color: COLORS.primary.contrastText,
          '&:hover': {
            backgroundColor: COLORS.secondary.main,
            color: COLORS.secondary.contrastText,
            '&& .MuiSvgIcon-root': {
              color: COLORS.secondary.contrastText,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: COLORS.disabled.main,
          },
        },
        containedSecondary: {
          backgroundColor: COLORS.secondary.main,
          color: COLORS.secondary.contrastText,
          '&:hover': {
            backgroundColor: COLORS.primary.main,
            color: COLORS.primary.contrastText,
            '&& .MuiSvgIcon-root': {
              color: COLORS.primary.contrastText,
            },
          },
        },
        outlinedPrimary: {
          borderColor: COLORS.primary.main,
          color: COLORS.primary.main,
          '&:hover': {
            backgroundColor: COLORS.primary.main,
            color: COLORS.primary.contrastText,
            '&& .MuiSvgIcon-root': {
              color: COLORS.primary.contrastText,
            },
          },
        },
        outlinedSecondary: {
          borderColor: COLORS.secondary.main,
          color: COLORS.primary.main,
          '&:hover': {
            backgroundColor: COLORS.secondary.main,
            color: COLORS.secondary.contrastText,
            '&& .MuiSvgIcon-root': {
              color: COLORS.secondary.contrastText,
            },
          },
        },
        textPrimary: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          color: COLORS.primary.main,
        },
        textSecondary: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          color: COLORS.primary.main,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '.MuiAlertTitle-root': {
            fontSize: 18,
            fontWeight: 400,
          },
        },
        standardError: {
          backgroundColor: COLORS.error.main,
          color: COLORS.black.main,
          '& .MuiAlert-icon': {
            color: COLORS.white.contrastText,
          },
          '.MuiAlert-icon': {
            color: COLORS.error.contrastText,
            alignSelf: 'center',
          },
        },
        standardInfo: {
          backgroundColor: COLORS.info.main,
          color: COLORS.black.main,
          '.MuiAlert-icon': {
            color: COLORS.info.contrastText,
            alignSelf: 'center',
          },
        },
        standardSuccess: {
          backgroundColor: COLORS.success.main,
          color: COLORS.white.main,
          '.MuiAlert-icon': {
            color: COLORS.white.main,
            alignSelf: 'center',
          },
        },
        standardWarning: {
          backgroundColor: COLORS.warning.main,
          color: COLORS.black.main,
          '.MuiAlert-icon': {
            color: COLORS.warning.contrastText,
            alignSelf: 'center',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ':hover': {
            cursor: 'pointer',
          },
          '& .MuiChip-label': {
            fontWeight: 500,
            fontSize: 14,
            '> span': {
              fontWeight: 500,
              fontSize: 14,
            },
            '& .MuiSvgIcon-root': {
              marginLeft: '0px !important',
              fontSize: 24,
              alignSelf: 'center',
            },
          },
          // DEFAULT
          '&.MuiChip-colorDefault': {
            color: COLORS.text.main,
            '&:hover': {
              color: COLORS.text.main,
              '.MuiChip-deleteIcon': {
                color: COLORS.text.main,
              },
            },
            '.MuiChip-deleteIcon': {
              color: COLORS.text.main,
            },
          },
          // PRIMARY
          '&.MuiChip-outlinedPrimary': {
            color: COLORS.primary.main,
            borderColor: COLORS.disabled.main,
            '&:hover': {
              color: COLORS.primary.main,
            },
            '.MuiChip-deleteIcon': {
              color: COLORS.primary.main,
            },
          },
          // SUCCESS
          '&.MuiChip-containedSuccess': {
            color: COLORS.success.main,
            borderColor: COLORS.success.main,
            '&:hover': {
              color: COLORS.success.main,
              backgroundColor: COLORS.success.light,
              borderColor: COLORS.success.light,
            },
          },
          '&.MuiChip-outlinedSuccess': {
            color: COLORS.success.main,
            borderColor: COLORS.success.main,
            '&:hover': {
              backgroundColor: COLORS.success.light,
              borderColor: COLORS.success.light,
            },
            '&.MuiChip-clickable': {
              '&:hover': {
                backgroundColor: COLORS.success.light,
                borderColor: COLORS.success.light,
              },
            },
            '.MuiChip-deleteIcon': {
              color: COLORS.success.main,
            },
          },
          '&.MuiChip-filledSuccess': {
            backgroundColor: COLORS.success.main,
          },
          // INFO
          '&.MuiChip-outlinedInfo': {
            color: COLORS.info.contrastText,
            borderColor: COLORS.info.main,
            '&:hover': {
              backgroundColor: COLORS.info.main,
              borderColor: COLORS.info.main,
            },
          },
          // TODO: CONFIRM TEXT COLOR - WHITE COLOR UNREADABLE
          // INFO - TEXT
          '&.MuiChip-colorInfo': {
            color: COLORS.info.contrastText,
            '.MuiChip-deleteIcon': {
              color: COLORS.info.contrastText,
            },
            '&:hover': {
              color: COLORS.info.contrastText,
              '.MuiChip-deleteIcon': {
                color: COLORS.info.contrastText,
              },
            },
          },
          // WARNING
          '&.MuiChip-containedWarning': {
            color: COLORS.warning.contrastText,
            borderColor: COLORS.warning.contrastText,
            '&:hover': {
              backgroundColor: COLORS.warning.light,
              borderColor: COLORS.warning.light,
            },
            '.MuiChip-deleteIcon': {
              color: COLORS.warning.contrastText,
            },
          },
          '&.MuiChip-outlinedWarning': {
            color: COLORS.warning.contrastText,
            borderColor: COLORS.warning.contrastText,
            '&:hover': {
              backgroundColor: COLORS.warning.main,
              borderColor: COLORS.warning.main,
            },
            '.MuiChip-deleteIcon': {
              color: COLORS.warning.contrastText,
            },
          },
          '&.MuiChip-filledWarning': {
            backgroundColor: COLORS.warning.main,
          },
          // ERROR
          '&.MuiChip-outlinedError': {
            color: COLORS.error.contrastText,
            borderColor: COLORS.error.contrastText,
            '&:hover': {
              backgroundColor: COLORS.error.main,
              borderColor: COLORS.error.main,
            },
            '.MuiChip-deleteIcon': {
              color: COLORS.error.contrastText,
            },
          },
          '&.MuiChip-filledError': {
            backgroundColor: COLORS.error.contrastText,
          },
          // GENERAL
          '.MuiChip-deleteIcon': {
            fontSize: '16px',
          },
          '&:hover': {
            color: COLORS.white.main,
            '.MuiChip-deleteIcon': {
              color: COLORS.white.main,
            },
          },
          '.MuiChip-label': {
            '.MuiSvgIcon-root': {
              verticalAlign: 'middle',
              margin: '0 5px',
            },
          },
        },
        filled: {
          color: COLORS.white.main,
          '.MuiChip-deleteIcon': {
            color: COLORS.white.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root': {
            margin: '-1px',
          },
          '.Mui-disabled &': {
            color: COLORS.gray.disabled, // COLORS.gray.main * 0.5 opacity
          },
        },
        colorPrimary: {
          color: COLORS.gray.main,
          '&.Mui-checked': {
            backgroundColor: COLORS.primary.main,
            '.MuiSvgIcon-root': {
              backgroundColor: COLORS.primary.main,
              color: COLORS.white.main,
              borderRadius: '50%',
              border: '2px solid white',
              outline: '1px solid white',
              padding: '5px',
              margin: '-1px',
            },
          },
          '.Mui-disabled &': {
            opacity: 0.5,
            '&.Mui-checked': {
              '.MuiSvgIcon-root': {
                backgroundColor: COLORS.gray.main,
              },
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginRight: '5px',
          borderRadius: '0',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {},
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {},
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: COLORS.background.primary,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: '0',
          boxShadow: 'none',
          border: '1px solid',
          borderColor: COLORS.primary.main,
          '&:first-of-type': {
            borderRadius: '0',
          },
          '&:last-child': {
            borderRadius: 0,
            marginBottom: 0,
            borderBottom: '1px solid',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
          '&.Mui-disabled': {
            backgroundColor: COLORS.white.main,
            '.MuiAccordionSummary-expandIconWrapper': {
              display: 'none',
            },
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            color: COLORS.primary.main,
          },
          '.MuiAccordionSummary-content': {
            color: COLORS.primary.main,
          },
          // Custom Dashboard accordion
          '&.Dashboard-accordion': {
            border: 'none',
            marginBottom: '12px',
            '&::before': {
              display: 'none',
            },
            '.MuiAccordionSummary-content': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '.SummaryTitle-left': {
                display: 'flex',
                alignItems: 'center',
                '> *': {
                  marginRight: '12px',
                },
              },
              '.SummaryTitle-right': {
                marginRight: '12px',
              },
            },
            '.MuiAccordionDetails-root': {
              padding: '0px 32px',
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {},
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {},
        title: {
          color: COLORS.header.main,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {},
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {},
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        required: false,
      },
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        margin: 4,
        color: 'black',
      },
      error: {
        '&.MuiFormHelperText-root.Mui-error': {
          color: 'green',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          lineHeight: '20px',
          color: COLORS.text.main,
          marginBottom: '8px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: 12,
          '.MuiInputBase-inputMultiline': {
            padding: '0px !important',
          },
          '.MuiOutlinedInput-input': {
            padding: '10px 14px',
            minHeight: '30px !important',
          },
          '.Mui-disabled': {
            backgroundColor: 'rgba(238, 238, 238, 1)',
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
          '.MuiInputLabel-root': {
            paddingRight: 1,
            color: 'rgba(55, 71, 79, 0.6)',
            transform: 'translate(14px, 9px) scale(1)',
          },
          '.MuiInputLabel-shrink': {
            color: 'rgba(55, 71, 79, 0.6)',
            transform: 'translate(14px, -9px) scale(0.75)',
          },
          '.MuiInputBase-root': {
            borderRadius: 0,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(185, 185, 186, 1)',
          },
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            paddingTop: 3,
            paddingBottom: 3,
          },
          '.MuiFilledInput-root': {
            background: 'red',
            border: '1px solid #b9b9ba',
            padding: '10px 14px !important',
            '&:hover': {
              background: 'none',
              border: '1px solid',
              borderColor: COLORS.primary.main,
            },
            '&:after': {
              transition: 'none',
              border: 'none',
              borderBottom: 'none',
              content: 'none',
            },
            '&:before': {
              transition: 'none',
              border: 'none',
              borderBottom: 'none',
              content: 'none',
            },
            '&.Mui-focused': {
              background: 'none',
              transition: 'none',
              '&:hover': {
                background: 'none',
              },
              '&:after': {
                transition: 'none',
                border: 'none',
                borderBottom: 'none',
                content: 'none',
              },
              '&:before': {
                transition: 'none',
                border: 'none',
                borderBottom: 'none',
                content: 'none',
              },
            },
            '.MuiChip-filled': {
              margin: '5px',
            },
          },
        },
        popper: {
          zIndex: 10,
          color: 'red !important',
          width: 20,
        },
      },
    },
  },
})

export default theme
