import { ChangeEvent, useEffect } from 'react'
import { TextField, InputLabel, Alert } from '@mui/material'
import { forwardRef } from 'react'

import { COLORS } from '@constants'
import { useFormContext } from 'react-hook-form'

interface Props {
  variant?: 'filled' | 'outlined' | 'standard'
  labelType?: 'fixed' | 'floating'
  label?: string
  id?: string
  name?: string
  required?: boolean
  color?: 'success' | 'info' | 'warning' | 'error' | 'primary'
  placeholder?: string
  disabled?: boolean
  horizontal?: boolean
  alert?: {
    severity?: 'success' | 'info' | 'warning' | 'error'
    message: string
  }
  value?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  rest?: any
}

const TextInput: React.FC<Props> = forwardRef(
  (
    {
      variant,
      id,
      name,
      labelType = 'fixed',
      label,
      horizontal,
      color = 'primary',
      placeholder,
      required,
      alert,
      ...rest
    },
    ref
  ) => {
    const ctx: any = useFormContext()

    if (alert?.message && !alert.severity) {
      alert.severity = 'error'
    }

    if (alert?.severity) {
      color = alert.severity
    }

    return (
      <div
        style={{
          display: 'flex',
          ...(horizontal
            ? { alignItems: 'center' }
            : { flexDirection: 'column' }),
        }}>
        {label && labelType === 'fixed' && (
          <InputLabel
            htmlFor={id || name}
            style={horizontal ? { width: '256px' } : undefined}>
            {label}
          </InputLabel>
        )}
        {ctx?.isPDFExport ? (
          <div className="pdf-export__input">{rest?.value}</div>
        ) : (
          <TextField
            inputRef={ref}
            name={name}
            id={id || name}
            variant={variant}
            label={labelType === 'floating' ? label : ''}
            placeholder={ctx?.isPDFExport ? '' : placeholder}
            required={required}
            color={color}
            sx={{
              flex: horizontal ? '1 0 auto' : undefined,
              '& .MuiOutlinedInput-root': {
                '& > fieldset': {
                  borderColor: alert?.message
                    ? COLORS[color].contrastText
                    : COLORS.gray.main,
                },
              },
            }}
            {...rest}
          />
        )}
        {alert?.message && (
          <Alert
            severity={alert.severity ? alert.severity : 'error'}
            sx={{
              marginTop: '6px',
              marginBottom: '6px',
              maxWidth: horizontal ? '300px' : undefined,
            }}>
            {alert.message}
          </Alert>
        )}
      </div>
    )
  }
)
TextInput.displayName = 'TextInput'
export default TextInput
