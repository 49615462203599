import { applyInterceptors, addHandler } from '@acato/axios-utils'
import { getState, setPartialState } from '@store/ui.store'

// Components
import { toaster } from '@store/toaster.state'

// Authorization config:
export const requestAuthInterceptor = config => {
  config.headers.Authorization = `Bearer ${getState('auth.token')}`
  return config
}

export const responseAuthInterceptor = response => {
  if (response.headers.authorization) {
    const oldToken = localStorage.getItem('auth')
    const { data } = JSON.parse(oldToken)
    const token = response.headers.authorization
    const newToken = { data, token }
    localStorage.setItem('auth', JSON.stringify(newToken))
    const setAuth = setPartialState('auth')
    setAuth({ data, token })
  }
  return response
}

export const errorInterceptor = addHandler({
  match: /[400-599]/,
  handler: error => {
    if (error?.status === 401) {
      localStorage.removeItem('auth')
      window.location.href = `${window.location.origin}/login`
      toaster.error('Unauthenticated, je bent automatisch uitgelogd.')
    }

    let errors = error?.data?.message
    if (error?.data?.errors) {
      errors = Object.entries(error.data.errors)
        .reduce(
          (memo, [key, value]) => {
            const msgKey = key
            const msgValues = value.join(' ')
            memo.push({ [msgKey]: msgValues })
            return memo
          },
          [errors]
        )
        .filter(msg => typeof msg !== 'string')
    }

    if (window.Rollbar) window.Rollbar.info(errors)

    return Promise.reject({ errors, raw: error?.data?.errors })
  },
})

export const createInterceptor = ({ error, request, response }) =>
  applyInterceptors({
    ...(error && {
      error,
    }),
    ...(request && {
      request,
    }),
    ...(response && {
      response,
    }),
  })
