import { declarationHooks } from '@services'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

// MUI
import { Container } from '@mui/material'

// Components
import {
  Alert,
  Button,
  Loader,
  LoaderSkeleton as Skeleton,
  Panel,
  SideMenu,
} from '@components'
import { toaster } from '@store/toaster.state'
import { setPartialState } from '@store/ui.store'
import RenderFormComponent from './components/RenderFormComponent'

// Utils
import getDate from '@helpers/dates/getDate'
import resetScrollPosition from '@helpers/view/resetScrollPosition'

// Icons
import { ArrowBack, ArrowForward } from '@mui/icons-material'

// Constants
import forms from '@constants/forms'

// Styles
import { COLORS } from '@constants'
import './style.scss'

const DeclarationView = () => {
  // Hooks
  const { setSectionColor, setRenderClose } = useOutletContext()
  const { useDeclaration } = declarationHooks
  const [fetchDeclaration, , declaration, err] = useDeclaration()
  const { declaration_id } = useParams()
  const navigate = useNavigate()

  // Store
  const setDeclarationStore = setPartialState('declaration')

  // Initial state
  const [pageLoading, setPageLoading] = useState(true)
  const [declarationError, setDeclarationError] = useState(null)
  const [formLoading, setFormLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [taxYear, setTaxYear] = useState(null)
  const [year, setYear] = useState(null)
  const [company, setCompany] = useState(null)
  const [preferences, setPreferences] = useState(null)
  const [nextButtonLabel, setNextButtonLabel] = useState(forms.next.text)
  const [sections, setSections] = useState([])
  const [currentSection, setCurrentSection] = useState(null)
  const [currentSubSection, setCurrentSubSection] = useState(null)
  const [currentForm, setCurrentForm] = useState(null)
  const [currentStep, setCurrentStep] = useState(null)
  const [endpoint, setEndpoint] = useState('')
  const [formId, setFormId] = useState('')
  const [wasConcept, setWasConcept] = useState(false)
  const [isDefinitive, setIsDefinitive] = useState(false)
  const [version, setVersion] = useState(null)

  const targetSection = useMemo(() => {
    if (!sections?.length) return

    return sections.find(({ slug }) => slug === 'target')
  }, [sections])

  // Reactive
  useEffect(() => {
    setSectionColor(COLORS.background.default)
    setRenderClose(true)
  }, [setSectionColor, setRenderClose])

  useEffect(() => {
    fetchDeclaration(declaration_id)
  }, [declaration_id, fetchDeclaration])

  const onSubmitError = useCallback(errors => toaster.error(errors), [])

  // Hide SideMenu if canvas editor is open ("Plan van Aanpak")
  const hideSideMenu = useMemo(() => {
    return (
      currentSection?.slug === 'canvas' && currentForm?.slug !== 'explainer'
    )
  }, [currentSection, currentForm])

  const successHandler = useCallback(
    declaration => {
      const {
        company,
        end_tax_year_at,
        year,
        sections,
        status,
        share_dialogs,
        share_profile,
        receive_info,
        receive_tips,
        declaration_version,
      } = declaration.data

      if (!sections) return
      if (status === 'finished' && wasConcept)
        return navigate('/declaration/success/' + declaration_id)
      if (status !== 'finished') setWasConcept(true)

      setDeclarationStore({ declaration: declaration.data })

      const tax_year = end_tax_year_at
        ? getDate(end_tax_year_at).pretty
        : `31 December ${declaration.data.year}`
      const openSections = sections.filter(section => !section.completed)
      const openSubSections =
        openSections?.[0]?.sections?.filter?.(section => !section.completed) ||
        []
      const section = openSubSections[0] || openSections[0] || sections.at(-1)
      const openForms = section.forms.filter(
        form => !form.completed && !form.skip
      )
      const form = openForms[0] || section.forms.at(-1)
      const formButton = form.button_label

      setVersion(declaration_version)
      setStatus(status)
      setPreferences({
        share_dialogs,
        share_profile,
        receive_info,
        receive_tips,
      })
      setTaxYear(tax_year)
      setYear(year)
      resetScrollPosition()
      setCompany(company)
      setSections(sections)
      setCurrentSubSection(openSubSections?.[0] || null)
      setCurrentStep(`${section.slug}-${form.slug}`)
      handleFormNavigation({ section, form })
      setNextButtonLabel(formButton || forms.next.text)
      setFormLoading(false)
      setPageLoading(false)
    },
    [wasConcept]
  )

  useEffect(() => {
    if (err?.errors) {
      toaster.error(err.errors)
      setDeclarationError(err.errors)
    }
    declaration && successHandler(declaration)
  }, [declaration, err, successHandler])

  // Methods - Form Navigation
  const handleFormNavigation = ({ section, form }) => {
    if (typeof section === 'string') {
      section = declaration.data.sections.find(
        _section => _section.slug === section
      )
    }
    if (typeof form === 'string') {
      form = section.forms.find(_form => _form.slug === form)
    }
    if (typeof form === 'number') {
      const currentIndex = section.forms.findIndex(
        form => form.id === currentForm.id
      )
      form = section.forms[currentIndex + form]
    }
    if (section?.subSection) {
      setCurrentSubSection(section.subSection)
    }
    const companyEndpoint = '/api/company-portal'
    const self = form.self.substring(
      form.self.indexOf(companyEndpoint) + companyEndpoint.length
    )

    setCurrentSection(section)
    setCurrentForm(form)
    setEndpoint(self)
    setFormId(`${section.slug}-${form.slug}`)

    setNextButtonLabel('Wijzigingen opslaan')
  }

  if (declarationError) {
    return (
      <Container>
        <Panel>
          <div>
            <Alert
              severity="error"
              content={declarationError}
            />
            <Button
              variant="contained"
              to="/"
              sx={{ marginTop: '12px' }}>
              Terug naar dashboard
            </Button>
          </div>
        </Panel>
      </Container>
    )
  }

  return (
    <div className="form-layout">
      <Container className={`form-grid ${hideSideMenu ? 'full-screen' : ''}`}>
        <div
          className="navigation"
          style={{
            display: hideSideMenu ? 'block' : 'none',
            marginTop: '-64px',
          }}>
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() =>
              handleFormNavigation({ section: 'canvas', form: -1 })
            }>
            Vorige
          </Button>
        </div>
        {pageLoading && sections.length < 1 ? (
          <div className="side-menu">
            <div className="skeleton-wrapper">
              <Skeleton
                width="100%"
                height="25px"
                margin="12px 0px"
              />
              <Skeleton
                width="80%"
                height="20px"
                margin="12px 0px"
              />
              <Skeleton
                width="100%"
                height="25px"
                margin="12px 0px"
              />
              <Skeleton
                width="70%"
                height="20px"
                margin="12px 0px"
              />
            </div>
          </div>
        ) : (
          <SideMenu
            className="side-menu"
            year={year}
            company={company}
            sections={sections}
            activeSubsection={currentSubSection}
            activeStep={currentForm.slug}
            currentStep={currentStep}
            handleFormNavigation={handleFormNavigation}
            declarationCompleted={status === 'finished'}
            hidden={hideSideMenu}
          />
        )}
        <Panel>
          {!formLoading && !pageLoading ? (
            <RenderFormComponent
              key={formId}
              formId={formId}
              endpoint={endpoint}
              company={company}
              preferences={preferences}
              year={year}
              taxYear={taxYear}
              section={currentSection.slug}
              targetSection={targetSection}
              currentSection={currentSection}
              currentForm={currentForm}
              currentSubSection={currentSubSection}
              onSubmitSuccess={successHandler}
              onSubmitError={onSubmitError}
              onFormLoaded={resetScrollPosition}
              declarationCompleted={status === 'finished'}
              isDefinitive={isDefinitive}
              version={version}
            />
          ) : (
            <div className="form-loader">
              <Loader text={forms.loading.text} />
            </div>
          )}
        </Panel>
      </Container>

      <Container className="button-grid">
        {!formLoading &&
          formId === 'submit-signature' &&
          status !== 'finished' && (
            <>
              <Button
                form={formId}
                variant="contained"
                color="secondary"
                endIcon={<ArrowForward />}
                sx={{ marginTop: '12px', marginRight: '12px' }}
                type="submit">
                Opslaan voor later
              </Button>{' '}
              <Button
                color="primary"
                variant="contained"
                disabled={!currentForm || status === 'finished'}
                loading={formLoading}
                endIcon={<ArrowForward />}
                sx={{ marginTop: '12px' }}
                onClick={() => setIsDefinitive(true)}>
                {nextButtonLabel}
              </Button>
            </>
          )}
        {formId === 'submit-success' ? (
          <Button
            variant="contained"
            to="/dashboard"
            sx={{ marginTop: '12px' }}>
            Terug naar mijn dashboard
          </Button>
        ) : (
          status !== 'finished' &&
          formId !== 'submit-signature' && (
            <Button
              type="submit"
              form={formId}
              color="primary"
              variant="contained"
              disabled={!currentForm || status === 'finished'}
              loading={formLoading}
              endIcon={<ArrowForward />}
              sx={{ marginTop: '12px' }}>
              {nextButtonLabel}
            </Button>
          )
        )}
      </Container>
    </div>
  )
}

export default DeclarationView
