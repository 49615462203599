import { useEffect, useState, useCallback, useMemo } from 'react'
import {
  Chip as MUIChip,
  ChipProps as MUIChipProps,
  IconButton,
} from '@mui/material'
import {
  Add,
  Done,
  Remove,
  StarBorder,
  WarningAmber,
  Star,
  ReportProblem,
} from '@mui/icons-material'

interface ChipSelectorProps {
  colorHex: string
  chips: any[]
  value: number[]
  onUpdate?: (value: Set<number>) => void
}
interface ChipProps extends MUIChipProps {
  colorHex?: string
}
interface BooleanChipProps extends ChipProps {
  uid: number
  label: string
  bgColor?: string
  colorHex?: string
  checked?: boolean
  type?: string
  onUpdate?: (uid?: number) => void
  onTypeSelect?: (uid?: number, type?: string) => boolean
}

const SUCCESS = 'focus1'
const ATTENTION = 'focus2'

const Chip = MUIChip as React.FC<ChipProps>

const BooleanChip: React.FC<BooleanChipProps> = ({
  uid,
  label,
  colorHex,
  type,
  checked,
  bgColor,
  disabled,
  onUpdate = () => {
    void 0
  },
  onTypeSelect,
}) => {
  const [checkedState, setChecked] = useState(checked)
  const [chipType, setChipType] = useState(type)
  const [isHovering, setHovering] = useState(false)

  const setType = async (uid: number, type: string) => {
    const newType = chipType === type ? '' : type
    if (onTypeSelect) {
      const select = await onTypeSelect(uid, newType)
      if (select !== false) setChipType(newType)
    }
  }

  return (
    <Chip
      icon={
        onTypeSelect || (!checkedState && disabled) ? (
          <span
            style={{
              display: 'flex',
              order: '1',
              margin: '0px 4px 0 0',
              gap: '8px',
            }}>
            {((disabled && chipType === SUCCESS) || onTypeSelect) && (
              <IconButton
                disabled={!onTypeSelect && disabled}
                size="small"
                onClick={() => setType(uid, SUCCESS)}>
                {chipType === SUCCESS ? (
                  <Star fontSize="inherit" />
                ) : (
                  <StarBorder fontSize="inherit" />
                )}
              </IconButton>
            )}
            {((disabled && chipType === ATTENTION) || onTypeSelect) && (
              <IconButton
                disabled={!onTypeSelect && disabled}
                size="small"
                onClick={() => setType(uid, ATTENTION)}>
                {chipType === ATTENTION ? (
                  <ReportProblem fontSize="inherit" />
                ) : (
                  <WarningAmber fontSize="inherit" />
                )}
              </IconButton>
            )}
          </span>
        ) : checkedState ? (
          isHovering && !disabled ? (
            <Remove fontSize="small" />
          ) : (
            <Done fontSize="small" />
          )
        ) : disabled ? undefined : (
          <Add
            fontSize="small"
            style={{ marginLeft: '4px' }}
          />
        )
      }
      sx={{
        '&&, && svg': { color: 'primary.main' },
        '&&': {
          borderRadius: 1,
          border: !checkedState
            ? '2px solid transparent'
            : `2px solid ${colorHex}`,
          borderWidth: '2px',
          background: bgColor || (isHovering ? colorHex : ''),
          boxShadow: 'none',
          cursor: disabled ? 'default' : '',
          span: { fontWeight: 400 },
        },
      }}
      {...{ label }}
      variant={checkedState ? 'outlined' : 'filled'}
      onClick={
        disabled
          ? undefined
          : () => {
              onUpdate(uid)
              setChecked(!checkedState)
            }
      }
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    />
  )
}

const ChipSelector: React.FC<ChipSelectorProps> = ({
  colorHex,
  chips,
  value = [],
  onUpdate = () => {
    void 0
  },
}) => {
  const [checkedStates, setCheckedStates] = useState(new Set(value))

  const updateCheckedState = (id?: number) => {
    const newState = new Set(checkedStates)
    if (id) newState[checkedStates.has(id) ? 'delete' : 'add'](id)
    setCheckedStates(newState)
    onUpdate(newState)
  }

  useEffect(() => {
    setCheckedStates(new Set(value))
  }, [value])

  const getCheckedState = useCallback(
    (id: number) => checkedStates.has(id),
    [checkedStates, chips]
  )

  return useMemo(
    () => (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px 8px' }}>
        <>
          {chips
            .filter(chip => chip && chip?.id)
            ?.map(({ id, label, type }) => (
              <BooleanChip
                {...{ colorHex, type, label }}
                uid={id}
                key={id}
                checked={getCheckedState(id)}
                onUpdate={updateCheckedState}
              />
            ))}
        </>
      </div>
    ),
    [checkedStates, chips]
  )
}

export { BooleanChip }
export default ChipSelector
