import IntakeFormVersionA from './version-a/IntakeFormVersionA'
import IntakeFormVersionB from './version-b/IntakeFormVersionB'

const IntakeFormVersionController = ({ intake_version, ...rest }) => {
  switch (intake_version) {
    case 'A':
      return <IntakeFormVersionA intake={{ intake_version, ...rest }} />
    case 'B':
      return <IntakeFormVersionB intake={{ intake_version, ...rest }} />
  }
}

export default IntakeFormVersionController
