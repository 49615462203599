import { useEffect } from 'react'
import { Controller } from 'react-hook-form'

// Components
import { BooleanQuestion } from '@components'
import IntakeParent from '../IntakeParent'
import IntakeSubsidiaries from '../IntakeSubsidiaries'

// Utils
import interpolate from '@helpers/strings/interpolate'

// Content
import forms from '@constants/forms'

// Styles
import { Alert } from '@components'
import { Box, List, ListItem, Typography } from '@mui/material'
import '../styles.scss'

const IntakeVersionBFormStep2 = ({
  disabled,
  intake,
  control,
  watch,
  setValue,
}) => {
  // State
  const { company, group_head, status, year, intake_version } = intake
  const { sections, questions } = forms.intake.b

  // Methods
  const handleSubsidiaryChange = subsidiaries => {
    setValue('subsidiaries', subsidiaries)
  }

  const handleParentChange = parent => {
    setValue('parent', parent)
  }

  // Conditionally render inputs based on previous answers
  const isGroup = watch('is_part_of_group')
  const isGroupHead = watch('is_group_owner')
  const parent = watch('parent')
  const subsidiaries = watch('subsidiaries')
  const groupHeadIsNative = watch('group_head_is_native')
  const groupDiversityReporting = watch('group_diversity_reporting')

  const disableGroupChoices = status === 'prefilled-by-intake-requests'

  useEffect(() => {
    if (!parent && group_head) handleParentChange(group_head)
  }, [group_head])

  return (
    <>
      <h2 style={{ marginTop: 0 }}>{sections.group.heading}</h2>
      <Controller
        control={control}
        name="is_part_of_group"
        render={({
          field: { name, value, ref, onChange },
          fieldState: { error },
        }) => {
          return (
            <BooleanQuestion
              required
              value={value}
              onChange={onChange}
              question={interpolate(questions[name].label, {
                company: company.name,
                year,
              })}
              alert={{ message: error?.message }}
              inputRef={ref}
              disabled={disabled || disableGroupChoices}
            />
          )
        }}
      />

      {isGroup && (
        <div className="fade-in">
          <Controller
            control={control}
            name="is_group_owner"
            render={({
              field: { name, value, ref, onChange },
              fieldState: { error },
            }) => {
              return (
                <BooleanQuestion
                  required
                  value={value}
                  onChange={onChange}
                  question={interpolate(questions[name].label, {
                    company: company.name,
                    year,
                  })}
                  alert={{ message: error?.message }}
                  inputRef={ref}
                  disabled={disabled || disableGroupChoices}
                />
              )
            }}
          />
        </div>
      )}

      {isGroup && isGroupHead === false && (
        <div className="fade-in">
          <Controller
            control={control}
            name="group_head_is_native"
            render={({
              field: { name, value, ref, onChange },
              fieldState: { error },
            }) => {
              return (
                <BooleanQuestion
                  required
                  value={value}
                  onChange={onChange}
                  question={questions[name].label}
                  alert={{ message: error?.message }}
                  inputRef={ref}
                  disabled={disabled || disableGroupChoices}
                />
              )
            }}
          />
        </div>
      )}

      {isGroup && isGroupHead === false && groupHeadIsNative === true && (
        <div className="fade-in">
          <h2 style={{ marginTop: 26 }}>
            Welk bedrijf was in {year} het hoofd van deze groep?
          </h2>
          <Controller
            name="parent"
            control={control}
            render={({ fieldState: { error } }) => {
              return (
                <>
                  {error && (
                    <Alert
                      content="Vul een bedrijf in als hoofd van de groep"
                      severity="error"
                      className=""
                      sx={{ my: 2 }}
                    />
                  )}
                  <IntakeParent
                    intake_version={intake_version}
                    button_label="Voeg een hoofd toe"
                    parent={parent === null ? null : parent || group_head}
                    setParent={handleParentChange}
                    current_kvk={company.kvk_number}
                    disabled={
                      disabled || group_head?.locked || disableGroupChoices
                    }
                  />
                </>
              )
            }}
          />
        </div>
      )}

      {isGroup && isGroupHead && (
        <div className="fade-in">
          <h2 style={{ marginTop: 52 }}>
            Welke grote vennootschappen behoorden in het boekjaar {year} tot
            deze groep?
          </h2>

          <div className="fade-in">
            <Controller
              control={control}
              name="group_diversity_reporting"
              render={({
                field: { name, value, ref, onChange },
                fieldState: { error },
              }) => {
                return (
                  <BooleanQuestion
                    required
                    value={value}
                    onChange={onChange}
                    question={interpolate(questions[name].label, { year })}
                    alert={{ message: error?.message }}
                    inputRef={ref}
                    disabled={disabled || disableGroupChoices}
                  />
                )
              }}
            />
          </div>

          <Box
            padding={1}
            lineHeight={1.2}>
            <Alert
              severity="info"
              content={
                <>
                  <Typography>
                    Voeg alleen groepsmaatschappijen toe die kwalificeren als
                    "grote" vennootschap. Dit is het geval als een vennootschap
                    op twee opeenvolgende balansdata voldoet aan ten minste twee
                    van de drie criteria uit het jaarrekeningrecht. De criteria
                    zijn in 2024 gewijzigd, namelijk:
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        De waarde van activa bedraagt meer dan €25 miljoen
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        De netto-omzet is meer dan €50 miljoen
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Het gemiddeld aantal werknemers is 250 of meer
                      </Typography>
                    </li>
                  </ul>

                  <Typography>
                    Deze criteria zijn van toepassing op boekjaren die zijn
                    aangevangen op of na 1 januari 2024, en kunnen worden
                    toegepast op de boekjaren die zijn aangevangen op of na 1
                    januari 2023.
                  </Typography>
                </>
              }
            />
          </Box>

          {groupDiversityReporting && (
            <div className="fade-in">
              <Controller
                name="subsidiaries"
                control={control}
                render={({ fieldState: { error } }) => {
                  return (
                    <>
                      {error && (
                        <Alert
                          content="Vul een groepsmaatschappij in"
                          severity="error"
                          className=""
                          sx={{ my: 2 }}
                        />
                      )}
                      <IntakeSubsidiaries
                        year={year}
                        intake_version={intake_version}
                        button_label="Voeg een groepsmaatschappij toe"
                        subsidiaries={subsidiaries}
                        setSubsidiaries={handleSubsidiaryChange}
                        current_kvk={company.kvk_number}
                        disabled={disabled}
                      />
                    </>
                  )
                }}
              />
            </div>
          )}
        </div>
      )}

      {isGroup && isGroupHead === false && parent && groupHeadIsNative && (
        <div className="fade-in">
          <h2 style={{ marginTop: 52 }}>{sections.sub_report.heading}</h2>
          <Controller
            control={control}
            name="parent.childReportsIndependently"
            render={({
              field: { name, value, ref, onChange },
              fieldState: { error },
            }) => {
              return (
                <BooleanQuestion
                  required
                  value={value}
                  onChange={onChange}
                  question={interpolate(questions[name].label, {
                    company: company.name,
                  })}
                  alert={{ message: error?.message }}
                  inputRef={ref}
                  disabled={disabled || parent?.locked}
                />
              )
            }}
          />
        </div>
      )}
    </>
  )
}

export default IntakeVersionBFormStep2
