import { useState, useMemo, useEffect, useCallback } from 'react'

// Components
import { Button } from '@components'
import { SearchKVK } from '@components'

// Material
import { Radio, Box } from '@mui/material'

// Icons
import CircleIcon from '@mui/icons-material/Circle'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

// Styles
import './styles.scss'

const AddSubsidiaryModal = ({ push, current_kvk }) => {
  // State
  const [result, setResult] = useState(null)
  const [selected, setSelected] = useState(null)

  const pushToSubsidiaries = useCallback(() => {
    const { id, name } = result[selected]

    push({ kvk_number: id, name })
  }, [result, selected])

  useEffect(() => {
    setSelected(null)
  }, [result])

  return (
    <div className="add-subsidiary-modal">
      <SearchKVK
        setResult={setResult}
        current_kvk={current_kvk}
      />
      {!!result?.length &&
        result.map((company, index) => (
          <Box
            className="add-subsidiary-modal__item"
            onClick={() => setSelected(index)}
            key={index}
            title={
              !company?.active
                ? 'Dit bedrijf is uitgeschreven uit het Handelsregister'
                : ''
            }>
            <div style={{ margin: '0 16px 0 -8px' }}>
              {!company?.active ? (
                <Radio
                  checked={selected === index}
                  style={{ background: 'none' }}
                  checkedIcon={<CircleIcon fontSize="small" />}
                  icon={
                    <ErrorOutlineOutlinedIcon
                      style={{ color: '#D89427' }}
                      fontSize="small"
                    />
                  }
                />
              ) : (
                <Radio
                  checked={selected === index}
                  style={{ background: 'none' }}
                  checkedIcon={<CircleIcon fontSize="small" />}
                />
              )}
              {company.name} - {company.city}
            </div>
            <div style={{ color: '#828282' }}>KvK nr.: {company.id}</div>
          </Box>
        ))}
      <div className="modal-actions">
        <Button
          variant="contained"
          disabled={selected === null}
          onClick={pushToSubsidiaries}
          style={{ marginLeft: 'auto' }}>
          Voeg dit bedrijf toe
        </Button>
      </div>
    </div>
  )
}

export default AddSubsidiaryModal
