const getDate = (timestamp = Date.now()) => {
  const standardDate = new Date(timestamp)
  const prettyOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const pretty = standardDate.toLocaleDateString('nl-NL', prettyOptions)
  const pretty_day = standardDate.toLocaleDateString('nl-NL', options)
  const day = standardDate.toLocaleDateString('nl-NL', { weekday: 'long' })
  const date = standardDate.toLocaleDateString('nl-NL', { month: 'long' })
  const month = standardDate.toLocaleDateString('nl-NL', { month: 'long' })
  const year = standardDate.toLocaleDateString('nl-NL', { year: 'numeric' })

  return {
    timestamp,
    date,
    pretty,
    pretty_day,
    day,
    month,
    year,
  }
}

export default getDate
