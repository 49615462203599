const ratioSubtop = {
  sections: {
    total: {
      subHeading: '',
      heading: 'Man-vrouwverhouding in de subtop',
      intro: 'Hoeveel vrouwen en mannen had de subtop in {{year}}?',
      number_heading: 'Hoe staat het er voor?',
    },
    turnover: {
      heading: 'In- en uitstroom van werknemers in de subtop',
      subheading: 'Lees meer over instroom en uitstroom',
      content: (
        <>
          <strong>Definitie instroom</strong>
          <p>
            Het aantal werknemers dat gedurende het afgelopen boekjaar is
            aangesteld in een functie in de subtop.
          </p>
          <p>Het gaat om:</p>
          <ul>
            <li>
              Personen van buiten de organisatie die werden aangesteld in een
              functie in de subtop.
            </li>
            <li>
              Werknemers uit de eigen organisatie die werden aangesteld in een
              functie in de subtop en die voorheen geen functie in de subtop
              hadden.
            </li>
          </ul>
          <p>
            De overgang van een functie in de subtop naar een andere functie
            binnen de subtop wordt niet als instroom geteld.
          </p>
          <p>
            Aanstellingen via uitzendbureaus, payrolling vallen hier niet onder.
            Ook nulurencontracten worden niet meegeteld.
          </p>
          <strong>Definitie uitstroom</strong>
          <p>
            Het aantal werknemers dat gedurende het afgelopen jaar de subtop
            heeft verlaten.
          </p>
          <p>Het gaat om:</p>
          <ul>
            <li>
              Werknemers die uitstroomden naar een functie in de organisatie die
              niet tot de subtop wordt gerekend.
            </li>
            <li>Werknemers die de organisatie hebben verlaten.</li>
          </ul>
          <p>
            De overgang van een functie binnen de subtop naar een andere functie
            binnen de subtop wordt niet als uitstroom geteld.
          </p>
          <p>
            Aanstellingen via uitzendbureaus, payrolling vallen hier niet onder.
            Ook nulurencontracten worden niet meegeteld.
          </p>
        </>
      ),
    },
  },
  questions: {
    code_1: {
      label: 'Niet van toepassing, wij hebben geen subtop.',
    },
    'code_2.male': {
      col_label: 'Aantal mannen',
      label:
        'Hoeveel werknemers had uw bedrijf in de subtop op {{taxYear}} (in personen)?',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_2.female': {
      col_label: 'Aantal vrouwen',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_3.male': {
      col_label: 'Aantal mannen',
      label:
        'Hoeveel werknemers werden in het boekjaar {{year}} aangesteld in de subtop?',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_3.female': {
      col_label: 'Aantal vrouwen',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_4.male': {
      label: 'Hoeveel werknemers verlieten de subtop in het boekjaar {{year}}?',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
    'code_4.female': {
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
  },
}

export default ratioSubtop
