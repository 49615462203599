import { Navigate } from 'react-router-dom'

// Layout
import AppLayout from '@layouts/AppLayout'
import GuestLayout from '@layouts/GuestLayout'

// Views
import {
  ActivationCode,
  CreateAccountView,
  DashboardCompany,
  DashboardOverview,
  DeclarationSuccessView,
  DeclarationView,
  PdfExport,
  GenderIdentityView,
  IntakeSuccessView,
  IntakeView,
  LegislationView,
  LoginView,
  PasswordForgetView,
  PasswordResetView,
  ProfileView,
  SuccessView,
} from '@views'
import AppLayoutWithoutHeaderAndFooter from '@layouts/AppLayoutWithoutHeaderAndFooter'

export const createRoutes = location => {
  return [
    {
      // DASHBOARD
      path: '/',
      element: <AppLayout from={location} />,
      children: [
        {
          path: 'dashboard',
          element: <DashboardOverview />,
        },
        {
          path: 'company/:company_id',
          element: <DashboardCompany />,
        },
        {
          path: 'intake/:intake_id',
          element: <IntakeView />,
        },
        {
          path: 'intake/success/:intake_id',
          element: <IntakeSuccessView />,
        },
        {
          path: 'profile',
          element: <ProfileView />,
        },
        {
          path: 'explainer/gender-identity',
          element: <GenderIdentityView />,
        },
        {
          path: 'explainer/legislation',
          element: <LegislationView />,
        },
        {
          path: 'declaration/:declaration_id',
          element: <DeclarationView />,
          children: [
            {
              path: ':section',
              element: <DeclarationView />,
              children: [
                {
                  path: ':form',
                  element: <DeclarationView />,
                },
              ],
            },
          ],
        },
        {
          path: 'declaration/success/:declaration_id',
          element: <DeclarationSuccessView />,
        },
        {
          path: '*',
          element: (
            <Navigate to={location.state?.from?.pathname || '/dashboard'} />
          ),
        },
        {
          path: '',
          element: (
            <Navigate to={location.state?.from?.pathname || '/dashboard'} />
          ),
        },
      ],
    },
    {
      path: '/',
      element: <AppLayoutWithoutHeaderAndFooter from={location} />,
      children: [
        {
          path: 'declaration/:declaration_id/pdf',
          element: <PdfExport />,
        },
      ],
    },
    {
      path: '/',
      element: <GuestLayout />,
      children: [
        {
          path: 'login',
          element: <LoginView defaultRoute={'/dashboard'} />,
        },
        {
          path: 'activate',
          element: <ActivationCode />,
        },
        {
          path: 'activate/success',
          element: <SuccessView page="activation" />,
        },
        { path: 'password-forget', element: <PasswordForgetView /> },
        { path: 'password-reset', element: <PasswordResetView /> },
        { path: 'register', element: <CreateAccountView /> },
        {
          path: 'password-forget/success',
          element: <SuccessView page="forgotPassword" />,
        },
        {
          path: 'password-reset/success',
          element: <SuccessView page="passwordReset" />,
        },
      ],
    },
  ]
}
