import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LogoutIcon from '@mui/icons-material/Logout'

import { getState } from '@store/ui.store'
import { setPartialState } from '@store/ui.store'
import { useNavigate, useLocation, Link } from 'react-router-dom'

import { COLORS } from '@constants'

// Style
import './style.scss'

const AccountMenu = ({ userName }) => {
  const navigate = useNavigate()

  // MENU: Toogle
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Box className="accountMenuBox">
        <Button
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="text"
          onClick={handleClick}
          disableElevation
          disableRipple
          disableFocusRipple
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            '&:focus': {
              outline: '2px solid black',
            },
          }}>
          <AccountCircleIcon
            sx={{
              marginRight: '10px',
              fontSize: '32px',
              backgroundColor: COLORS.primary.main,
              borderRadius: '50%',
              color: 'white !important',
            }}
          />

          {userName}
        </Button>
      </Box>
      <Menu
        className="accountMenu"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 160,
            overflow: 'visible',
            boxShadow:
              'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,' +
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '.MuiMenuItem-root': {
              color: COLORS.primary.main,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem
          component={Link}
          to="/profile">
          Account en instellingen
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setPartialState('auth')(null)
            navigate('/')
          }}>
          <LogoutIcon sx={{ marginRight: '5px' }} /> Uitloggen
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default AccountMenu
