import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
  useMemo,
} from 'react'
import { declarationHooks } from '@services'
import { useForm, FormProvider } from 'react-hook-form'

// Components
import { FormInputContext, Loader } from '@components'
import FormNotFound from './FormNotFound'

// Constants
import forms from '@constants/forms'

// styles
import '../style.scss'
import { toaster } from '@store/toaster.state'

const RenderFormComponent = ({
  formId,
  endpoint,
  onFormLoaded,
  onSubmitSuccess,
  onSubmitError,
  year,
  taxYear,
  section,
  currentForm,
  currentSection,
  currentSubSection,
  company,
  preferences,
  declarationCompleted,
  targetSection,
  isDefinitive,
  version,
}) => {
  // Initial state
  const [renderError, setRenderError] = useState(false)

  // React hook-form context:
  const formMethods = useForm() // get all useForm methods.
  const { clearErrors, setError, setValue } = formMethods

  // Form submit with dynamic endpoints
  const { useDeclarationUpdate } = declarationHooks
  const [updateDeclaration, submitting, formResponse, formError] =
    useDeclarationUpdate()

  const setDefaultFormValues = useCallback(
    values => {
      values &&
        Object.entries(values).map(([key, value]) => setValue(key, value))
    },
    [setValue]
  )

  // Form submit function for each form in the sections
  const onSubmit = useCallback(
    body => {
      updateDeclaration({ endpoint, body })
    },
    [updateDeclaration, endpoint]
  )

  // Lazy load form component based on current form
  const FormComponent = useMemo(() => {
    const versionDirectory = `version-${version}`
    const sectionDirectory = section.match(/^\w+/)?.toString?.() || section
    const formSlug = currentForm?.slug
    const formFile = formSlug?.match(/^\w+/)?.toString?.() || formSlug

    return React.lazy(() => {
      return Promise.all([
        import(
          `@partials/forms/declaration/${versionDirectory}/${sectionDirectory}/${formFile}`
        ),
      ])
        .then(([moduleExports]) => moduleExports)
        .catch(err => {
          console.error(err)
          setRenderError(true)
        })
        .finally(() => {
          onFormLoaded()
          clearErrors()
        })
    })
  }, [section, version, currentForm?.slug, onFormLoaded, clearErrors])

  // Run effect with currentForm: set default/saved values.
  useEffect(() => {
    if (currentForm?.answers) setDefaultFormValues(currentForm.answers)
  }, [currentForm, setDefaultFormValues])

  useEffect(() => {
    if (isDefinitive) {
      setValue('finished', true)
      formMethods.handleSubmit(onSubmit)()
    }
  }, [isDefinitive])

  useEffect(() => {
    if (formError) {
      if (!formError?.errors?.forEach) {
        toaster.error('Er is een fout opgetreden')
      } else {
        let messages = []
        formError.errors.forEach((error, i) => {
          const err = Object.keys(error)[0]
          const msg = Object.values(error)[0]
          messages.push(msg)
          setError(err, { message: msg }, { shouldFocus: i === 0 })
        })
        onSubmitError && onSubmitError(messages)
      }
    }
    formResponse && onSubmitSuccess(formResponse)
  }, [formResponse, formError, onSubmitError, onSubmitSuccess, setError])

  // Form error
  if (renderError) return <FormNotFound />

  return (
    <div>
      <Suspense
        fallback={
          <div className="form-loader">
            <Loader text={forms.loading.text} />
          </div>
        }>
        <div
          key={formId}
          style={{
            display: submitting ? 'none' : 'block',
          }}>
          <FormProvider
            isPDFExport={true}
            {...formMethods}>
            <FormInputContext.Provider
              value={{ disabled: declarationCompleted }}>
              <FormComponent
                formId={formId}
                currentSection={currentSection}
                currentSubSection={currentSubSection}
                currentForm={currentForm}
                onSubmit={onSubmit}
                onSubmitSuccess={onSubmitSuccess}
                company={company}
                preferences={preferences}
                year={year}
                taxYear={taxYear}
                declarationCompleted={declarationCompleted}
                targetSection={targetSection}
              />
            </FormInputContext.Provider>
          </FormProvider>
        </div>
      </Suspense>
      {submitting && (
        <div className="form-loader">
          <Loader text="Formulier aan het opslaan..." />
        </div>
      )}
    </div>
  )
}

export default RenderFormComponent
