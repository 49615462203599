import { useEffect } from 'react'
import { intakeHooks } from '@services'
import { useNavigate } from 'react-router-dom'

// Components
import { Grid, CircularProgress } from '@mui/material'
import { Button } from '@components'
import { modal } from '@store/modal.state'
import { toaster } from '@store/toaster.state'

// styles
import './styles.scss'

const RequestModal = ({ content, id }) => {
  // Hooks
  const { useAcceptRequest } = intakeHooks
  const navigate = useNavigate()
  const [acceptRequest, loading, res, err] = useAcceptRequest()

  // Methods
  const handleRequest = e => {
    const accept = e.target.id === 'accept'
    acceptRequest({ id, accept })
  }

  useEffect(() => {
    if (res) {
      toaster.success('Uw reactie is successvol verwerkt')
      modal.setClose()
      navigate(0)
    }
    err?.errors && toaster.error(err.errors)
  }, [res, err, navigate])

  return (
    <div className="modal">
      {content}
      <Grid
        container
        spacing={5}>
        <Grid
          item
          xs={12}
          sm={6}>
          <Button
            id="accept"
            fullWidth
            onClick={handleRequest}
            color="primary"
            variant="contained"
            disabled={loading}>
            Verzoek accepteren
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}>
          <Button
            id="reject"
            fullWidth
            onClick={handleRequest}
            color="primary"
            variant="outlined"
            disabled={loading}>
            Verzoek afwijzen
          </Button>
        </Grid>
      </Grid>
      <div className={`loading-overlay ${loading ? 'loading' : ''}`}>
        <CircularProgress />
      </div>
    </div>
  )
}

export default RequestModal
