/* eslint-disable max-len */
const supervisoryBoard = {
  sections: {
    board: {
      heading: 'De raad van commissarissen',
      subHeading: 'Wat is het streefcijfer voor de raad van commissarissen?',
      intro: (
        <div>
          <p>
            Als u een one-tier board heeft, vult u hier het streefcijfer voor de
            niet-uitvoerende bestuurders in.
          </p>
          <p>
            Een streefcijfer van 30% betekent: ten minste 30% mannen en ten
            minste 30% vrouwen.
          </p>
          <p>
            Als uw bedrijf dit boekjaar beursgenoteerd was in Nederland, geldt
            een quotum voor de raad van commissarissen. U kunt hieronder dan
            33,3% doorgeven. Als streefjaar kiest u 2022.
          </p>
        </div>
      ),
    },
    situation: {
      heading: 'Hoe staat het ervoor?',
    },
    balance: {
      heading: 'Man-vrouwverhouding in de raad van commissarissen',
      intro:
        'Hoeveel leden had uw raad van commissarissen op [datum einde boekjaar]?',
      gridIntro:
        'Hoeveel nieuwe benoemingen, herbenoemingen en aftredingen zijn er geweest in uw raad van commissarissen in het afgelopen boekjaar?',
    },
    chair: {
      heading: 'Voorzitter',
    },
  },
  skip: {
    severity: 'info',
    content:
      'U heeft aangegeven dat uw organisatie geen Raad van Commissarissen heeft. U kunt deze stap overslaan door op volgende te klikken.',
  },
  questions: {
    code_1: {
      label: 'Streefcijfer (in %)',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
      inputProps: {
        min: 0,
        max: 50,
        step: 0.01,
      },
    },
    code_2: {
      label: 'Streefjaar',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
    },
    code_3: {
      label: 'Totaal op {{taxYear}}',
      male: {
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
        type: 'number',
        inputProps: {
          min: 0,
        },
      },
      female: {
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
        type: 'number',
        inputProps: {
          min: 0,
        },
      },
    },
    code_4: {
      label: 'Herbenoemd dit boekjaar',
      male: {
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
        type: 'number',
        inputProps: {
          min: 0,
        },
      },
      female: {
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
        type: 'number',
        inputProps: {
          min: 0,
        },
      },
    },
    code_5: {
      label: 'Nieuw benoemd dit boekjaar',
      male: {
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
        type: 'number',
        inputProps: {
          min: 0,
        },
      },
      female: {
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
        type: 'number',
        inputProps: {
          min: 0,
        },
      },
    },
    code_6: {
      label: 'Afgetreden dit boekjaar',
      male: {
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
        type: 'number',
        inputProps: {
          min: 0,
        },
      },
      female: {
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
        type: 'number',
        inputProps: {
          min: 0,
        },
      },
    },
    code_7: {
      label: 'Aantal niet ingevulde vacatures {{taxYear}}',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      type: 'number',
    },
    code_8: {
      label: 'De voorzitter identificeert zich als:',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      options: [
        {
          id: 1,
          value: 'male',
          label: 'Man',
        },
        {
          id: 2,
          value: 'female',
          label: 'Vrouw',
        },
        {
          id: 3,
          value: 'non-binary',
          label: 'Non-binair',
        },
        {
          id: 4,
          value: 'no-chair',
          label: 'Er is geen voorzitter',
          style: {
            marginTop: '12px',
            flex: 1,
          },
        },
      ],
    },
  },
}

export default supervisoryBoard
