import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

// Components
import { Footer, MobileForbidden, Nav, Section } from '@components'

// Styles
// import '../../styles/company-portal/global.scss'
import { getState } from '@store/ui.store'
import { Container, Content, FooterSection, Header } from './styles'

const AppLayout = ({ from }) => {
  const [sectionColor, setSectionColor] = useState()
  const [renderClose, setRenderClose] = useState(false)
  const [tooSmall, setTooSmall] = useState(false)

  const authenticated = getState('auth.token')
  const auth = getState('auth')
  const [userName, setUsername] = useState(auth?.data?.name)

  const handleViewport = () => {
    const width = window.innerWidth
    if (width < 960) setTooSmall(true)
    if (width >= 960) setTooSmall(false)
  }

  useEffect(() => {
    handleViewport()
    window.addEventListener('resize', handleViewport, { passive: true })
    return () => window.removeEventListener('resize', handleViewport)
  }, [])

  if (!authenticated)
    return (
      <Navigate
        to="/login"
        state={{ from }}
      />
    )

  return tooSmall ? (
    <MobileForbidden />
  ) : (
    <Container>
      <Header>
        <Nav
          renderClose={renderClose}
          userName={userName}
        />
      </Header>
      <Content>
        <Section color={sectionColor}>
          <Outlet context={{ setSectionColor, setRenderClose, setUsername }} />
        </Section>
      </Content>
      <FooterSection>
        <Footer />
      </FooterSection>
    </Container>
  )
}

export default AppLayout
