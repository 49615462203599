import { COLORS } from '@constants'

const styles = {
  page: {
    backgroundColor: COLORS.secondary.main,
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '70vh',
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',
  },
  card: {
    backgroundColor: COLORS.white.main,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  card__title: {
    color: COLORS.lightBlue.main,
    margin: 0,
  },
  card__content: {
    margin: '24px 0px',
    whiteSpace: 'pre-line',
  },
  card__button: {
    marginTop: 'auto',
    marginBottom: 0,
  },
}

export default styles
