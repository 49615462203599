/* eslint-disable max-len */

// UI
import { Chip } from '@components'
import { Button } from '@components'
import Tooltip from '@mui/material/Tooltip'

// Icons
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

// Utils
import renderStatusChip from '@helpers/view/renderStatusChip'

const AccordionRow = ({
  name,
  kvk_number,
  status,
  action,
  onAction,
  openRequests,
}) => {
  status = renderStatusChip(status)

  return (
    <div
      className="subsidiariesGrid"
      style={{ marginBottom: '24px' }}>
      <div>
        <span
          style={{
            fontSize: '20px',
            fontWeight: 500,
          }}>
          {name}
        </span>
        <br />
        <span style={{ color: '#666666' }}>{kvk_number}</span>
      </div>
      <Chip
        icon={<TaskAltIcon />}
        variant="outlined"
        color="primary"
        text="U rapporteert"
        sx={{ width: 'fit-content' }}
      />
      <Chip
        color={status.color}
        text={status.message}
        sx={{ width: 'fit-content' }}
      />
      {openRequests && (
        <Tooltip title="Beoordeel eerst alle openstaande verzoeken binnen uw groep, voordat u begint met de rapportage.">
          <div className="tooltip">
            <Button
              variant="contained"
              disabled>
              Start rapportage
            </Button>
          </div>
        </Tooltip>
      )}
      {action && (
        <Button
          variant="contained"
          onClick={onAction}
          endIcon={<ArrowForwardIcon />}
          sx={{ width: 'fit-content' }}>
          {action}
        </Button>
      )}
    </div>
  )
}

export default AccordionRow
