import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

const renderStatusChip = status => {
  switch (status) {
    case 'finished':
      return {
        color: 'success',
        message: 'Afgerond',
        icon: <TaskAltIcon />,
      }

    case 'in_progress':
      return {
        color: 'primary',
        message: 'Rapportage begonnen',
        icon: <TaskAltIcon />,
      }

    case undefined:
      return {
        color: 'primary',
        message: 'Nog niet begonnen',
        icon: <InfoOutlinedIcon />,
      }

    default:
      return {
        color: 'primary',
        message: 'Nog niet begonnen',
        icon: <InfoOutlinedIcon />,
      }
  }
}

export default renderStatusChip
