import { ReactElement } from 'react'

// Components
import { Container, Grid } from '@mui/material'
import { Button } from '@components'
import { Panel } from '@components'

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

interface Props {
  title: string
  content: ReactElement
}

const ExplainerScreen: React.FC<Props> = ({ title, content }) => {
  return (
    <Container>
      <Button
        to="/dashboard"
        startIcon={<ArrowBackIcon />}>
        Terug naar home
      </Button>
      <h1>{title}</h1>
      <Grid container>
        <Grid
          item
          xs={12}
          lg={9}>
          <Panel
            style={{ marginTop: 12 }}
            spacing={0}>
            {content}
          </Panel>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ExplainerScreen
