import { useEffect, useMemo } from 'react'
import { Controller } from 'react-hook-form'

// Material
import { Container, Grid } from '@mui/material'

// Components
import { Alert, BooleanQuestion, SelectImage } from '@components'
import intakeForm from '@constants/forms/intake'

// Styles
import '../styles.scss'

import interpolate from '@helpers/strings/interpolate'

const IntakeVersionAFormStep3 = ({
  intake,
  disabled,
  control,
  watch,
  setValue,
}) => {
  // State
  const { sections, questions } = intakeForm.a

  const form = watch()
  const isGroup = watch('is_part_of_group')
  const isGroupHead = watch('is_group_owner')
  const subsidiaries = watch('subsidiaries')

  const isConsolidatedAllowed = useMemo(() => {
    // Consolidated only allowed if one of the subs don't report for itself
    if (subsidiaries?.length) {
      if (subsidiaries.some(sub => sub.groupHeadReports === true)) {
        return true
      }
    }
    return false
  }, [form])

  useEffect(() => {
    if (disabled) return

    if (!isConsolidatedAllowed) setValue('consolidated', false)
    else setValue('consolidated', null)
  }, [isConsolidatedAllowed])

  const renderSubsidiaryObligations = useMemo(() => {
    if (!subsidiaries?.length) return

    return (
      <Container style={{ margin: '48px 0', padding: 0 }}>
        {subsidiaries.map((subsidiary, index) => (
          <Controller
            key={index}
            control={control}
            name={`subsidiaries.${index}.groupHeadReports`}
            render={({
              field: { value, ref, onChange },
              fieldState: { error },
            }) => {
              return (
                <BooleanQuestion
                  required
                  value={value}
                  onChange={onChange}
                  question={interpolate(questions['report_for_others'].label, {
                    company: subsidiary.name,
                  })}
                  alert={{ message: error?.message }}
                  inputRef={ref}
                  disabled={disabled || subsidiary.locked}
                />
              )
            }}
          />
        ))}
      </Container>
    )
  }, [subsidiaries])

  if (!isGroup || !isGroupHead) {
    return (
      <Alert
        className={`intake__alert--bottom ${disabled ? 'hidden' : ''}`}
        severity="info"
        content={sections.alert.finalize}
      />
    )
  }

  return (
    <>
      <h2 style={{ marginTop: 0 }}>{sections.obligations.heading}</h2>
      {sections.obligations.intro}

      {renderSubsidiaryObligations}

      {isGroup && isGroupHead && isConsolidatedAllowed && (
        <div className="fade-in">
          <Grid container>
            <h2>{sections.report.heading}</h2>
            <p>{sections.report.intro}</p>
            <Grid
              item
              xs={12}>
              <Controller
                name="consolidated"
                control={control}
                render={({
                  field: { name, value, ref, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectImage
                      required
                      value={value}
                      disabled={disabled}
                      onChange={onChange}
                      options={questions[name].options}
                      alert={{ message: error?.message }}
                      inputRef={ref}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default IntakeVersionAFormStep3
