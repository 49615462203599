import React from 'react'

import './styles.scss'

import { Button } from '@components'
import { ChevronRight, HomeOutlined } from '@mui/icons-material'

interface BreadcrumbItem {
  name: string
  path: string
  selected: boolean
}

const Breadcrumb: React.FC<{ items: BreadcrumbItem[] }> = ({ items = [] }) => {
  return (
    <div className="breadcrumb">
      {items.map((item, index) => {
        const isLast = index === items.length - 1
        return (
          <React.Fragment key={index}>
            <Button
              to={item.path}
              className={`breadcrumb__item${
                isLast ? ' breadcrumb__item--selected' : ''
              }`}
              startIcon={item.name === 'Home' && <HomeOutlined />}>
              {item.name}
            </Button>
            {!isLast && <ChevronRight />}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default Breadcrumb
