import { ReactElement } from 'react'
import './style.scss'

interface Props {
  children: ReactElement
  grid?: string
  gap?: number
  style?: object
}

const Row: React.FC<Props> = ({
  children,
  grid = '1fr 2fr',
  gap = 12,
  style,
}) => {
  return (
    <div
      className="row"
      style={{ gridTemplateColumns: grid, gap: `${gap}px`, ...style }}>
      {children}
    </div>
  )
}

export default Row
