import { dashboardResource } from './resource'

export const dashboardService = {
  loadDashboard: signal =>
    dashboardResource.get('/dashboard', { signal: signal() }),
  queryKvkDatabase: (input, signal) =>
    dashboardResource.get(`/kvk?query=${input}`, { signal: signal() }),
  loadCompanies: signal =>
    dashboardResource.get('/auth/who-am-i', { signal: signal() }),
  loadDashboardCompany: (company_id, signal) =>
    dashboardResource.get(`/dashboard/${company_id}`, { signal: signal() }),
}
