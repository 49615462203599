import { Chip as MUIChip } from '@mui/material'

// Icons
import Clear from '@mui/icons-material/Clear'

interface Props {
  text: any
  icon?: any
  sx?: any
  variant: 'filled' | 'outlined'
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  size?: 'small' | 'medium'
  disabled?: boolean
  clickable?: boolean
  onDelete?: () => void
  onClick: () => void
}

const Chip: React.FC<Props> = ({
  text,
  icon,
  sx,
  size,
  variant = 'outlined',
  clickable = false,
  color,
  disabled,
  onDelete,
  onClick,
}) => {
  const labelWithIcon = (
    <>
      {icon}
      <span>{text}</span>
    </>
  )

  return (
    <MUIChip
      size={size}
      label={icon ? labelWithIcon : text}
      clickable={clickable}
      variant={variant}
      color={color}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={<Clear />}
      disabled={disabled}
      sx={{ ...sx, pointerEvents: !onClick ? 'none' : 'inherit' }}
    />
  )
}

export default Chip
