/* eslint-disable max-len */
export const DECLARATION_VERSION_B_RATIO_SUBTOP = {
  sections: {
    top: {
      subHeading: 'Man-vrouwverhouding',
      heading: 'In de subtop',
    },
    total: {
      heading: 'Hoeveel werknemers had uw bedrijf in de subtop op {{taxYear}}?',
      second_heading: 'In- en uitstroom van werknemers in de subtop',
    },
    evaluation: {
      heading: 'Hoe staat het er voor?',
      subheading: 'Evaluatie',
      intro:
        'U heeft een streefcijfer vastgesteld voor de man-vrouwverhouding in de subtop van {{target}}% voor het jaar {{year}}.',
      no_target:
        'U heeft geen streefcijfer vastgesteld voor de subtop. Dit blok bevat daarom geen verdere vragen.',
      no_reporting_requirement:
        'U heeft geen streefcijfer vastgesteld voor de subtop. Dit blok bevat daarom geen verdere vragen.',
    },
  },
  questions: {
    code_1: {
      name: 'code_1',
      type: 'checkbox',
      label: 'N.v.t. geen subtop / geen medewerkers in leidinggevende functies',
    },
    'code_2.male': {
      input: {
        type: 'number-input',
        name: 'code_2.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
      col_label: 'Aantal mannen',
      label: 'Totaal',
    },
    'code_2.female': {
      input: {
        type: 'number-input',
        name: 'code_2.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
      col_label: 'Aantal vrouwen',
    },
    'code_3.male': {
      label: 'Hoeveel werknemers werden aangesteld in de subtop?',
      col_label: 'Aantal mannen',
      input: {
        type: 'number-input',
        name: 'code_3.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_3.female': {
      col_label: 'Aantal vrouwen',
      input: {
        type: 'number-input',
        name: 'code_3.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_4.male': {
      label: 'Hoeveel werknemers verlieten de subtop in het boekjaar {{year}}?',
      input: {
        type: 'number-input',
        name: 'code_4.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_4.female': {
      input: {
        type: 'number-input',
        name: 'code_4.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    code_5: {
      name: 'code_5',
      type: 'radio-button-group',
      label: 'Heeft u dit streefcijfer op {{taxYear}} gehaald?',
      items: [
        {
          id: 1,
          value: 'yes',
          label: 'Ja',
          style: {
            flex: 1,
            marginRight: '12px',
          },
        },
        {
          id: 2,
          value: 'not_yet',
          label: 'Nee, maar het streefjaar is nog niet bereikt',
        },
        {
          id: 3,
          value: 'no',
          label: 'Nee, en het streefjaar is al wel bereikt',
          style: {
            marginTop: '12px',
          },
        },
      ],
    },
    code_5_yes: {
      name: 'code_5_yes',
      type: 'textarea',
      label: 'Kunt u vertellen hoe u het streefcijfer heeft gerealiseerd?',
    },
    code_5_no: {
      name: 'code_5_no',
      type: 'textarea',
      label: 'Wat is de reden dat dit streefcijfer (nog) niet is gehaald?',
    },
    code_5_not_yet: {
      name: 'code_5_not_yet',
      type: 'radio-button-group',
      label: 'Ligt u op koers om dit streefcijfer te halen?',
      items: [
        {
          id: 1,
          value: 'yes',
          label:
            'Ja, de man-vrouwverhouding is voldoende verbeterd om het streefcijfer te gaan halen',
        },
        {
          id: 2,
          value: 'no',
          label:
            'Nee, de man-vrouwverhouding is onvoldoende verbeterd om het streefcijfer te gaan halen',
          style: {
            marginTop: '12px',
          },
        },
        {
          id: 3,
          value: 'unknown',
          label: 'Weet niet',
          style: {
            marginTop: '12px',
            flex: 1,
          },
        },
      ],
    },
    code_6: {
      name: 'code_6',
      type: 'textarea',
      label:
        'Wat gaat u de komende tijd doen om het streefcijfer alsnog te halen?',
    },
  },
}
