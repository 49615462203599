import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button as MUIButton,
  ButtonBaseProps,
  CircularProgress,
} from '@mui/material'

// Static content
import { CONTENT } from '@constants'
interface ButtonProps extends ButtonBaseProps {
  type?: 'button' | 'submit'
  variant?: 'contained' | 'outlined' | 'text'
  color?: 'primary' | 'secondary'
  onClick?: () => void
  loading?: boolean
  loadingText?: string
  disabled?: boolean
  startIcon?: any
  endIcon?: any
  children: React.ReactNode
  style?: any
  sx?: any
  to?: string
  form?: string
  rest?: any
  component?: any
  className?: string
}

const Button: React.FC<ButtonProps> = ({
  type = 'submit',
  variant,
  color,
  onClick,
  startIcon,
  endIcon,
  loading,
  loadingText,
  disabled,
  children,
  style,
  sx,
  to,
  form,
  ...rest
}) => {
  if (loading) {
    return (
      <MUIButton
        variant={variant}
        color={color}
        disabled
        style={style}
        sx={sx}
        {...rest}>
        {loadingText ? loadingText : CONTENT.ui.button.loading}{' '}
        <CircularProgress
          size={18}
          sx={{ marginLeft: '18px' }}
        />
      </MUIButton>
    )
  }

  return (
    <MUIButton
      type={type}
      variant={variant}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      style={style}
      sx={sx}
      form={form}
      component={to ? Link : undefined}
      to={to || ''}
      {...rest}>
      {children}
    </MUIButton>
  )
}

export default Button
