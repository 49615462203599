/* eslint-disable max-len */

import { DECLARATION_VERSION_B_DETAILS } from './forms/declaration/version-b/details.version-b'
import { DECLARATION_VERSION_B_PROFILE } from './forms/declaration/version-b/profile.version-b'
import { DECLARATION_VERSION_B_RATIO_DIRECTORS } from './forms/declaration/version-b/ratio-directors.version-b'
import { DECLARATION_VERSION_B_RATIO_SUBTOP } from './forms/declaration/version-b/ratio-subtop.version-b'
import { DECLARATION_VERSION_B_RATIO_SUPERVISORY } from './forms/declaration/version-b/ratio-supervisory.version-b'
import { DECLARATION_VERSION_B_TARGET_DIRECTORS } from './forms/declaration/version-b/target-directors.version-b'
import { DECLARATION_VERSION_B_TARGET_SUBTOP } from './forms/declaration/version-b/target-subtop.version-b'
import { DECLARATION_VERSION_B_TARGET_SUPERVISORY } from './forms/declaration/version-b/target-supervisory.version-b'

export const FORMS_DECLARATION = {
  VERSION_B: {
    profile: DECLARATION_VERSION_B_PROFILE,
    details: DECLARATION_VERSION_B_DETAILS,
    target: {
      directors: DECLARATION_VERSION_B_TARGET_DIRECTORS,
      supervisory: DECLARATION_VERSION_B_TARGET_SUPERVISORY,
      subtop: DECLARATION_VERSION_B_TARGET_SUBTOP,
    },
    ratio: {
      directors: DECLARATION_VERSION_B_RATIO_DIRECTORS,
      supervisory: DECLARATION_VERSION_B_RATIO_SUPERVISORY,
      subtop: DECLARATION_VERSION_B_RATIO_SUBTOP,
    },
  },
}
