import { useCallback, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import { toaster } from '@store/toaster.state'
import { ChangeNameForm } from '@partials/forms/profile/change.name.form'
import { ChangePasswordForm } from '@partials/forms/profile/change.password.form'
import { ConnectedUsersForm } from '@partials/forms/profile/connected.users.form'
import { PreferencesForm } from '@partials/forms/profile/preferences.form'

import { profileHooks } from '@services'

import { Container } from '@mui/material'
import { Loader } from '@components'
import { setPartialState, getState } from '@store/ui.store'

const { useListConnectedUsers } = profileHooks

export const ProfileView = () => {
  const [listConnectedUsers, loading, res, err] = useListConnectedUsers()
  const { setSectionColor, setUsername } = useOutletContext()

  useEffect(() => {
    listConnectedUsers({})
    setSectionColor('primary')
  }, [])

  useEffect(() => {
    if (err?.errors) toaster.error('Gebruikers konden niet worden opgehaald')
  }, [err])

  const successHandler = useCallback((res, field = 'wachtwoord') => {
    if (field === 'naam') {
      const name = res.data?.name
      if (name) {
        const authObject = getState('auth')
        authObject.data.name = name
        setPartialState('auth')(authObject)
        localStorage.setItem('auth', JSON.stringify(authObject))
      }
    }

    toaster.success(`Uw ${field} is gewijzigd.`)
  }, [])
  const errorHandler = useCallback((e, field = 'wachtwoord') => {
    if (!e?.errors?.length) {
      return toaster.error(`Uw ${field} kon niet worden aangepast`)
    }

    e.errors.forEach(error => {
      toaster.error(Object.values(error)[0])
    })
  }, [])

  return !res?.data || loading ? (
    <div className="form-loader">
      <Loader text="Instellingen ophalen..." />
    </div>
  ) : (
    <>
      <Container
        style={{
          maxWidth: '1152px',
          marginTop: '-40px',
          marginBottom: '40px',
        }}>
        <h1>Accountinstellingen</h1>
      </Container>
      <div style={{ marginTop: '24px' }}>
        <ChangeNameForm
          onSuccess={data => successHandler(data, 'naam')}
          onError={data => errorHandler(data, 'naam')}
          setUsername={setUsername}
        />
      </div>
      <div style={{ marginTop: '24px' }}>
        <ChangePasswordForm
          onSuccess={successHandler}
          onError={errorHandler}
        />
      </div>
      <div style={{ marginTop: '32px' }}>
        <PreferencesForm
          onSuccess={() => toaster.success('Uw mailvoorkeuren zijn gewijzigd.')}
          onError={() =>
            toaster.error('Uw mailvoorkeuren zijn niet opgeslagen')
          }
        />
      </div>
      <Container style={{ maxWidth: '1152px', margin: '60px auto 0 auto' }}>
        <h1>Bedrijfsinstellingen</h1>
      </Container>
      <div>
        <ConnectedUsersForm
          userData={res?.data || []}
          onUpdate={() => listConnectedUsers({})}
        />
      </div>
    </>
  )
}
