import { Link } from 'react-router-dom'
import { getState } from '@store/ui.store'

// Components
import { AppBar } from '@mui/material'
import { AccountMenu } from '@components'

// Icons
import CloseIcon from '@mui/icons-material/Close'

// Assets
import logo from '@assets/logos/ser_logo.png'

// Styles
import './styles.scss'

const Nav = ({ renderClose, userName }) => {
  const auth = getState('auth')

  return (
    <AppBar position="static">
      <div className="navWrapper">
        <div className="imageWrapper">
          <Link to={'/'}>
            <img
              src={logo}
              width={253}
              height={43}
              alt="Sociaal Economische Raad"
            />
          </Link>
        </div>

        {auth && !renderClose && (
          <div className="navAligner">
            <Link
              className="navLinks"
              to={'/'}>
              Home
            </Link>
            <AccountMenu userName={userName} />
          </div>
        )}

        {renderClose && (
          <Link to={'/'}>
            <div className="closeDeclaration">
              <CloseIcon />
              <span>
                Sluit{' '}
                {typeof renderClose === 'string' ? renderClose : 'rapportage'}
              </span>
            </div>
          </Link>
        )}
      </div>
    </AppBar>
  )
}

export default Nav
