import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

// Components
import { MobileForbidden, Nav, Section } from '@components'
import { Container, Content, Header } from './styles'

// Styles
import '@styles/global.scss'
import { COLORS } from '@constants'

// Assets
import bg_pattern from '@assets/images/bg_pattern_opacity.png'

const GuestLayout = () => {
  const [tooSmall, setTooSmall] = useState(false)

  const handleViewport = () => {
    const width = window.innerWidth
    if (width < 960) setTooSmall(true)
    if (width >= 960) setTooSmall(false)
  }

  useEffect(() => {
    handleViewport()
    window.addEventListener('resize', handleViewport, { passive: true })
    return () => window.removeEventListener('resize', handleViewport)
  }, [])

  return tooSmall ? (
    <MobileForbidden />
  ) : (
    <Container>
      <Header>
        <Nav />
      </Header>
      <Content>
        <Section
          color="default"
          style={{
            backgroundColor: COLORS.tertiary.main,
            backgroundImage: `url(${bg_pattern})`,
            backgroundSize: 'auto',
            backgroundRepeat: 'repeat',
            minHeight: 'calc(100vh - 80px)',
          }}>
          <Outlet />
        </Section>
      </Content>
    </Container>
  )
}

export default GuestLayout
