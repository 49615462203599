import { Container, Grid } from '@mui/material'

// styles
import classes from './styles.module.scss'

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <Container>
        <Grid
          container
          spacing={5}>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.column}>
            <h4 className={classes.heading}>Sociaal-Economische Raad</h4>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.ser.nl/nl/adviezen">
              Adviezen
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.ser.nl/nl/Publicaties">
              Publicaties
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.ser.nl/nl/actueel">
              Actueel
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.ser.nl/nl/thema">
              Thema's
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.ser.nl/nl/ser">
              SER
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.column}>
            <h4 className={classes.heading}>Contact</h4>
            <address className={classes.column}>
              <span>SER Diversiteitsportaal</span>
              <span>Postbus 90405</span>
              <span>2509 LK Den Haag</span>
            </address>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.column}>
            <h4 className={classes.heading}>Helpdesk</h4>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.ser.nl/nl/thema/diversiteitsportaal/helpdesk">
              Helpdesk SER Diversiteitsportaal
            </a>
            <span>
              Email:{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:diversiteitsportaal@ser.nl">
                diversiteitsportaal@ser.nl
              </a>
            </span>
            <span>Tel: 070 - 2 002 532</span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.ser.nl/nl/thema/diversiteitsportaal/helpdesk/faq">
              Veelgestelde vragen
            </a>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
