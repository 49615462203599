import { Chip } from '@components'
import { Box, Grid, Typography } from '@mui/material'
import { useMemo } from 'react'

import { INTAKE_GROUP_RELATION_CHANGE_STATES } from '@constants'

const IntakeGroupRelationChangeCompany = ({
  name,
  status,
  label,
  descriptions = [],
}) => {
  {
    const renderDescription = useMemo(() => {
      if (descriptions.length < 2)
        return (
          <Typography
            color="primary"
            fontSize={14}>
            {descriptions[0]}
          </Typography>
        )

      return (
        <Typography
          color="primary"
          fontSize={14}
          component="ul"
          style={{ margin: 0, paddingLeft: '1.5rem' }}>
          {descriptions.map((description, index) => (
            <li key={index}>{description}</li>
          ))}
        </Typography>
      )
    })

    const renderChip = useMemo(() => {
      const { color, icon: Icon } = INTAKE_GROUP_RELATION_CHANGE_STATES[status]

      return (
        <Chip
          variant="filled"
          text={label}
          sx={{
            fontWeight: 300,
            lineHeight: '2rem',
            height: '2.5rem',
            borderRadius: '2.5rem',
          }}
          color={color}
          icon={<Icon />}
        />
      )
    }, [status, label])

    return (
      <Box
        bgcolor="#f5f5f5"
        flexGrow={1}>
        <Grid
          container
          padding="1rem">
          <Grid
            item
            flexGrow={1}>
            <Typography
              fontSize="medium"
              fontWeight="bold">
              {name}
            </Typography>
            {renderDescription}
          </Grid>
          <Grid
            item
            flexShrink={1}>
            {renderChip}
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default IntakeGroupRelationChangeCompany
