// React
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

// Services
import regex from '@validation/regex'

// Components
import { Grid, Link as MuiLink, Button, CircularProgress } from '@mui/material'
import { TextInput } from '@components'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { authHooks } from '@services'

const { useForgotPassword } = authHooks

export const PasswordForgetForm = ({ onSuccess, onError }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
    },
  })

  const [sendForgetPassword, loading, res, err] = useForgotPassword()

  const signInHandler = useCallback(
    ({ email }) => {
      sendForgetPassword(email)
    },
    [sendForgetPassword]
  )

  useEffect(() => {
    err && onError(err)
    res && onSuccess(res)
  }, [res, err, onSuccess, onError])

  return (
    <form onSubmit={handleSubmit(signInHandler)}>
      <Grid
        container
        justifyContent="center">
        <Grid
          item
          xs={4}
          style={{ backgroundColor: '#fff', padding: '1rem 1.5rem' }}>
          <Grid
            item
            xs={12}>
            <h2>Wachtwoord vergeten?</h2>
          </Grid>

          <Grid
            item
            mb={2.5}>
            <TextInput
              label={'E-mailadres'}
              placeholder={'naam@domein.nl'}
              required
              {...register('email', {
                required: {
                  value: true,
                  message: 'E-mailadres is verplicht.',
                },
                pattern: {
                  value: regex.email,
                  message: 'Dit is geen geldig e-mailadres.',
                },
              })}
              {...(errors.email?.message && {
                alert: {
                  message: errors.email?.message,
                },
              })}
            />
          </Grid>

          <Grid
            container
            pt={2}
            justifyContent={'space-between'}>
            <Grid item>
              <MuiLink
                component={Link}
                to={'/login'}
                color={'primary'}>
                Terug naar inloggen
              </MuiLink>
            </Grid>
          </Grid>

          <Grid
            container
            pt={2}
            justifyContent={'end'}>
            <Grid item>
              <Button
                disabled={!!loading}
                type={'submit'}
                color="primary"
                variant="contained"
                endIcon={
                  loading ? (
                    <CircularProgress
                      color={'primary'}
                      size={20}
                    />
                  ) : (
                    <ArrowForwardIcon
                      color="buttonIcon"
                      size={12}
                    />
                  )
                }>
                {(loading && 'Versturen') || 'Volgende'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
