import { useCallback, useEffect } from 'react'
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom'
import { toaster } from '@store/toaster.state'
import { PasswordResetForm } from '@partials/forms/authentication/password.reset.form'

export const PasswordResetView = () => {
  const token = useSearchParams()[0].get('token')
  const navigate = useNavigate()

  const successHandler = useCallback(
    res => {
      const { message } = res
      toaster.success(message)
      navigate('/login')
    },
    [navigate]
  )

  const errorHandler = useCallback(({ errors }) => {
    if (!errors?.forEach) return toaster.error('Er is een fout opgetreden')

    toaster.error(errors.map(err => Object.values(err)[0]))
  }, [])

  return (
    <PasswordResetForm
      onSuccess={successHandler}
      onError={errorHandler}
      token={token}
    />
  )
}
