import fallback from './forms/fallback'

// Forms
import intakeForm from './forms/intake'
import companyCompany from './forms/sections/company/company'
import companyDetails from './forms/sections/company/details'
import companyStructure from './forms/sections/company/structure'
import boardDirectors from './forms/sections/current/boardDirectors'
import targetForm from './forms/sections/target/target'
import supervisoryBoard from './forms/sections/current/supervisoryBoard'
import currentSubtop from './forms/sections/current/subtop'
import currentOverview from './forms/sections/current/overview'
import canvasExplainer from './forms/sections/canvas/explainer'
import canvasDownload from './forms/sections/canvas/download'
import canvasPlan from './forms/sections/canvas/plan'
import reviewSubmit from './forms/sections/submit/review'
import submitSignature from './forms/sections/submit/signature'
import submitSuccess from './forms/sections/submit/success'
import ratioDirectors from './forms/sections/ratio/directors'
import ratioSupervisory from './forms/sections/ratio/supervisory'
import ratioSubtop from './forms/sections/ratio/subtop'
import ratioOverview from './forms/sections/ratio/overview'

const forms = {
  loading: {
    text: fallback.loading,
  },
  next: {
    text: 'Volgende',
  },
  intake: intakeForm,
  company: {
    company: companyCompany,
    details: companyDetails,
    structure: companyStructure,
  },
  current: {
    supervisoryBoard,
    boardDirectors,
    subtop: currentSubtop,
    overview: currentOverview,
  },
  canvas: {
    explainer: canvasExplainer,
    plan: canvasPlan,
    download: canvasDownload,
  },
  submit: {
    review: reviewSubmit,
    signature: submitSignature,
    success: submitSuccess,
  },
  target: {
    target: targetForm,
  },
  ratio: {
    directors: ratioDirectors,
    supervisory: ratioSupervisory,
    subtop: ratioSubtop,
    overview: ratioOverview,
  },
}

export default forms
