import React, { useCallback, useEffect, useRef, useState } from 'react'

// Components
import { NumberCounter } from '@components'

// Styles
import './styles.scss'

interface Props {
  targetNumber: string
  currentNumber: string
  targetYear: string
  taxYear: string
}

const GenderChart: React.FC<Props> = ({
  targetNumber,
  currentNumber,
  targetYear,
  taxYear,
}) => {
  const [target, setTarget] = useState(0)
  const [current, setCurrent] = useState(0)
  const [barWidth, setBarWidth] = useState({
    target: 0,
    current: 0,
  })

  const totalRef = useRef(null)
  const currentRef = useRef(null)
  const targetRef = useRef(null)

  const getWidth = (el: any) => {
    return parseInt(el.getBoundingClientRect().width)
  }

  const handleBarWidth = useCallback(() => {
    const totalWidth = getWidth(totalRef.current)
    const targetWidth = (totalWidth / 100) * target
    const currentWidth = (totalWidth / 100) * current
    setBarWidth({
      target: targetWidth,
      current: currentWidth,
    })
  }, [current, target])

  useEffect(() => {
    handleBarWidth()
    window.addEventListener('resize', handleBarWidth)
    return () => window.removeEventListener('resize', handleBarWidth)
  }, [targetRef, currentRef, target, current, handleBarWidth])

  useEffect(() => {
    const target: number =
      typeof targetNumber === 'string' ? parseInt(targetNumber) : targetNumber
    const current: number =
      typeof currentNumber === 'string'
        ? parseInt(currentNumber)
        : currentNumber
    setTarget(target || 0)
    setCurrent(current || 0)
  }, [targetNumber, currentNumber])

  const animations = {
    current: {
      bar: { width: `${current}%` },
      legend: { transform: `translateX(${barWidth.current}px)` },
      position: { transform: 'translateX(-75px)' },
    },
    target: {
      bar: { width: `${target}%` },
      legend: { transform: `translateX(${barWidth.target}px)` },
      position: { transform: 'translateX(-75px)' },
    },
  }

  return (
    <div className="wrapper">
      <div className="bars">
        <div
          className="bar"
          id="bar--total"
          ref={totalRef}
        />
        <div
          className="bar"
          id="bar--target"
          ref={targetRef}
          style={animations.target.bar}>
          <div
            className="legend"
            id="legend--target"
            style={animations.target.legend}>
            <span
              style={animations.target.position}
              className="tooltip">
              Streefcijfer voor {targetYear}
            </span>
            <span
              style={animations.target.position}
              className="percentage">
              <strong>
                <NumberCounter
                  initialVal={0}
                  newVal={target}
                />
                %
              </strong>
            </span>
          </div>
        </div>
        <div
          className="bar"
          id="bar--current"
          ref={currentRef}
          style={animations.current.bar}>
          <div
            className="legend"
            id="legend--current"
            style={animations.current.legend}>
            <span
              className="percentage"
              style={animations.current.position}>
              <strong>
                <NumberCounter
                  initialVal={0}
                  newVal={current}
                />
                %
              </strong>
            </span>
            <div
              style={animations.current.position}
              className="tooltip">
              <div>% vrouwen</div>
              <div> {taxYear}</div>
            </div>
          </div>
        </div>
        <span id="legend--start">0%</span>
        <span id="legend--end">100%</span>
      </div>
    </div>
  )
}

export default GenderChart
