/* eslint-disable max-len */
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Components
import { BooleanQuestion, TextInput } from '@components'
import { Grid } from '@mui/material'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

// Validation

// Hooks
import { Button, Panel } from '@components'
import { profileHooks } from '@services'

const { useAddContact } = profileHooks

export const AddContactForm = ({ onSuccess, onError, company_id }) => {
  let formValues = {
    first_name: '',
    last_name: '',
    email: '',
    administrative: null,
    company_id,
  }

  const {
    register,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: formValues,
  })

  const [addContact, loading, res, err] = useAddContact()
  const registeredUser = {
    first_name: watch('first_name'),
    last_name: watch('last_name'),
  }
  const addContactHandler = useCallback(
    formData => {
      addContact({ ...formData })
    },
    [addContact]
  )

  useEffect(() => {
    err && onError(err)
    if (res) {
      onSuccess(registeredUser)
      reset()
    }
  }, [res, err, onSuccess, onError, reset, setValue, registeredUser])

  return (
    <form onSubmit={handleSubmit(addContactHandler)}>
      <Panel style={{ flexDirection: 'column', padding: 0 }}>
        <Grid
          item
          xs={12}
          md={12}>
          <TextInput
            type="text"
            label={'Voornaam'}
            {...register('first_name', {
              required: {
                value: true,
                message: 'Voornaam is een verplicht veld.',
              },
            })}
            {...(errors.first_name?.message && {
              alert: {
                message: errors.first_name?.message,
              },
            })}
          />
          <TextInput
            type="text"
            label={'Achternaam'}
            {...register('last_name', {
              required: {
                value: true,
                message: 'Achternaam is een verplicht veld.',
              },
            })}
            {...(errors.last_name?.message && {
              alert: {
                message: errors.last_name?.message,
              },
            })}
          />
          <TextInput
            type="email"
            label={'E-mail'}
            {...register('email', {
              required: {
                value: true,
                message: 'E-mail is een verplicht veld.',
              },
            })}
            {...(errors.email?.message && {
              alert: {
                message: errors.email?.message,
              },
            })}
          />
          <Controller
            control={control}
            name="administrative"
            render={({
              field: { name, value, ref, onChange },
              fieldState: { error },
            }) => {
              return (
                <BooleanQuestion
                  name={name}
                  required
                  vertical
                  value={value}
                  onChange={onChange}
                  question="Bestuurlijk verantwoordelijke"
                  alert={{ message: error?.message }}
                  inputRef={ref}
                />
              )
            }}
          />
        </Grid>
        <div style={styles.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            loading={loading}
            endIcon={<ArrowForwardIcon />}>
            Contactpersoon toevoegen
          </Button>
        </div>
      </Panel>
    </form>
  )
}

const styles = {
  buttonWrapper: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
}
