import jsPDF from 'jspdf'

const margins = 10 // Define a 20mm margin on all sides

const config = {
  portrait: {
    orientation: 'p',
    width: 210 - margins * 2,
    height: 297 - margins * 2,
    margin: margins,
  },
  landscape: {
    orientation: 'l',
    width: 297 - margins * 2,
    height: 210 - margins * 2,
    margin: margins,
  },
}

export const imageToPDF = (
  canvas: HTMLCanvasElement,
  orientation: 'portrait' | 'landscape' = 'portrait'
) => {
  const imgData = canvas
    .toDataURL('image/jpeg', 0.7) // Use JPEG to reduce file size
    .replace('image/jpeg', 'image/octet-stream')

  if (!imgData.includes('image/octet-stream')) return null

  const doc = new jsPDF('p', 'mm', 'a4')

  const imgWidth = config[orientation].width
  const pageHeight = doc.internal.pageSize.height
  const imgHeight = (canvas.height * imgWidth) / canvas.width
  let heightLeft = imgHeight

  let position = margins

  doc.addImage(imgData, 'JPEG', margins, position, imgWidth, imgHeight)
  heightLeft -= pageHeight

  while (heightLeft >= 0) {
    position = heightLeft + margins * 2 - imgHeight
    doc.addPage()
    doc.addImage(imgData, 'JPEG', margins, position, imgWidth, imgHeight)
    heightLeft -= pageHeight
  }

  return doc.output('arraybuffer')
}
