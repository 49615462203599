const styles = {
  radioWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
  radioBox: {
    minHeight: '50px',
    marginRight: 0,
  },
  radio: {
    padding: '0px',
    marginRight: '12px',
  },
}

export default styles
