/* eslint-disable max-len */
export const DECLARATION_VERSION_B_RATIO_SUPERVISORY = {
  sections: {
    top: {
      subHeading: 'Man-vrouwverhouding',
      heading: 'In de raad van commissarisen van {{company}}',
    },
    total: {
      heading: 'Hoeveel vrouwen en mannen had de RvC op {{taxYear}}?',
    },
    appointments: {
      heading:
        'Hoeveel benoemingen en herbenoemingen zijn er geweest in de RvC in het boekjaar {{year}}?',
    },
    director: {
      heading: 'Voorzitter',
    },
    evaluation: {
      heading: 'Hoe staat het er voor?',
      subheading: 'Evaluatie',
      intro:
        'U heeft een streefcijfer vastgesteld voor de man-vrouwverhouding in de RvC van {{target}}% voor het jaar {{year}}.',
      no_target:
        'U heeft geen streefcijfer vastgesteld voor de RvC. Dit blok bevat daarom geen verdere vragen.',
      no_reporting_requirement:
        'U heeft geen streefcijfer vastgesteld voor de RvC. Dit blok bevat daarom geen verdere vragen.',
    },
  },
  questions: {
    code_1: {
      name: 'code_1',
      type: 'checkbox',
      label:
        'N.v.t. geen raad van commissarissen / geen niet-uitvoerende bestuurders',
    },
    'code_2.male': {
      input: {
        type: 'number-input',
        name: 'code_2.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
      col_label: 'Aantal mannen',
      label: 'Totaal op {{taxYear}}',
    },
    'code_2.female': {
      input: {
        type: 'number-input',
        name: 'code_2.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
      col_label: 'Aantal vrouwen',
    },
    code_3: {
      label: 'Aantal niet ingevulde vacatures {{taxYear}}',
      input: {
        type: 'number-input',
        name: 'code_3',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_4.male': {
      label: 'Herbenoemd',
      col_label: 'Aantal mannen',
      input: {
        type: 'number-input',
        name: 'code_4.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_4.female': {
      col_label: 'Aantal vrouwen',
      input: {
        type: 'number-input',
        name: 'code_4.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_5.male': {
      label: 'Nieuw benoemd',
      input: {
        type: 'number-input',
        name: 'code_5.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_5.female': {
      input: {
        type: 'number-input',
        name: 'code_5.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_6.male': {
      label: 'Afgetreden',
      input: {
        type: 'number-input',
        name: 'code_6.male',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    'code_6.female': {
      input: {
        type: 'number-input',
        name: 'code_6.female',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
    },
    code_7: {
      name: 'code_7',
      type: 'radio-button-group',
      label: 'De voorzitter identificeert zich als:',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
      items: [
        {
          id: 1,
          value: 'male',
          label: 'Man',
        },
        {
          id: 2,
          value: 'female',
          label: 'Vrouw',
        },
        {
          id: 3,
          value: 'non-binary',
          label: 'Non-binair',
        },
        {
          id: 4,
          value: 'other',
          label: 'Anders dan bovenstaande',
          style: {
            marginTop: '12px',
          },
        },
        {
          id: 5,
          value: 'no-chair',
          label: 'Er is geen voorzitter',
          style: {
            marginTop: '12px',
          },
        },
        {
          id: 6,
          value: 'not-saying',
          label: 'Dat zeggen wij liever niet',
          style: {
            marginTop: '12px',
          },
        },
      ],
    },
    code_8: {
      name: 'code_8',
      type: 'radio-button-group',
      label: 'Heeft u dit streefcijfer op {{taxYear}} gehaald?',
      items: [
        {
          id: 1,
          value: 'yes',
          label: 'Ja',
          style: {
            flex: 1,
            marginRight: '12px',
          },
        },
        {
          id: 2,
          value: 'not_yet',
          label: 'Nee, maar het streefjaar is nog niet bereikt',
        },
        {
          id: 3,
          value: 'no',
          label: 'Nee, en het streefjaar is al wel bereikt',
          style: {
            marginTop: '12px',
          },
        },
      ],
    },
    code_8_yes: {
      name: 'code_8_yes',
      type: 'textarea',
      label: 'Kunt u vertellen hoe u het streefcijfer heeft gerealiseerd?',
    },
    code_8_no: {
      name: 'code_8_no',
      type: 'textarea',
      label: 'Wat is de reden dat dit streefcijfer (nog) niet is gehaald?',
    },
    code_8_not_yet: {
      name: 'code_8_not_yet',
      type: 'radio-button-group',
      label: 'Ligt u op koers om dit streefcijfer te halen?',
      items: [
        {
          id: 1,
          value: 'yes',
          label:
            'Ja, de man-vrouwverhouding is voldoende verbeterd om het streefcijfer te gaan halen',
        },
        {
          id: 2,
          value: 'no',
          label:
            'Nee, de man-vrouwverhouding is onvoldoende verbeterd om het streefcijfer te gaan halen',
          style: {
            marginTop: '12px',
          },
        },
        {
          id: 3,
          value: 'unknown',
          label: 'Weet niet',
          style: {
            marginTop: '12px',
            flex: 1,
          },
        },
      ],
    },
    code_9: {
      name: 'code_9',
      type: 'textarea',
      label:
        'Wat gaat u de komende tijd doen om het streefcijfer alsnog te halen?',
    },
  },
}
