import { createFetchHooks } from '@acato/react-fetch'
import { dashboardService } from './service'

const handlers = (resCb = null, errCb = null) => ({
  responseHandler: res => (resCb && resCb(res?.data)) || res?.data,
  errorHandler: ({ errors }) => (errCb && errCb({ errors })) || { errors },
})

export const dashboardHooks = createFetchHooks(
  {
    name: 'useLoadDashboard',
    fetcher: dashboardService.loadDashboard,
    ...handlers(),
  },
  {
    name: 'useLoadDashboardCompany',
    fetcher: dashboardService.loadDashboardCompany,
    ...handlers(),
  },
  {
    name: 'useKVKDatabase',
    fetcher: dashboardService.queryKvkDatabase,
    ...handlers(),
  },
  {
    name: 'useLoadCompanies',
    fetcher: dashboardService.loadCompanies,
    ...handlers(),
  }
)
