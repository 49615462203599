import { Fragment } from 'react'
import { Box, InputAdornment, Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { FormInput, Row } from '@components'
import { LABELS } from './DeclarationTargetNumbers.constants'

export const DeclarationTargetNumbersPreviousYear = ({
  name,
  disabled,
  isPrefilled,
}) => {
  return (
    <>
      <Row grid="1fr 1fr">
        <FormInput
          rootElement={Fragment}
          label={LABELS.TARGET_NUMBER}
          name={name + '.previous_target_v2'}
          type="number-input"
          disabled={disabled || isPrefilled}
          toFixed={2}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ boxSizing: 'content-box', p: '8px 0' }}>
                <Tooltip title={LABELS.TARGET_NUMBER_TOOLTIP}>
                  <InfoOutlined sx={{ m: '-16px', p: '16px' }} />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <FormInput
          rootElement={Fragment}
          name={name + '.previous_year_v2'}
          label={LABELS.TARGET_YEAR}
          type="number-input"
          disabled={disabled || isPrefilled}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ boxSizing: 'content-box', p: '8px 0' }}>
                <Tooltip title={LABELS.TARGET_YEAR_TOOLTIP}>
                  <InfoOutlined sx={{ m: '-16px', p: '16px' }} />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Row>
      <FormInput
        rootElement={Box}
        rootProps={{ paddingY: 2 }}
        name={name + '.overwrite_targets_v2'}
        label={LABELS.TARGET_RENEW}
        type="checkbox"
        disabled={disabled}
        required
      />
    </>
  )
}
