import { useEffect, useMemo } from 'react'
import { getState, setPartialState } from '@store/ui.store'
import { generatePath, useOutletContext } from 'react-router-dom'
import { dashboardHooks, profileHooks } from '@services/index'

// Components
import { Container } from '@mui/material'
import { Button } from '@components'

// Styles
import styles from './styles'
import { AdditionalInformation } from '@components'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Loader } from '@components'
import { toaster } from '@store/toaster.state'

export const DashboardView = () => {
  // State
  const auth = getState('auth')

  // Hooks
  const { useLoadDashboard, useLoadCompanies } = dashboardHooks
  const { useClaimCompany } = profileHooks
  const { setSectionColor, setRenderClose } = useOutletContext()
  const [getDashboard, loadingDashboard, dashboardResponse] = useLoadDashboard()
  const [
    claimCompany,
    loadingClaimCompany,
    claimCompanyResponse,
    claimCompanyError,
  ] = useClaimCompany()
  const [loadCompanies, loadingCompanies, loadCompanyResponse] =
    useLoadCompanies()

  const setAuth = setPartialState('auth')

  const updateCompanyState = ({ data }) => {
    const authObject = { data, token: getState('auth.token') }
    setAuth(authObject)
    localStorage.setItem('auth', JSON.stringify(authObject))
  }

  useEffect(() => {
    setSectionColor('primary')
    setRenderClose(false)
  }, [setSectionColor, setRenderClose])

  useEffect(() => {
    getDashboard()
  }, [])

  useEffect(() => {
    if (!claimCompanyResponse) return

    toaster.success(claimCompanyResponse?.message || 'Uitnodiging geaccepteerd')
    getDashboard()
    loadCompanies()
  }, [claimCompanyResponse])

  useEffect(() => {
    if (!claimCompanyError) return

    toaster.error(claimCompanyError?.errors?.[0]?.[0] || 'Uitnodiging ongeldig')
    getDashboard()
    loadCompanies()
  }, [claimCompanyError])

  useEffect(() => {
    if (loadCompanyResponse) updateCompanyState(loadCompanyResponse)
  }, [loadCompanyResponse])

  const renderCompanyCards = useMemo(() => {
    if (!dashboardResponse?.data?.companies?.length) return null

    const { companies } = dashboardResponse.data
    return companies.map(company => (
      <div
        style={styles.card}
        key={company.id}>
        <h2 style={styles.card__title}>{company.name}</h2>
        <p style={styles.card__content}>KvK nr.: {company.kvk_number}</p>
        <div>
          <Button
            to={generatePath('/company/:company_id', {
              company_id: company.id,
            })}
            variant="contained"
            endIcon={<ArrowForwardIcon />}>
            Naar bedrijfspagina
          </Button>
        </div>
      </div>
    ))
  }, [dashboardResponse])

  const renderInviteCards = useMemo(() => {
    if (!dashboardResponse?.data?.invites?.length) return null

    const { invites } = dashboardResponse.data
    return invites.map(({ company, token }) => (
      <div
        style={{ ...styles.card, flexDirection: 'row', alignItems: 'center' }}
        key={company.id}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <strong>Uitnodiging accepteren van {company.name}</strong>
          <p style={{ margin: '4px 0' }}>
            {company.name} heeft u uitgenodigd de rapportage voor de wet
            ingroeiquotum en streefcijfers te beheren.
          </p>
        </div>
        <div>
          <Button
            onClick={() => claimCompany(token)}
            variant="contained">
            Accepteren
          </Button>
        </div>
      </div>
    ))
  }, [dashboardResponse])

  return (
    <div style={styles.page}>
      <Container>
        {!dashboardResponse?.data || loadingDashboard ? (
          <Loader
            text="Dashboard aan het laden..."
            style={{ minHeight: '60vh' }}
          />
        ) : (
          <>
            <h1 style={styles.heading}>Welkom {auth.data.name}</h1>
            <div style={styles.invite_cards}>{renderInviteCards}</div>
            {!!dashboardResponse?.data?.companies?.length && (
              <>
                <h2>Mijn bedrijven</h2>
                <div style={styles.company_cards}>{renderCompanyCards}</div>
              </>
            )}
            <div style={{ marginTop: '84px' }}>
              <AdditionalInformation />
            </div>
          </>
        )}
      </Container>
    </div>
  )
}

export default DashboardView
