import { createFetchHooks } from '@acato/react-fetch'
import { declarationService } from './service'

const handlers = (resCb = null, errCb = null) => ({
  responseHandler: res => (resCb && resCb(res?.data)) || res?.data,
  errorHandler: ({ errors }) => (errCb && errCb({ errors })) || { errors },
})

export const declarationHooks = createFetchHooks(
  {
    name: 'useDeclaration',
    fetcher: declarationService.fetchDeclaration,
    ...handlers(),
  },
  {
    name: 'useDeclarationUpdate',
    fetcher: declarationService.updateDeclaration,
    ...handlers(),
  },
  {
    name: 'useFetchPreferences',
    fetcher: declarationService.fetchPreferences,
    ...handlers(),
  },
  {
    name: 'usePreferencesUpdate',
    fetcher: declarationService.updatePreferences,
    ...handlers(),
  },
  {
    name: 'useDeclarationActivities',
    fetcher: declarationService.fetchActivities,
    ...handlers(),
  },
  {
    name: 'useDeclarationFocus',
    fetcher: declarationService.setActivityFocus,
    ...handlers(),
  },
  {
    name: 'useAddDeclarationActivities',
    fetcher: declarationService.addActivity,
    ...handlers(),
  },
  {
    name: 'useDeclarationPlan',
    fetcher: declarationService.fetchPlan,
    ...handlers(),
  },
  {
    name: 'useDeclarationCanvas',
    fetcher: declarationService.downloadCanvas,
    ...handlers(),
  },
  {
    name: 'useDeclarationExport',
    fetcher: declarationService.generateExportPDF,
    ...handlers(),
  }
)
