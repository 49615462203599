import { FormEvent, useEffect, useState } from 'react'
import { ChangeEvent } from 'react'

// Hooks
import { dashboardHooks } from '@services/index'

// Components
import { CircularProgress } from '@mui/material'
import TextInput from '../../core/TextInput'
import { toaster } from '@store/toaster.state'

// Icons
import Search from '@mui/icons-material/Search'

// styles
import './styles.scss'

interface Props {
  setResult: (e: object | null) => void
  current_kvk: string
}

const SearchKVK: React.FC<Props> = ({ setResult, current_kvk }) => {
  // Hooks
  const { useKVKDatabase } = dashboardHooks
  const [queryKvkDatabase, loading, res, err] = useKVKDatabase()

  // Initial state
  const [kvkInput, setKvkInput] = useState('')

  const searchDatabase = (e?: FormEvent | MouseEvent) => {
    e?.preventDefault()
    if (!kvkInput) return
    if (current_kvk.toString() === kvkInput) {
      return toaster.error(
        'U kunt niet het kvk nummer van uw huidige intake gebruiken om een bedrijf op te zoeken.'
      )
    }
    setResult([])
    queryKvkDatabase(kvkInput)
  }

  useEffect(() => {
    if (res?.data?.length) {
      const companies: Array<{ id: number; name: string; city: string }> =
        res.data
      const uniqueCompanies = companies.filter(company => {
        const similarCompanies = companies.filter(({ id }) => id === company.id)

        if (similarCompanies.length === 1) return true

        const similarCompaniesGotCity = companies.filter(
          ({ id, city }) => id === company.id && !!city
        )

        return similarCompaniesGotCity.length >= 1 ? !!company.city : true
      })

      setResult(uniqueCompanies)
    }
    if (err) {
      setResult(null)
      const error =
        typeof err.errors === 'string' ? err.errors : err.errors?.[0]?.[0]
      toaster.error(error || 'Er is een fout opgetreden')
    }
  }, [res, err, setResult])

  return (
    <form
      className="search"
      onSubmit={searchDatabase}>
      <TextInput
        required
        value={kvkInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setKvkInput(e.target.value)
        }
        label="Zoek op naam of KvK-nummer"
      />
      <div
        className="search__button"
        onClick={searchDatabase}>
        {loading ? (
          <CircularProgress
            size={16}
            sx={{ color: '#FFF' }}
          />
        ) : (
          <Search
            className="search-icon"
            sx={{ color: '#FFF' }}
          />
        )}
      </div>
    </form>
  )
}

export default SearchKVK
