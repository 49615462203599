import React from 'react'
import { Alert as MUIAlert, AlertTitle, Button } from '@mui/material'

import styles from './styles'

interface Props {
  severity: 'error' | 'warning' | 'info' | 'success'
  title?: string
  content?: string
  action?: string
  sx?: any
  icon?: any
  className?: string
}

const Alert: React.FC<Props> = ({
  className,
  severity,
  title,
  content,
  action,
  sx,
  icon,
  ...rest
}) => {
  return (
    <MUIAlert
      sx={sx}
      severity={severity}
      action={<Button style={styles.actionButton}>{action}</Button>}
      icon={icon}
      className={className}
      {...rest}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {content}
    </MUIAlert>
  )
}

export default Alert
