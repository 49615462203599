import { authResource } from './resource'

export const authService = {
  login: (email, password, signal) =>
    authResource.post('/login', { email, password }, { signal: signal() }),
  forgotPassword: (email, signal) =>
    authResource.post('/forgot-password', { email }, { signal: signal() }),
  resetPassword: (data, signal) =>
    authResource.post('/reset-password', { ...data }, { signal: signal() }),
  createAccount: (data, signal) =>
    authResource.post('/register', { ...data }, { signal: signal() }),
  getInvitation: (token, signal) =>
    authResource.get('/invites/' + token, { signal: signal() }),
}
