import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { LoginForm } from '@partials/forms/authentication/login.form'
import { toaster } from '@store/toaster.state'
import { modal } from '@store/modal.state'
import { setPartialState } from '@store/ui.store'

import WelcomeModal from '@partials/modals/WelcomModal'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const setAuth = setPartialState('auth')

export const LoginView = ({ defaultRoute }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const successHandler = useCallback(
    res => {
      const { access_token: token, data, show_modal } = res

      setAuth({ data, token })

      if (show_modal) {
        modal.setOpen({
          heading: 'Welkom bij de SER',
          content: <WelcomeModal />,
          buttons: [
            {
              id: 1,
              text: 'Ga naar home',
              variant: 'contained',
              color: 'primary',
              style: { margin: '-16px 16px 16px 16px' },
              endIcon: <ArrowForwardIcon />,
            },
          ],
        })
      }
      toaster.success(`Welkom ${data.name}`)
      const origin = location.state?.from?.pathname
      navigate(origin || defaultRoute)
    },
    [navigate, location, defaultRoute]
  )
  const errorHandler = useCallback(({ errors }) => {
    if (!Array.isArray(errors))
      return toaster.error('Er is een fout opgetreden')

    errors.forEach(error => {
      toaster.error(Object.values(error)?.[0] || 'Er is een fout opgetreden')
    })
  }, [])

  return (
    <main>
      <LoginForm
        onSuccess={successHandler}
        onError={errorHandler}
      />
    </main>
  )
}
