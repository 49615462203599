import { Fragment } from 'react'
import { Box } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { FormInput, Row, Alert } from '@components'
import { LABELS } from './DeclarationTargetNumbers.constants'

export const DeclarationTargetNumbersFirstSubmit = ({
  name,
  disabled,
  exclude,
  department_name,
  minmax,
}) => {
  return (
    <>
      <Collapse
        in={!exclude}
        orientation="vertical">
        <Row grid="1fr 1fr">
          <FormInput
            rootElement={Fragment}
            name={name + '.target_v2'}
            label={LABELS.TARGET_NUMBER}
            type="number-input"
            disabled={disabled || exclude}
            toFixed={2}
            options={{
              inputProps: {
                min: minmax?.target?.min,
                max: minmax?.target?.max,
              },
            }}
          />
          <FormInput
            rootElement={Fragment}
            name={name + '.year_v2'}
            label={LABELS.TARGET_YEAR}
            type="number-input"
            disabled={disabled || exclude}
            options={{
              inputProps: {
                min: minmax?.year?.min,
                max: minmax?.year?.max,
              },
            }}
          />
        </Row>
      </Collapse>
      <FormInput
        rootElement={Box}
        rootProps={{ paddingY: 2 }}
        name={name + '.excluded_v2'}
        label={LABELS.TARGET_EXCLUDE(department_name)}
        type="checkbox"
        disabled={disabled}
        required
      />
      <Collapse in={exclude}>
        <Alert
          severity="info"
          content={LABELS.TARGET_EXCLUDE_REASON}
        />
      </Collapse>
    </>
  )
}
