/* eslint-disable max-len */
import { modal } from '@store/modal.state'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Button, Chip } from '@components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Grid,
  Tooltip,
} from '@mui/material'
import AccordionRow from './AccordionRow'
import Parent from './Parent'
import SubSidiary from './Subsidiary'

// Modals
import StartDeclarationModal from '@partials/modals/StartDeclarationModal'

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

// Utils
import getDate from '@helpers/dates/getDate'
import renderDeclarationButton from '@helpers/view/renderDeclarationButton'

// Styles
import './styles.scss'

// Constants
import { CONTENT } from '@constants'
import { InfoOutlined } from '@mui/icons-material'

import OptionsMenu from './OptionsMenu'

const DashboardAccordion = ({ data }) => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(false)

  const {
    actions,
    year,
    deadline,
    subsidiaries,
    company,
    intake_id,
    intake_status,
    declaration_id,
    declaration_status,
    group_head,
    consolidated,
  } = data

  const reportDeadline = getDate(deadline).pretty
  const allowIntake = actions.can_start_intake
  const allowDeclaration = actions.can_start_declaration

  const hasOpenSubsidiaryRequests =
    subsidiaries?.find(sub => sub.request?.status === 'open') && !allowIntake
  const hasOpenParentRequests =
    group_head?.request?.status === 'open' && !allowIntake
  const isParentRequestDenied =
    group_head?.request?.status === 'denied-by-company'
  const isSubsidiaryRequestDenied = subsidiaries?.find(
    sub => sub.request?.status === 'denied-requested-company-finished-intake'
  )
  const hasGroupHeadRequestOrSubs =
    !allowIntake &&
    (group_head?.request?.status === 'open' || subsidiaries?.length > 0)

  const declarationFinished = declaration_status === 'finished'
  const noReportingRequired =
    group_head?.request?.status === 'accepted-by-company' &&
    (group_head?.request?.type ===
      'you-are-my-group-head-and-you-will-take-care-of-my-declaration' ||
      group_head?.request?.type ===
        'you-are-my-subsidiary-and-i-will-take-care-of-your-declaration')
  const openRequests = intake_status === 'submitted' && !declaration_id
  const waitingForIntake = intake_status === 'prefilled-by-intake-requests'

  // Methods
  const startDeclaration = event => {
    if (expanded) {
      event?.preventDefault?.()
      event?.stopPropagation?.()
    }

    if (declarationFinished || declaration_status === 'in_progress') {
      return navigate(`/declaration/${declaration_id}`)
    }
    const modalBody = {
      heading: 'Let op',
      content: (
        <StartDeclarationModal
          year={year}
          company={company}
          consolidated={consolidated}
          subsidiaries={subsidiaries}
        />
      ),
      buttons: [
        {
          id: 1,
          text: 'Ga verder',
          to: `/declaration/${declaration_id}`,
          type: 'contained',
          color: 'primary',
        },
      ],
    }
    modal.setOpen(modalBody)
  }

  return (
    <Accordion
      className="Dashboard-accordion"
      expanded={expanded}>
      <AccordionSummary
        onClick={() => {
          if (hasGroupHeadRequestOrSubs) setExpanded(!expanded)
        }}
        sx={{
          cursor: hasGroupHeadRequestOrSubs ? undefined : 'default !important',
        }}
        expandIcon={hasGroupHeadRequestOrSubs && <ExpandMoreIcon />}>
        <div className="SummaryTitle-left">
          <h2
            className="summary-title"
            style={{ width: 128, padding: '0 24px', fontSize: '36px' }}>
            {year}
          </h2>

          {hasOpenParentRequests || hasOpenSubsidiaryRequests ? (
            <Chip
              variant="contained"
              text="Openstaande verzoeken"
              color="info"
              icon={<InfoOutlined />}
            />
          ) : isParentRequestDenied ? (
            <Tooltip title="Het acceptatieverzoek is afgewezen. Herstart de rapportage om opnieuw te beginnen.">
              <div>
                <Chip
                  variant="filled"
                  color="error"
                  text={`Acceptatieverzoek aan ${
                    group_head?.company?.name || 'groepshoofd'
                  } afgewezen`}
                />
              </div>
            </Tooltip>
          ) : isSubsidiaryRequestDenied ? (
            <Tooltip title="Het acceptatieverzoek is afgewezen. Herstart de rapportage om opnieuw te beginnen.">
              <div>
                <Chip
                  variant="filled"
                  color="error"
                  text={`Eén of meer groepsmaatschappijen hebben uw verzoek niet geaccepteerd.`}
                />
              </div>
            </Tooltip>
          ) : waitingForIntake ? (
            <Tooltip title="U hebt een acceptatieverzoek geaccepteerd. Vergeet niet de intake in te vullen om het acceptatieverzoek af te ronden.">
              <div>
                <Chip
                  variant="filled"
                  color="info"
                  text={'Vul intake in om acceptatieverzoek af te ronden'}
                />
              </div>
            </Tooltip>
          ) : noReportingRequired ? (
            <Chip
              variant="filled"
              color="success"
              text={`In ${year} gaat ${group_head?.company?.name} uw rapportage vezorgen`}
              icon={<TaskAltIcon />}
            />
          ) : (
            <Chip
              variant={declarationFinished ? 'filled' : 'outlined'}
              text={
                declarationFinished
                  ? 'Rapportage afgerond'
                  : `Rapportage inleveren voor ${reportDeadline}`
              }
              color={declarationFinished ? 'success' : 'primary'}
              icon={declarationFinished ? <TaskAltIcon /> : null}
            />
          )}
        </div>
        <div
          className="SummaryTitle-right"
          style={{ marginLeft: 'auto' }}>
          {allowIntake && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              to={`/intake/${intake_id}`}>
              {CONTENT.dashboard.buttons.start_intake}
            </Button>
          )}
          {allowDeclaration && subsidiaries?.length < 1 && (
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group">
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                onClick={startDeclaration}
                disabled={!declaration_id}>
                {renderDeclarationButton(declaration_status)}
              </Button>
            </ButtonGroup>
          )}
          {allowDeclaration && subsidiaries?.length > 0 && consolidated && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              disabled={!allowDeclaration}
              onClick={startDeclaration}>
              {renderDeclarationButton(declaration_status, consolidated)}
            </Button>
          )}
        </div>
        <OptionsMenu
          data={data}
          style={{ margin: '0 0 0 8px' }}
        />
      </AccordionSummary>

      {subsidiaries?.length > 0 && (
        <AccordionDetails>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={6}
              md={8}>
              <h2 className="no-margin--top">Uw organisatie</h2>
            </Grid>
          </Grid>
          <div className="subsidiariesGrid table-head">
            <span>Bedrijfsnaam / kvk nr.</span>
            <span>Wie rapporteert?</span>
            <span>Status rapportage</span>
          </div>
          <AccordionRow
            name={company.name}
            kvk_number={company.kvk_number}
            status={declaration_status}
            openRequests={openRequests}
            action={
              declaration_id &&
              !consolidated &&
              renderDeclarationButton(declaration_status)
            }
            onAction={startDeclaration}
          />

          <h2 className="no-margin--top">
            Maatschappijen onder {company.name}
          </h2>
          <div className="subsidiariesGrid table-head">
            <span>Bedrijfsnaam / kvk nr.</span>
            <span>Wie rapporteert?</span>
            <span>Status rapportage</span>
          </div>
          {subsidiaries?.map((subsidiary, i) => {
            return (
              <SubSidiary
                key={i}
                year={year}
                parent={company}
                subsidiary={subsidiary}
                openRequests={openRequests}
                consolidated={consolidated}
                parentDeclarationStatus={declaration_status}
              />
            )
          })}
        </AccordionDetails>
      )}

      {group_head?.request?.status === 'open' && (
        <AccordionDetails>
          <Parent
            company={company}
            group_head={group_head}
            year={year}
          />
        </AccordionDetails>
      )}

      <div className="Dashboard-accordion__buttons"></div>
    </Accordion>
  )
}

export default DashboardAccordion
