/* eslint-disable max-len */
const canvasExplainer = {
  sections: {
    plan: {
      heading: 'Plan van aanpak',
      subHeading: 'Had uw bedrijf in {{year}} een formeel plan van aanpak?',
      intro:
        'Het plan van aanpak is een strategisch actieplan waarin u beschrijft hoe u de streefcijfers voor het bestuur, de rvc en de subtop wil gaan halen.',
      accordion: {
        heading: 'Wat verstaan we onder een formeel plan?',
        content: (
          <>
            <p>
              Om 'ja' te kunnen antwoorden, moet u een op zichzelf staand beleid
              hebben of moet beleid opgenomen zijn in een ander beleidsplan.
            </p>
            <p>Het beleid moet zijn:</p>
            <ul>
              <li>beschreven</li>
              <li>goedgekeurd door het bestuur</li>
            </ul>
            <p>Het plan kan bijvoorbeeld inhouden:</p>
            <ul>
              <li>het opstellen of wijzigen van een profielschets</li>
              <li>
                de inrichting van een transparant wervings- en selectieproces
              </li>
              <li>een uitleg over voorkeursbeleid</li>
              <li>
                maatregelen voor een evenwichtige m/v-verdeling bij
                talentmanagement en successieplanning
              </li>
              <li>beleid voor remuneratie en gelijke beloning</li>
              <li>KPI's voor leidinggevenden</li>
              <li>training, coaching en mentoring</li>
              <li>inrichten van interne monitoringprocessen</li>
              <li>maatregelen voor een inclusieve organisatiecultuur </li>
            </ul>
          </>
        ),
      },
    },
  },
  questions: {
    code_1: {
      value: {
        label:
          'Had uw bedrijf in {{year}} een formeel plan van aanpak om uw streefcijfers voor de man-vrouwverhouding in de top en subtop te realiseren of te behouden?',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
      reason: {
        label: 'Waarom niet?',
      },
    },
    code_2: {
      label:
        'Is dit plan van aanpak gedeeld met uw werknemers, de ondernemingsraad, aandeelhouders en/of anderen? (Meerdere antwoorden mogelijk)',
      report: {
        label: 'Ja, gepubliceerd in het bestuursverslag over {{year}}',
      },
      employees: {
        label: 'Ja, gedeeld met werknemers',
      },
      council: {
        label: 'Ja, gedeeld met de ondernemingsraad',
      },
      shareholders: {
        label: 'Ja, gedeeld met aandeelhouders',
      },
      other: {
        value: {
          label: 'Ja, gedeeld met anderen, namelijk',
        },
        others: {
          label: 'Met wie is het plan van aanpak nog meer gedeeld?',
        },
      },
      work_in_progress: {
        label: 'Nee',
      },
    },
  },
}

export default canvasExplainer
