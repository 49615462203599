import { setPartialState } from './ui.store'

const setToasterState = setPartialState('toaster')

export const toaster = {
  info: (message, hide) => setToasterState({ type: 'info', message, hide }),
  error: (message, hide) => setToasterState({ type: 'error', message, hide }),
  success: (message, hide) =>
    setToasterState({ type: 'success', message, hide }),
  warning: (message, hide) =>
    setToasterState({ type: 'warning', message, hide }),
}
