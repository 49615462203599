import { createResource } from '@acato/axios-utils'
import {
  createInterceptor,
  errorInterceptor,
  requestAuthInterceptor,
  responseAuthInterceptor,
} from '@services/interceptor'

// Api url
const API_URL = process.env.REACT_APP_API_URL

const intercept = createInterceptor({
  error: errorInterceptor,
  request: requestAuthInterceptor,
  response: responseAuthInterceptor,
})

// profile/change-password
export const profileResource = intercept(
  createResource({
    baseURL: `${API_URL}/api/company-portal`,
  })
)
