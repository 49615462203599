/* eslint-disable max-len */
const signDeclaration = {
  sections: {
    intro: {
      heading: 'Rapportage ondertekenen en indienen',
      intro:
        'U staat op het punt uw rapportage op te sturen naar de SER. Na het indienen kunnen er geen aanpassingen meer gedaan worden.',
    },
    sign: {
      heading: 'Ondertekenen',
      content:
        'Wij verzoeken u als bestuurlijk verantwoordelijke (CEO, directeur, etc.) de rapportage Genderdiversiteit in de top en subtop {{year}} te ondertekenen. Naar waarheid ingevuld en bevestigd:',
    },
  },
  questions: {
    code_1: {
      label: 'Datum',
      required: { value: true },
    },
    code_2: {
      label: 'Naam bestuurlijk verantwoordelijke',
      required: { value: true },
    },
  },
}

export default signDeclaration
