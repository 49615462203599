export const DECLARATION_VERSION_B_TARGET_SUBTOP = {
  sections: {
    target: {
      heading: 'Streefcijfer subtop',
      subHeading: 'Wat is het streefcijfer voor de subtop?',
      intro: (
        <>
          <p>Hier kunt u uw streefcijfer doorgeven voor de subtop.</p>
          <p>
            Wanneer u voor meerdere groepsmaatschappijen gezamenlijk
            rapporteert, kunt u de subtop op het niveau van de groep definiëren.
            Uw streefcijfer voor de subtop geldt voor de groep als geheel en
            niet voor de groepsmaatschappijen afzonderlijk.
          </p>
        </>
      ),
      accordion: {
        heading: 'Lees meer over het formuleren van streefcijfers',
        content: (
          <>
            <p>
              De wet verplicht grote vennootschappen zelf passende en ambitieuze
              streefcijfers te formuleren voor de verhouding tussen vrouwen en
              mannen in het bestuur, de rvc en de subtop
            </p>
            <p>
              Een streefcijfer is een minimumpercentage dat geldt voor vrouwen
              én mannen. Een streefcijfer van 40% m/v voor het bestuur betekent
              dus dat het bestuur voor ten minste 40% uit vrouwen en voor ten
              minste 40% uit mannen moet bestaan.
            </p>
            <p>
              'Passend' wil zeggen dat het streefcijfer afhangt van de omvang
              van het bestuur, de rvc of de subtop én van de huidige
              man-vrouwverhouding. 'Ambitieus' betekent dat het streefcijfer is
              gericht op een evenwichtiger samenstelling dan in de bestaande
              situatie. Het streefcijfer mag niet 0% zijn.
            </p>
            <p>
              Voor het bestuur, de rvc en de subtop stelt u afzonderlijke
              streefcijfers op. Als uw vennootschap een one-tier board heeft,
              stelt u voor uitvoerende en niet-uitvoerende bestuurders
              afzonderlijke streefcijfers op. Naast het streefcijfer geeft u ook
              een streefjaar door, daarmee geeft u aan op welke termijn u het
              streefcijfer beoogt te behalen.
            </p>
            <p>
              Wanneer het bestuur en de rvc beide uit 1 natuurlijk persoon
              bestaan, stelt u een streefcijfer vast voor beide organen
              gezamenlijk. Het streefcijfer is dan 50%.
            </p>
            <p>
              Wanneer na het behalen van het streefcijfer nog ruimte is voor een
              evenwichtiger m/v-verhouding, formuleert u een nieuw streefcijfer
              dat passend en ambitieus is.
            </p>
          </>
        ),
      },
    },
    explanation_subtop: {
      heading: 'Definitie subtop',
      intro: (
        <>
          <p>
            Grote vennootschappen formuleren ook een streefcijfer voor de
            man-vrouwverhouding in 'leidinggevende functies', de zogenoemde
            subtop.
          </p>
          <p>
            U mag zelf definiëren welke functies en werknemers u tot de subtop
            rekent. Medewerkers die gestationeerd zijn in het buitenland mogen
            worden meegeteld als dat past in uw definitie van de subtop.
          </p>
        </>
      ),
    },
    explanation_data_explorer: {
      heading: 'Toelichting streefcijfer subtop',
    },
  },
  questions: {
    //  code_1 is handled by DeclarationTargetNumbers
    'code_2.definition_v2': {
      name: 'code_2.definition_v2',
      type: 'textarea',
      label: 'Hoe definieert u de subtop?',
    },
    'code_2.previous_definition_v2': {
      name: 'code_2.previous_definition_v2',
      type: 'textarea',
      label: 'Hoe definieert u de subtop?',
    },
    'code_2.overwrite_definition_v2': {
      name: 'code_2.overwrite_definition_v2',
      type: 'checkbox',
      label: 'Wij hebben onze definitie van de subtop aangepast.',
    },
    'code_2.new_definition_v2': {
      name: 'code_2.new_definition_v2',
      type: 'textarea',
      label: 'Nieuwe definitie van de subtop',
    },
    code_3: {
      name: 'code_3',
      type: 'textarea',
      label: 'Wilt u een toelichting geven bij uw streefcijfer voor de subtop?',
    },
  },
}
