/* eslint-disable max-len */
import oneTier from '@assets/graphics/forms/one-tier.svg'
import twoTier from '@assets/graphics/forms/two-tier.svg'
import otherOversight from '@assets/graphics/forms/other-oversight.svg'

const companyStructure = {
  sections: {
    model: {
      heading: 'Uw bestuursmodel',
      intro: 'Welk bestuursmodel heeft uw bedrijf?',
      alert: (
        <>
          <p>
            In de rapportage kunt u de gegevens over uitvoerende bestuurders
            invullen bij het bestuur.
          </p>
          <p>
            De gegevens over niet-uitvoerende bestuurders vult u in bij de raad
            van commissarissen.
          </p>
        </>
      ),
    },
    numbers: {
      heading: 'Rapportage',
      intro:
        'Hieronder kunt u aangeven of u hier rapporteert over de man-vrouwverhouding in het bestuur, de raad van commissarissen en/of de subtop van uw bedrijf.',
    },
    management: {
      heading: 'Bestuur',
    },
    board: {
      heading: 'Raad van Commissarissen',
    },
    subtop: {
      heading: 'Subtop',
    },
    special_subtop: {
      heading: 'Eenhoofdig bestuur',
      intro:
        'Wanneer het bestuur en de rvc beide uit één natuurlijk persoon bestaan, dan stelt u een streefcijfer vast voor beide organen gezamenlijk. Dit streefcijfer is altijd 50%',
      alert:
        'U kunt zowel voor het bestuur als de rvc een streefcijfer van 50% invullen',
    },
  },
  questions: {
    code_1: {
      label: 'Welk bestuursmodel heeft uw bedrijf?',
      required: {
        value: true,
        message: 'Deze vraag is verplicht',
      },
      options: [
        {
          id: 1,
          value: 'one-tier',
          label: 'Option 1',
          title: 'One-tier board',
          content:
            'Een bestuur waarin zowel uitvoerende als niet-uitvoerende bestuurders zitten',
          selected: false,
          image: {
            src: oneTier,
          },
        },
        {
          id: 2,
          value: 'two-tier',
          label: 'Option 2',
          title: 'Two-tier board',
          content:
            'Een apart bestuur dat de dagelijkse leiding heeft en een aparte raad van commissarissen die toezicht houdt op het bestuur',
          selected: false,
          image: {
            src: twoTier,
          },
        },
        {
          id: 3,
          value: 'no-supervisory',
          label: 'Option 3',
          title: 'Bestuur zonder toezichthoudend orgaan',
          content:
            'Uitsluitend een bestuur/directie, geen toezichthouders en geen toezichthoudend orgaan',
          selected: false,
          image: {
            src: otherOversight,
          },
        },
      ],
    },
    code_2: {
      value: {
        label: 'Rapporteert u over het bestuur?',
        required: {
          value: false,
          message: 'Dit veld is verplicht',
        },
      },
      reason: {
        label: 'Reden:',
        required: {
          value: false,
        },
      },
    },
    code_3: {
      value: {
        label: 'Rapporteert u over de raad van commissarissen?',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
      reason: {
        label: 'Reden:',
        required: {
          value: false,
        },
      },
    },
    code_4: {
      value: {
        label: 'Rapporteert u over de subtop?',
        required: {
          value: true,
          message: 'Dit veld is verplicht',
        },
      },
      reason: {
        label: 'Reden:',
        required: {
          value: false,
        },
      },
    },
    code_5: {
      label: 'Bestaan het bestuur en de rvc beide uit één persoon',
      required: {
        value: true,
        message: 'Dit veld is verplicht',
      },
    },
  },
}

export default companyStructure
